import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import NeedHelpImg from './../../assets/images/needHelp.svg';
import CloseIcon from '../../assets/images/close-icon.svg';
import { NavigationConfig } from '../../utils/navigation_config';
var NeedHelp = function () {
    var navigate = useNavigate();
    var supportMail = process.env.NEED_HELP_SUPPORT_EMAIL;
    return (React.createElement(Grid, { container: true },
        React.createElement(IconButton, { onClick: function () { return navigate(NavigationConfig.Account.Login); }, className: "close-icon" },
            React.createElement("img", { src: CloseIcon })),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12, pb: '24px' },
            React.createElement(Box, null,
                React.createElement("img", { src: NeedHelpImg, alt: "" }))),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12, pb: "20px" },
            React.createElement(Typography, { variant: 'h5', lineHeight: '32px' }, "Need Help?")),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12, pb: "24px" },
            React.createElement(Typography, { variant: 'caption', fontSize: { xs: '14px', sm: '14px', md: '16px', lg: '16px' }, className: "need-help" }, "Please reach out to our support team using the below email ID.")),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, pb: "24px" },
            React.createElement(Typography, { variant: 'h6', className: "support-text" },
                React.createElement("a", { href: "mailto:".concat(supportMail), target: "_blank", rel: "noopener noreferrer" }, supportMail))),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12 },
            React.createElement(Button, { fullWidth: true, variant: 'contained', onClick: function () { return navigate(-1); }, className: 'btn-txt' }, "Go Back"))));
};
export default NeedHelp;
