var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import Constants from './../../config/constants';
import './../../assets/scss/otp_styles.scss';
import { Stack, Typography } from '@mui/material';
var createInitailBoxValues = function (number) {
    var obj = {};
    for (var i = 0; i < number; i++) {
        obj["box_".concat(i)] = '';
    }
    return obj;
};
var OTPComponent = (function (_super) {
    __extends(OTPComponent, _super);
    function OTPComponent(props) {
        var _this = _super.call(this, props) || this;
        _this.handleOnOTPPaste = function (e) {
            var _a;
            var copiedText = (_a = e === null || e === void 0 ? void 0 : e.clipboardData) === null || _a === void 0 ? void 0 : _a.getData('Text');
            _this.setState(function () {
                var _a, _b;
                var boxes = {};
                for (var i = 0; i < _this.props.numOfOTPboxes; i++) {
                    boxes["box_".concat(i)] = (_b = (_a = String(copiedText)) === null || _a === void 0 ? void 0 : _a[i]) !== null && _b !== void 0 ? _b : '';
                }
            });
        };
        _this.handleInputChange = function (e) {
            var _a = e.target, name = _a.name, value = _a.value;
            var _b = name.split("_"), boxName = _b[0], boxIndex = _b[1];
            boxIndex = parseInt(boxIndex, 10);
            var firstBox = 'box_0', lastBox = "box_".concat(_this.props.numOfOTPboxes - 1);
            if ((value.length === 0) || Constants.regex.atleastOneNumRegExp.test(value)) {
                _this.setState(function (prevState) {
                    var _a;
                    var boxValues = __assign(__assign({}, prevState.boxValues), (_a = {}, _a[name] = value, _a));
                    _this.props.onChange(Object.values(boxValues).join(''));
                    return (__assign(__assign({}, prevState), { boxValues: boxValues }));
                });
                if ((name !== firstBox) && (value.length === 0)) {
                    var prevElement = document.querySelector("input[name=".concat(boxName, "_").concat(boxIndex - 1, "]"));
                    prevElement.focus();
                }
                if ((name !== lastBox) && (value.length === 1)) {
                    var nextElement = document.querySelector("input[name=".concat(boxName, "_").concat(boxIndex + 1, "]"));
                    nextElement.focus();
                }
            }
        };
        _this.state = {
            boxValues: __assign({}, createInitailBoxValues(_this.props.numOfOTPboxes)),
            verificationSuccess: false,
            resendTimer: '',
        };
        return _this;
    }
    OTPComponent.prototype.render = function () {
        var _this = this;
        if (this.props.numOfOTPboxes > 0) {
            return (React.createElement(React.Fragment, null,
                React.createElement(Stack, { className: 'otp_container', flexDirection: 'row', maxWidth: '100%' }, Array(this.props.numOfOTPboxes).fill('x').map(function (box, i) {
                    var _a;
                    return (React.createElement("input", { key: i + 1, className: "otp_box ".concat(_this.props.error ? 'error' : ''), name: "box_".concat(i), value: (_a = _this.state.boxValues) === null || _a === void 0 ? void 0 : _a["box_".concat(i)], onChange: _this.handleInputChange, maxLength: 1, onPaste: _this.handleOnOTPPaste }));
                })),
                this.props.error_message
                    && React.createElement(Typography, { variant: "caption", className: 'otp-error_text', display: "block", gutterBottom: true }, this.props.error_message)));
        }
        else {
            return null;
        }
    };
    return OTPComponent;
}(React.Component));
export default React.memo(OTPComponent);
