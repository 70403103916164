var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from 'axios';
import { app_actions } from '../actions/app_actions';
import { store } from '../store/index';
import _ from './../utils/lodash';
import * as api_urls from './api_urls';
import { loginSignup_asyncActions } from '../asyncActions/loginSignup_asynActions';
import { app_config } from '../config/app_config';
import { utility } from '../utils';
var Server = (function () {
    function Server() {
        var _this = this;
        this.api_urls = api_urls;
        this.axiosOptions = {
            timeout: 120000,
            transformRequest: [this.transformRequest],
            withCredentials: true
        };
        this.ContentHeaders = {
            Json: 'application/json',
            FormData: 'multipart/form-data',
            Plain: 'text/plain'
        };
        this.BaseDomain = {
            Api: app_config.apiDomain
        };
        this.requestProgress = function (updateProgress, addPerCent) {
            return function (progressEvent) {
                return updateProgress(addPerCent + Math.floor((progressEvent.loaded * 100) / progressEvent.total));
            };
        };
        this.post = function (data) {
            store.dispatch(app_actions.snackbarMessage(''));
            if (!data.domain) {
                data.domain = api_service.BaseDomain.Api;
            }
            if (!data.headerType) {
                data.headerType = api_service.ContentHeaders.Json;
            }
            if (data.showLoader) {
                store.dispatch(app_actions.showLoader());
            }
            if (data.headerType === api_service.ContentHeaders.Json) {
                data.payLoad = JSON.stringify(data.payLoad);
            }
            if (data.cancelRequest) {
                _this.cancelRequest(data.cancelRequest);
            }
            return axios.post(data.endPoint, data.payLoad, {
                timeout: _this.axiosOptions.timeout,
                transformRequest: _this.axiosOptions.transformRequest,
                baseURL: data.domain,
                headers: _this.getHeadersByType(data.headerType, data.domain, data.customHeaders),
                cancelToken: data.cancelToken
            });
        };
        this.isNetworkError = function (error) {
            return !error.response && error.code !== 'ECONNABORTED';
        };
        this.retry = function (config) {
            return axios(config);
        };
        this.put = function (data) {
            store.dispatch(app_actions.snackbarMessage(''));
            var progressConfig = {};
            if (!data.domain) {
                data.domain = api_service.BaseDomain.Api;
            }
            if (!data.headerType) {
                data.headerType = api_service.ContentHeaders.Json;
            }
            if (data.headerType === api_service.ContentHeaders.Json) {
                data.payLoad = JSON.stringify(data.payLoad);
            }
            if (data.showLoader !== false) {
                data.showLoader = true;
            }
            if (data.showLoader) {
                store.dispatch(app_actions.showLoader());
            }
            if (data.updateProgress) {
                progressConfig = {
                    onUploadProgress: _this.requestProgress(data.updateProgress, 0),
                    onDownloadProgress: _this.requestProgress(data.updateProgress, 50)
                };
            }
            return axios.put(data.endPoint, data.payLoad, __assign({ timeout: _this.axiosOptions.timeout, transformRequest: _this.axiosOptions.transformRequest, baseURL: data.domain, headers: _this.getHeadersByType(data.headerType, data.domain, data.customHeaders) }, progressConfig));
        };
        this.patch = function (data) {
            store.dispatch(app_actions.snackbarMessage(''));
            if (!data.domain) {
                data.domain = api_service.BaseDomain.Api;
            }
            if (!data.headerType) {
                data.headerType = api_service.ContentHeaders.Json;
            }
            if (data.headerType === api_service.ContentHeaders.Json) {
                data.payLoad = JSON.stringify(data.payLoad);
            }
            if (data.showLoader !== false) {
                data.showLoader = true;
            }
            if (data.showLoader) {
                store.dispatch(app_actions.showLoader());
            }
            return axios.patch(data.endPoint, data.payLoad, {
                timeout: _this.axiosOptions.timeout,
                transformRequest: _this.axiosOptions.transformRequest,
                baseURL: data.domain,
                headers: _this.getHeadersByType(data.headerType, data.domain, data.customHeaders)
            });
        };
        this.delete = function (data) {
            store.dispatch(app_actions.snackbarMessage(''));
            if (!data.domain) {
                data.domain = api_service.BaseDomain.Api;
            }
            if (!data.headerType) {
                data.headerType = api_service.ContentHeaders.Json;
            }
            if (data.headerType === api_service.ContentHeaders.Json) {
                data.payLoad = JSON.stringify(data.payLoad);
            }
            if (data.showLoader !== false) {
                data.showLoader = true;
            }
            if (data.showLoader) {
                store.dispatch(app_actions.showLoader());
            }
            return axios.delete(data.endPoint, {
                baseURL: data.domain,
                data: data.payLoad,
                headers: _this.getHeadersByType(data.headerType, data.domain, data.customHeaders)
            });
        };
        this.get = function (data) {
            store.dispatch(app_actions.snackbarMessage(''));
            if (!data.domain) {
                data.domain = api_service.BaseDomain.Api;
            }
            if (!data.headerType) {
                data.headerType = api_service.ContentHeaders.Json;
            }
            if (data.showLoader !== false) {
                data.showLoader = true;
            }
            if (data.showLoader) {
                store.dispatch(app_actions.showLoader());
            }
            if (data.cancelRequest) {
                _this.cancelRequest(data.cancelRequest);
            }
            return axios.get(data.endPoint, {
                baseURL: data.domain,
                timeout: _this.axiosOptions.timeout,
                params: data.payLoad,
                headers: _this.getHeadersByType(data.headerType, data.domain, data.customHeaders),
                cancelToken: data.cancelToken
            });
        };
        this.fileUpload = function (data) {
            store.dispatch(app_actions.snackbarMessage(''));
            if (!data.domain) {
                data.domain = _this.BaseDomain.Api;
            }
            if (!data.headerType) {
                data.headerType = _this.ContentHeaders.FormData;
            }
            if (data.showLoader !== false) {
                data.showLoader = true;
            }
            var payload = data.payload;
            var payLoadData = data.payload;
            var config = {};
            if (data.id && data.id !== '') {
                var indexToUpdate_1 = -1;
                config = {
                    onUploadProgress: function (progressEvent) {
                        var percentage = (Math.round((progressEvent.loaded * 100) / progressEvent.total));
                        var uploadedData = _.clone(store.getState().App.percentageData);
                        var percentageData = {
                            id: data.id,
                            name: data.fileData.name,
                            type: data.fileData.type,
                            percentage: "".concat(percentage, " %"),
                            per_num: percentage
                        };
                        if (indexToUpdate_1 > -1) {
                            uploadedData[indexToUpdate_1].percentage = "".concat(percentage, " %");
                            uploadedData[indexToUpdate_1].per_num = percentage;
                        }
                        else {
                            indexToUpdate_1 = uploadedData.length;
                            uploadedData.push(percentageData);
                        }
                        store.dispatch(app_actions.updateUploadPercentage(uploadedData));
                    },
                    headers: _this.getHeadersByType(data.headerType, data.domain),
                };
            }
            else {
                config = {
                    headers: _this.getHeadersByType(data.headerType, data.domain),
                };
            }
            return axios.post("".concat(data.domain).concat(data.endPoint), payload, config);
        };
        this.cancelToken = function () {
            var CancelToken = axios.CancelToken;
            return CancelToken.source();
        };
        this.cancelRequest = function (source) {
            source.cancel('');
        };
        axios.interceptors.response.use(function (response) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
            store.dispatch(app_actions.hideLoader());
            console.log('success API response:', response);
            if (response.data) {
                if (((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.statusCode) === 403) {
                    utility.alert((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.message);
                    store.dispatch(app_actions.resetStore());
                }
                else if (((_d = (_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.statusCode) > 200 && ((_f = (_e = response === null || response === void 0 ? void 0 : response.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.statusCode) !== 401) {
                    utility.alert((_h = (_g = response === null || response === void 0 ? void 0 : response.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.message);
                }
                else if (((_j = response === null || response === void 0 ? void 0 : response.data) === null || _j === void 0 ? void 0 : _j.statusCode) > 200 && ((_k = response === null || response === void 0 ? void 0 : response.data) === null || _k === void 0 ? void 0 : _k.statusCode) !== 401) {
                }
            }
            return response;
        }, function (error) {
            console.log('failed API response:', error);
            var config = error.config;
            if (error.response) {
                if (error.response.status === 403) {
                }
                if (error.response.status !== 401 && error.response.status !== 403) {
                    utility.alert(error.response.data.message);
                }
            }
            if (error.message === "timeout of ".concat(_this.axiosOptions.timeout, "ms exceeded")) {
                error.message = 'Request has been timed out. Please try again.';
                utility.alert('Request has been timed out. Please try again.');
            }
            if (error.message === 'Network Error') {
                if (navigator.onLine) {
                    error.message = 'We are unable to reach the server. Please try later.';
                    utility.alert('We are unable to reach the server. Please try later.');
                }
                else {
                    error.message = 'Please check your internet connection.';
                    utility.alert('Please check your internet connection.');
                }
            }
            store.dispatch(app_actions.hideLoader());
            return new Promise(function (resolve, reject) {
                if (error.response) {
                    if (error.response.status === 403) {
                        store.dispatch(app_actions.resetStore());
                    }
                    else if (error.response.status === 401) {
                        store.dispatch(app_actions.showLoader());
                        store.dispatch(loginSignup_asyncActions.refreshSession(store.getState().User.session.refreshToken))
                            .then(function (result) {
                            store.dispatch(app_actions.hideLoader());
                            var accessToken = result.accessToken;
                            if (accessToken) {
                                config.headers.Authorization = "Bearer ".concat(accessToken !== null && accessToken !== void 0 ? accessToken : '');
                                resolve(_this.retry(config));
                            }
                        });
                    }
                    else {
                        reject(error.response.data);
                    }
                }
                else {
                    reject(error);
                }
            });
        });
    }
    Server.prototype.transformRequest = function (data) {
        if ((data === null || data === void 0 ? void 0 : data.headerType) === api_service.ContentHeaders.Json) {
            try {
                data = JSON.parse(data);
                var temp_1 = _.clone(data);
                _.map(data, function (value, key) {
                    if (key && key.indexOf('__') > -1) {
                        delete temp_1[key];
                    }
                });
                return JSON.stringify(temp_1);
            }
            catch (e) {
                return data;
            }
        }
        else {
            return data;
        }
    };
    Server.prototype.getHeadersByType = function (headerType, domain, customHeaders) {
        var data = {};
        switch (headerType) {
            case api_service.ContentHeaders.Json: {
                data['Content-Type'] = 'application/json';
                break;
            }
            case api_service.ContentHeaders.Plain: {
                data['Content-Type'] = 'text/plain';
                break;
            }
            case api_service.ContentHeaders.FormData: {
                data['Content-Type'] = 'multipart/form-data';
                break;
            }
            default:
                data['Content-Type'] = 'application/json';
                break;
        }
        data['Authorization'] = 'Bearer ' + store.getState().User.session.accessToken;
        data = _.extend({}, data, customHeaders);
        return data;
    };
    return Server;
}());
export var api_service = new Server();
