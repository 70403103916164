import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import React from 'react';
import './../../assets/scss/form_styles.scss';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { Stack, FormControl, FormHelperText, Typography, Box, IconButton } from '@mui/material';
import { CloseIconSmall } from '../icons';
var MUITimePicker = function (_a) {
    var labelText = _a.labelText, selectedTime = _a.selectedTime, timeChangeHandler = _a.timeChangeHandler, errorMessage = _a.errorMessage, isMandatory = _a.isMandatory, disabled = _a.disabled, clearTime = _a.clearTime;
    var handleClearTime = function () {
        clearTime && clearTime();
    };
    return (React.createElement(Stack, { className: "time-container date-container" },
        React.createElement(FormControl, { error: Boolean(errorMessage) },
            React.createElement(DemoContainer, { components: ['TimeField'] },
                React.createElement(DemoItem, { label: errorMessage ? React.createElement(Typography, { fontSize: '14px', className: errorMessage ? 'error' : '' },
                        React.createElement(Box, { component: 'span', color: "#d32f2f" }, labelText.toUpperCase() + "".concat(isMandatory ? '*' : '')))
                        :
                            React.createElement(Typography, { fontSize: '14px', className: errorMessage ? 'error' : '' },
                                React.createElement(React.Fragment, null,
                                    labelText.toUpperCase(),
                                    isMandatory &&
                                        React.createElement(Box, { component: 'span', color: "#d32f2f" }, "*"))) },
                    React.createElement(TimeField, { defaultValue: selectedTime === null || selectedTime === '' ? null : selectedTime, value: selectedTime ? selectedTime : null, onChange: timeChangeHandler, className: "time-picker-styles date-picker-styles", disabled: disabled })),
                clearTime && selectedTime && !errorMessage &&
                    React.createElement(IconButton, { sx: { position: 'absolute',
                            right: 0,
                            bottom: 10 }, size: "small", onClick: handleClearTime, className: "", title: "Clear Date" },
                        React.createElement(CloseIconSmall, null))),
            React.createElement(FormHelperText, { sx: { marginLeft: '0px' } }, errorMessage))));
};
export default MUITimePicker;
