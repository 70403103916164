import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
var TabsComponent = function (_a) {
    var tabs = _a.tabs, selectedTab = _a.selectedTab, onTabChange = _a.onTabChange;
    return (React.createElement(Box, { sx: { width: '100%', typography: 'body1' } },
        React.createElement(TabContext, { value: selectedTab },
            React.createElement(Box, { sx: { borderBottom: 1, borderColor: 'divider' } },
                React.createElement(TabList, { onChange: onTabChange, "aria-label": "lab API tabs example" }, tabs === null || tabs === void 0 ? void 0 : tabs.map(function (tab) { return (React.createElement(Tab, { sx: {
                        textTransform: 'capitalize',
                        '&::first-letter': {
                            textTransform: 'uppercase',
                        },
                    }, key: tab.value, label: tab.label, value: tab.value })); }))), tabs === null || tabs === void 0 ? void 0 :
            tabs.map(function (tab) { return (React.createElement(TabPanel, { key: tab.value, value: tab.value }, tab.content)); }))));
};
export default TabsComponent;
