var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Autocomplete, Box, FormControl, FormHelperText, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
var AutoCompleteMultiSelectDD = function (_a) {
    var options = _a.options, valueKey = _a.valueKey, displayKey = _a.displayKey, label = _a.label, disabled = _a.disabled, handleChange = _a.handleChange, selectedValue = _a.selectedValue, name = _a.name, placeholder = _a.placeholder, errorMessage = _a.errorMessage, isMandatoryField = _a.isMandatoryField, isMultiple = _a.isMultiple, handleBlur = _a.handleBlur;
    return (React.createElement(Stack, { className: disabled ? 'disable-fields custom-select-container' : 'custom-select-container' },
        React.createElement(FormControl, { error: Boolean(errorMessage), className: 'select-dropdown' },
            React.createElement(Box, { component: 'span' },
                React.createElement(Typography, { fontSize: '14px', className: errorMessage ? 'error' : '' },
                    label.toUpperCase(),
                    Boolean(isMandatoryField) &&
                        React.createElement(Box, { component: 'span', color: "red" }, "*"))),
            React.createElement(Autocomplete, { disablePortal: true, id: "common-auto-complete", options: options, getOptionLabel: function (option) { return option[displayKey]; }, sx: { width: 'auto',
                    '& .MuiInputBase-root': {
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        minHeight: '37px',
                        maxHeight: '70px',
                        overflow: 'auto',
                        height: 'auto !important',
                        '&:hover': {
                            border: '1px solid #000',
                        }
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                    }
                }, renderInput: function (params) {
                    var _a, _b;
                    return (React.createElement(TextField, __assign({}, params, { placeholder: !((_a = selectedValue === null || selectedValue === void 0 ? void 0 : selectedValue[0]) === null || _a === void 0 ? void 0 : _a.Name) ? (_b = placeholder === null || placeholder === void 0 ? void 0 : placeholder.toUpperCase()) !== null && _b !== void 0 ? _b : "SELECT" : '' })));
                }, multiple: true, disabled: disabled, renderOption: function (props, option) { return (React.createElement("li", __assign({}, props, { style: { fontSize: '14px', color: '#696F88' } }), option[displayKey])); }, onChange: function (event, value, reason, details) { return handleChange(event, value, reason, details); }, noOptionsText: React.createElement(Typography, { fontSize: '14px', color: '#696F88' }, 'No Options'), value: selectedValue ? selectedValue : [], size: "small", limitTags: 5, onBlur: function (event) {
                    event = { target: { value: event.target.value, name: name } };
                    handleBlur(event);
                }, clearIcon: React.createElement(React.Fragment, null) }),
            React.createElement(FormHelperText, null, errorMessage))));
};
export default AutoCompleteMultiSelectDD;
