var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from 'react';
import ModuleHeader from '../../components/ModuleHeader/ModuleHeader';
import { Box } from '@mui/material';
import ModuleTable from '../../components/UtilityComponents/moduleTable';
import { SupportTicketCols } from '../../config/TableColumns';
import moment from 'moment';
var titcketList = [
    { TicketCode: 'ST10001', Description: 'Need to install node in ubuntu', TicketRaisedBy: 'Self', CreatedTime: moment('2024-01-23T07:11:53.772Z'), ticketStatus: 'INPROGRESS' },
    { TicketCode: 'ST10002', Description: 'Need to install node in OS', TicketRaisedBy: 'Self', CreatedTime: moment('2024-01-23T07:11:53.772Z'), ticketStatus: 'INPROGRESS' },
    { TicketCode: 'ST10003', Description: 'Need to install node in OSS', TicketRaisedBy: 'Self', CreatedTime: moment('2024-01-23T07:11:53.772Z'), ticketStatus: 'Pending' },
    { TicketCode: 'ST10004', Description: 'Need to node in ubuntu', TicketRaisedBy: 'Self', CreatedTime: moment('2024-01-23T07:11:53.772Z'), ticketStatus: 'Resolved' },
    { TicketCode: 'ST10005', Description: 'Need to install node in ubuntuubuntu', TicketRaisedBy: 'Self', CreatedTime: moment('2024-01-23T07:11:53.772Z'), ticketStatus: 'INPROGRESS' },
    { TicketCode: 'ST10006', Description: 'Need to install node in node', TicketRaisedBy: 'Self', CreatedTime: moment('2024-01-23T07:11:53.772Z'), ticketStatus: 'Pending' },
    { TicketCode: 'ST10007', Description: 'Need to install node in ubuntu', TicketRaisedBy: 'Self', CreatedTime: moment('2024-01-23T07:11:53.772Z'), ticketStatus: 'Resolved' },
    { TicketCode: 'ST10008', Description: 'Need to install node in Windows', TicketRaisedBy: 'Self', CreatedTime: moment('2024-01-23T07:11:53.772Z'), ticketStatus: 'Resolved' },
];
var freezedSupportTicketList = [];
var Support = function () {
    var _a = useState(), selectedPortal = _a[0], setSelectedPortal = _a[1];
    var _b = useState(), selectedStatus = _b[0], setSelectedStatus = _b[1];
    var _c = useState(), searchText = _c[0], setSearchText = _c[1];
    var _d = useState(false), drawerOpen = _d[0], setDrawerOpen = _d[1];
    var _e = useState(), isFetching = _e[0], setIsFetching = _e[1];
    var _f = useState(titcketList), dataToDisplay = _f[0], setDataToDisplay = _f[1];
    console.log('selectedPortal:', selectedPortal);
    console.log('selectedStatus:', selectedStatus);
    console.log('searchText:', searchText);
    console.log('drawerOpen:', drawerOpen);
    var getSupportTicketList = function () {
        freezedSupportTicketList = __spreadArray([], dataToDisplay, true);
        Object.freeze(freezedSupportTicketList);
    };
    useEffect(function () {
        var filteredList = __spreadArray([], freezedSupportTicketList, true);
        if (searchText) {
            filteredList = freezedSupportTicketList.filter(function (eachRec) {
                var fullStringToMatch = '';
                if (eachRec.TicketCode) {
                    fullStringToMatch += eachRec.TicketCode;
                }
                return fullStringToMatch.toLocaleLowerCase().includes(searchText.toLocaleLowerCase());
            });
        }
        setDataToDisplay(filteredList);
    }, [searchText]);
    useEffect(function () {
        getSupportTicketList();
    }, []);
    return (React.createElement(Box, { className: "common-module-wrapper support-ticket-wrapper" },
        React.createElement(ModuleHeader, { moduleName: 'Support', placeHolderText: 'Search by TicketID', hasPortalDD: false, hasStatusDD: false, hasSearch: true, hasAddItem: false, getSelectedPortal: setSelectedPortal, selectedStatus: setSelectedStatus, getSearchedText: setSearchText, isDrawerOpen: setDrawerOpen }),
        React.createElement(Box, { className: "module-common-table-wrapper" },
            React.createElement(ModuleTable, { dataToDisplay: dataToDisplay, columns: SupportTicketCols, isLoading: isFetching, isActionColumn: true, actionHandler: function () { }, moduleName: "Support Ticket" }))));
};
export default Support;
