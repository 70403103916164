var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { useSelector } from 'react-redux';
import MuiAppBar from '../components/UtilityComponents/appBar_component';
import { NavigationConfig } from '../utils/navigation_config';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import MiniDrawer from '../components/SideMenu/sideMenu';
import './../assets/scss/authentication.scss';
var LightTooltip = styled(function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (React.createElement(Tooltip, __assign({}, props, { classes: { popper: className }, placement: 'top-start', arrow: true })));
})(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["& .".concat(tooltipClasses.tooltip)] = {
            backgroundColor: theme.palette.common.black,
            color: 'white',
            boxShadow: theme.shadows[1],
            fontSize: 11,
            width: 270,
            marginRight: 2
        },
        _b["& .".concat(tooltipClasses.arrow)] = {
            color: theme.palette.common.black,
        },
        _b);
});
var getUser = function (state) { return state.User; };
var CheckAuth = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    var navigate = useNavigate();
    var location = useLocation();
    var navConfig = NavigationConfig;
    var isLoggedIn = useSelector(function (state) { return getUser(state).isLoggedIn; });
    var userBasicInfo = useSelector(function (store) { return store.User.userData; });
    var helpMail = useSelector(function (store) { return store.User.helpMail; });
    var _b = React.useState(false), openNeedhelp = _b[0], setNeedhelp = _b[1];
    React.useEffect(function () {
        checkAuthentication();
    }, [isLoggedIn]);
    function checkAuthentication() {
        if (!isLoggedIn) {
            navigate(navConfig.Account.Login);
        }
        else {
            checkAuthorization();
        }
    }
    function checkAuthorization() {
        return true;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(MuiAppBar, null),
        React.createElement(Box, { className: "app-side--main-menu-container", sx: { height: 'calc(100vh - 56px)', overflow: 'auto', paddingTop: '56px' } },
            React.createElement(MiniDrawer, null, children))));
};
export default CheckAuth;
