import { JSONEditor } from "vanilla-jsoneditor";
import { useEffect, useRef } from "react";
import React from "react";
import { Box } from "@mui/material";
var SvelteJSONEditor = function (props) {
    var refContainer = useRef(null);
    var refEditor = useRef(null);
    useEffect(function () {
        refEditor.current = new JSONEditor({
            target: refContainer.current,
            props: {}
        });
        return function () {
            if (refEditor.current) {
                refEditor.current.destroy();
                refEditor.current = null;
            }
        };
    }, []);
    useEffect(function () {
        if (refEditor.current) {
            refEditor.current.updateProps(props);
        }
    }, [props]);
    return (React.createElement(Box, { className: "", width: '100%', height: '100%', ref: refContainer, sx: {
            '& .jse-menu.svelte-7deygj.svelte-7deygj': {
                background: '#000',
                '& .jse-selected.svelte-7deygj': {
                    color: '#000 !important',
                }
            }
        } }));
};
export default SvelteJSONEditor;
