import isPromise from "../utils/is-promise";
var objectAssign = require('object-assign');
export default function promiseMiddleware(_a) {
    var dispatch = _a.dispatch;
    return function (next) { return function (action) {
        if (!isPromise(action.payload)) {
            return next(action);
        }
        var types = action.types, payload = action.payload, meta = action.meta;
        var promise = payload.promise, data = payload.data;
        var PENDING = types[0], FULFILLED = types[1], REJECTED = types[2];
        dispatch(objectAssign({}, { type: PENDING }, data ? { payload: data } : {}, meta ? { meta: meta } : {}));
        return promise.then(function (result) {
            dispatch({
                type: FULFILLED,
                payload: result,
                meta: meta
            });
        }, function (error) {
            dispatch({
                type: REJECTED,
                payload: error,
                meta: meta
            });
        });
    }; };
}
