import * as moment from 'moment';
import _ from './lodash';
import { toast } from 'react-toastify';
import constants from '../config/constants';
var Utility = (function () {
    function Utility() {
        var _this = this;
        this.moment = moment;
        this.Object = {
            IsEmpty: function (obj) {
                return _.isEmpty(obj);
            }
        };
        this.Date = {
            Format: 'DD/MM/YYYY',
            ZeroTime: 'T00:00:00.000Z'
        };
        this.Window = {
            GetViewPort: function () {
                return {
                    width: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
                    height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
                };
            }
        };
        this.String = {
            IsNullOrEmpty: function (value) {
                return (!value || value.length === 0);
            },
            IsValidEmail: function (value) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(value);
            },
            IsNullOrWhiteSpace: function (value) {
                if (value == null) {
                    return true;
                }
                for (var i = 0; i < value.length; i++) {
                    if (value[i] !== ' ') {
                        return false;
                    }
                }
                return true;
            },
            Format: function (format) {
                var args = [];
                for (var _i = 1; _i < arguments.length; _i++) {
                    args[_i - 1] = arguments[_i];
                }
                if (_this.String.IsNullOrEmpty(format)) {
                    throw 'Format is null or empty.';
                }
                return format.replace(/{(\d+)}/g, function (match, num) {
                    return typeof args[num] !== 'undefined'
                        ? args[num]
                        : match;
                });
            },
            ReplaceAll: function (value, oldChar, newChar) {
                if (oldChar.length !== 1) {
                    throw 'Old char must be of length 1';
                }
                if (newChar.length !== 1) {
                    throw 'New char must be of length 1';
                }
                return value.replace(new RegExp(oldChar, 'g'), newChar);
            },
            Trim: function (value, char) {
                if (!char) {
                    return value.trim();
                }
                else {
                    if (value[0] === char) {
                        value = value.slice(1, value.length);
                    }
                    var end = value.length - 1;
                    if (value[end] === char) {
                        value = value.slice(0, end);
                    }
                    return value;
                }
            },
            TrimStart: function (value, char) {
                if (!char) {
                    return value.trim();
                }
                else {
                    if (value[0] === char) {
                        value = value.slice(1, value.length);
                    }
                    return value;
                }
            },
            TrimEnd: function (value, char) {
                if (!char) {
                    return value.trim();
                }
                else {
                    var end = value.length - 1;
                    if (value[end] === char) {
                        value = value.slice(0, end);
                    }
                    return value;
                }
            }
        };
        this.Number = {
            IsNumeric: function (n) {
                return !isNaN(parseFloat(n)) && isFinite(parseFloat(n));
            },
            IsInteger: function (value) {
                return typeof value === 'number' &&
                    isFinite(value) &&
                    Math.floor(value) === value;
            }
        };
        this.getPlatformString = function () {
            if (navigator.userAgent.match(/Android/i)) {
                return 'android';
            }
            if ((navigator.userAgent.match(/BlackBerry/i)) || (navigator.userAgent.match(/RIM Tablet OS/i)) || (navigator.userAgent.match(/BB10/i))) {
                return 'blackberry';
            }
            if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
                return 'ios';
            }
            if (navigator.userAgent.match(/Windows Phone|IEMobile|WPDesktop/i)) {
                return 'wp';
            }
            var isFirefox = typeof InstallTrigger !== 'undefined';
            if (isFirefox) {
                return 'firefox';
            }
            var isSafari = Object.prototype.toString.call(window['HTMLElement']).indexOf('Constructor') > 0;
            if (isSafari) {
                return 'safari';
            }
            var isEdge = navigator.userAgent.indexOf(' Edge/') >= 0;
            if (isEdge) {
                return 'edge';
            }
            return 'unknown';
        };
    }
    Utility.prototype.alert = function (msg, toastType) {
        if (msg === void 0) { msg = "Something is wrong !!!"; }
        if (toastType === constants.TOAST_TYPES.WARN) {
            toast.warn(msg, { toastId: msg });
        }
        else if (toastType === constants.TOAST_TYPES.ERROR) {
            toast.error(msg, { toastId: msg });
        }
        else if (toastType === constants.TOAST_TYPES.INFO || !toastType) {
            toast.info(msg, { toastId: msg });
        }
    };
    Utility.prototype.mapDispatchToProps = function (dispatch) {
        return {
            Dispatch: dispatch
        };
    };
    return Utility;
}());
export var utility = new Utility();
