var AppConfig = (function () {
    function AppConfig() {
        this.ENV = process.env.NODE_ENV;
        this.apiDomain = process.env.API_DOMAIN;
        this.pageAnimation = 'slide';
        this.logLevel = 'info';
        this.appVersion = process.env.APP_VERSION;
    }
    return AppConfig;
}());
export var app_config = new AppConfig();
