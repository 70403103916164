import { Box, IconButton, Stack, Typography, Checkbox, Tooltip, CardMedia, Card, Grid, Button, Divider, FormControlLabel, FormGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './../../assets/scss/dealerModule.scss';
import { BackArrowIcon, EditPencilIcon } from '../../components/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavigationConfig } from '../../utils/navigation_config';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { api_service } from '../../api/api_service';
import { api_urls } from '../../api/api_urls';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { styled } from '@mui/material/styles';
import DefaultWhiteLogo from '../../assets/images/whiteBackground.svg';
var VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
var DealerManagementView = function () {
    var navigate = useNavigate();
    var location = useLocation();
    var _a = useState(false), isFetching = _a[0], setIsFetching = _a[1];
    var _b = useState([]), dealerData = _b[0], setDealerData = _b[1];
    var _c = React.useState({
        right: false,
    }), state = _c[0], setState = _c[1];
    var _d = React.useState([]), rows = _d[0], setRows = _d[1];
    var _e = React.useState({}), rowDetails = _e[0], setRowDetails = _e[1];
    var _f = useState([]), filters = _f[0], setFilters = _f[1];
    var _g = useState([]), jsonFieldsInfo = _g[0], setJsonFieldsInfo = _g[1];
    var vendorId = location.state;
    var goBackHandler = function () {
        navigate(-1);
    };
    var eidtDealerHandler = function () {
        navigate("".concat(NavigationConfig.DealerManagement, "/edit/").concat(vendorId), { state: vendorId });
    };
    var getDealerDetails = function () {
        setIsFetching(true);
        api_service.get({
            endPoint: api_urls.DEALER_DETAILS_BY_ID,
            payLoad: {
                VendorId: Number(vendorId)
            }
        }).then(function (res) {
            setIsFetching(false);
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                var dealerData_1 = res.data.data;
                setDealerData(dealerData_1);
            }
        }).catch(function (err) { setIsFetching(false); });
    };
    var getFieldConfigData = function () {
        api_service.get({
            endPoint: "".concat(api_urls.GET_FIELD_CONFIG_DATA, "?VendorId=").concat(vendorId),
            showLoader: true
        }).then(function (res) {
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                var response = res.data.data;
                if (response) {
                    var NORJson = response.NORJson, LOCJson = response.LOCJson, SCHJson = response.SCHJson;
                    if (NORJson && LOCJson && SCHJson) {
                        setJsonFieldsInfo(response);
                    }
                }
            }
        }).catch(function (err) {
            console.log('handleSaveDealerConfiguration ~ err:', err);
        });
    };
    useEffect(function () {
        getDealerDetails();
        getFieldConfigData();
    }, [vendorId]);
    return (React.createElement(Box, { className: "dealer-view-container" },
        React.createElement(Stack, { direction: 'row', className: "dealer-view-header" },
            React.createElement(IconButton, { size: 'small', onClick: goBackHandler },
                React.createElement(BackArrowIcon, null)),
            React.createElement(Typography, null, "View Portal Configurations")),
        React.createElement(Box, { className: "dealer-view-body" },
            React.createElement(Stack, { direction: 'row', className: "dealer-info-box" },
                React.createElement(Stack, null,
                    React.createElement(Typography, { className: "label" }, "Portal Name"),
                    React.createElement(Typography, { className: "content" }, dealerData === null || dealerData === void 0 ? void 0 : dealerData.DealerName)),
                React.createElement(Stack, null,
                    React.createElement(Typography, { className: "label" }, "Portal URL"),
                    React.createElement(Typography, { className: "content" }, dealerData === null || dealerData === void 0 ? void 0 : dealerData.DealerUrl))),
            React.createElement(Box, { className: "dealer-view-body-inside dealer-view-body-inside-view" },
                React.createElement(Stack, { direction: 'row', className: "edit-dealer " },
                    React.createElement(Typography, { className: "feature-text" }, "Features Configurations"),
                    React.createElement(Typography, { className: "edit-text" },
                        "Edit Configurations",
                        React.createElement(IconButton, { size: 'small', onClick: eidtDealerHandler },
                            React.createElement(EditPencilIcon, null)))),
                React.createElement(Box, { className: "granted-apps", mb: 1 },
                    React.createElement(Stack, { className: "content-wrapper content-wrapper-edit" },
                        React.createElement(Typography, { className: 'header-txt', width: '100%' }, "Program Input"),
                        React.createElement(Divider, { sx: { width: '100%' } }),
                        React.createElement(Stack, { width: '100%' },
                            React.createElement(Stack, { rowGap: '8px', width: '100%', py: 1.5 }, (dealerData === null || dealerData === void 0 ? void 0 : dealerData.Programs) && dealerData.Programs.map(function (programsObj) {
                                return (React.createElement(React.Fragment, null,
                                    React.createElement(Grid, { container: true, className: "content-wrapper content-grid-wrapper", justifyContent: 'flex-start !important' },
                                        React.createElement(Grid, { item: true, xs: 3 },
                                            React.createElement(Typography, { className: "label" }, "Name"),
                                            React.createElement(Tooltip, { title: programsObj === null || programsObj === void 0 ? void 0 : programsObj.Name },
                                                React.createElement(Typography, { className: "content", noWrap: true }, programsObj === null || programsObj === void 0 ? void 0 : programsObj.Name))),
                                        React.createElement(Grid, { item: true, xs: 3 },
                                            React.createElement(Typography, { className: "label" }, "Private Label"),
                                            React.createElement(Tooltip, { title: programsObj.PrivateLabel },
                                                React.createElement(Typography, { className: "content", noWrap: true }, programsObj.PrivateLabel))))));
                            }))))),
                React.createElement(Box, { className: "granted-apps", mb: 1 },
                    React.createElement(Stack, { className: "content-wrapper content-wrapper-edit" },
                        React.createElement(Typography, { className: 'header-txt', width: '100%' }, "Features Configurations"),
                        React.createElement(Divider, { sx: { width: '100%' } }),
                        React.createElement(Stack, { width: '100%' },
                            React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', py: 1.5 },
                                React.createElement(Typography, { className: 'header-txt', whiteSpace: 'nowrap' }, "Applications Granted"),
                                React.createElement(Box, { width: '80%' },
                                    React.createElement(Grid, { container: true, className: " content-wrapper-grid" },
                                        (dealerData === null || dealerData === void 0 ? void 0 : dealerData.IsCreditAppEnabled) == 1 &&
                                            React.createElement(Grid, { item: true, xs: 4 }, "Credit Application"),
                                        (dealerData === null || dealerData === void 0 ? void 0 : dealerData.IsLOCAppEnabled) == 1 &&
                                            React.createElement(Grid, { item: true, xs: 4 }, "Line of Credit Application"),
                                        (dealerData === null || dealerData === void 0 ? void 0 : dealerData.IsTakeDownAppEnabled) == 1 &&
                                            React.createElement(Grid, { item: true, xs: 4 }, "Schedule/Takedown"),
                                        (dealerData === null || dealerData === void 0 ? void 0 : dealerData.IsCreditAppEnabled) == 0 && (dealerData === null || dealerData === void 0 ? void 0 : dealerData.IsLOCAppEnabled) == 0 && (dealerData === null || dealerData === void 0 ? void 0 : dealerData.IsTakeDownAppEnabled) == 0 &&
                                            React.createElement(Grid, { item: true, xs: 12 }, "No Applications Granted")))),
                            React.createElement(Divider, { sx: { width: '100%' } }),
                            React.createElement(Box, { className: "granted-apps", mb: 1, display: 'flex' },
                                React.createElement(Stack, { direction: 'row', justifyContent: 'flex-start !important', className: "content-wrapper content-wrapper-edit content-quote", sx: { border: 'none !important' } },
                                    React.createElement(Typography, null, "Quote tool"),
                                    React.createElement(Typography, null,
                                        React.createElement(Checkbox, { sx: { padding: '0px' }, disabled: true, checked: (dealerData === null || dealerData === void 0 ? void 0 : dealerData.IsQuoteToolEnabled) == 0 ? false : true, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null) }))),
                                React.createElement(Stack, { direction: 'row', justifyContent: 'flex-start !important', className: "content-wrapper content-wrapper-edit content-quote", sx: { border: 'none !important' } },
                                    React.createElement(Typography, null, "Send Application Link"),
                                    React.createElement(Typography, null,
                                        React.createElement(Checkbox, { sx: { padding: '0px' }, disabled: true, checked: (dealerData === null || dealerData === void 0 ? void 0 : dealerData.IsSendAppEnabled) == 0 ? false : true, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null) }))),
                                React.createElement(Stack, { direction: 'row', justifyContent: 'flex-start !important', className: "content-wrapper content-wrapper-edit content-quote", sx: { border: 'none !important' } },
                                    React.createElement(Typography, null, "Req Docs"),
                                    React.createElement(Typography, null,
                                        React.createElement(Checkbox, { sx: { padding: '0px' }, disabled: true, checked: (dealerData === null || dealerData === void 0 ? void 0 : dealerData.IsReqDocEnabled) == 0 ? false : true, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null) }))))))),
                React.createElement(Box, { className: "granted-apps", mb: 1 },
                    React.createElement(Stack, { direction: 'column', alignItems: 'baseline !important', className: "content-wrapper content-wrapper-rate-card content-wrapper-edit" },
                        React.createElement(Typography, null, "Field Configuration"),
                        React.createElement(Divider, { sx: { width: '100%' } }),
                        React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', py: 1.5, spacing: 2 },
                            React.createElement(Grid, { container: true, spacing: 2 }, Object.keys(jsonFieldsInfo).map(function (key) {
                                if (key == "NORJson" || key == "LOCJson" || key == "SCHJson") {
                                    return (React.createElement(Grid, { item: true, xs: 12, sm: 4, lg: 3, key: key },
                                        React.createElement(Button, { variant: 'outlined', className: 'jsonBtn', fullWidth: true },
                                            React.createElement(AttachmentIcon, { sx: { paddingRight: '10px' } }),
                                            key)));
                                }
                                return null;
                            }))))),
                React.createElement(Box, { sx: {
                        '& .granted-apps': {
                            margin: '10px 0',
                        }
                    } },
                    React.createElement(Box, { className: "granted-apps", mb: 1 },
                        React.createElement(Stack, { direction: 'column', alignItems: 'baseline !important', className: "content-wrapper content-wrapper-rate-card content-wrapper-edit" },
                            React.createElement(Typography, null, "Rate Card"),
                            React.createElement(Divider, { sx: { width: '100%' } }),
                            React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', py: 1.5, spacing: 2 },
                                (dealerData === null || dealerData === void 0 ? void 0 : dealerData.RateCard) && React.createElement(Typography, { width: '100%', className: "fileUploadHeader", sx: { fontWeight: '500 !important' } }, dealerData === null || dealerData === void 0 ? void 0 : dealerData.RateCard),
                                (dealerData === null || dealerData === void 0 ? void 0 : dealerData.RateCardUrl) ? (React.createElement(Card, { sx: { maxWidth: 150, minHeight: 70, maxHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                                    React.createElement(CardMedia, { component: 'iframe', title: "PDF Viewer", src: dealerData === null || dealerData === void 0 ? void 0 : dealerData.RateCardUrl }))) :
                                    (React.createElement(Typography, { sx: { textAlign: 'start', width: '100%' } }, "No file available"))))),
                    React.createElement(Box, { className: "granted-apps", mb: 1 },
                        React.createElement(Stack, { direction: 'column', alignItems: 'baseline !important', className: "content-wrapper content-wrapper-rate-card content-wrapper-edit" },
                            React.createElement(Typography, null, "FAQ"),
                            React.createElement(Divider, { sx: { width: '100%' } }),
                            React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', py: 1.5, spacing: 2 },
                                (dealerData === null || dealerData === void 0 ? void 0 : dealerData.FAQ) && React.createElement(Typography, { width: '100%', className: "fileUploadHeader", sx: { fontWeight: '500 !important' } }, dealerData === null || dealerData === void 0 ? void 0 : dealerData.FAQ),
                                (dealerData === null || dealerData === void 0 ? void 0 : dealerData.FAQUrl) ? (React.createElement(Card, { sx: { maxWidth: 150, minHeight: 70, maxHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                                    React.createElement(CardMedia, { component: 'iframe', title: "PDF Viewer", src: dealerData === null || dealerData === void 0 ? void 0 : dealerData.FAQUrl }))) :
                                    (React.createElement(Typography, { sx: { width: '100%', textAlign: 'start' } }, "No file available"))))),
                    React.createElement(Box, { className: "granted-apps" },
                        React.createElement(Stack, { direction: 'row', className: "content-wrapper content-wrapper-edit" },
                            React.createElement(Typography, null, "Font Selected:"),
                            React.createElement(Typography, null, dealerData === null || dealerData === void 0 ? void 0 : dealerData.FontStyle))),
                    React.createElement(Box, { className: "granted-apps", mb: 1 },
                        React.createElement(Stack, { className: "content-wrapper content-wrapper-edit" },
                            React.createElement(Typography, { className: 'header-txt', width: '100%' }, "Logo Upload"),
                            React.createElement(Divider, { sx: { width: '100%' } }),
                            React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', py: 1.5, spacing: 2 },
                                React.createElement(Typography, { width: '100%', className: "fileUploadHeader defaultLogoText", sx: { fontWeight: '500 !important' } },
                                    "Landing Page Background",
                                    React.createElement(FormGroup, null,
                                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, name: 'IsDefaultLoginBackground', checked: Boolean(dealerData === null || dealerData === void 0 ? void 0 : dealerData.IsDefaultLoginBackground), disabled: true, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null) }), label: "Check this box to use a default background" }))),
                                (dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginBackgroundUrl) ?
                                    React.createElement(Card, { sx: { maxWidth: 150, minHeight: 70, maxHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                                        React.createElement(CardMedia, { component: "img", alt: "No Preview", height: "auto", image: dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginBackgroundUrl })) :
                                    React.createElement(Card, { sx: { maxWidth: 150, minHeight: 70, maxHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                                        React.createElement(CardMedia, { component: "img", alt: "No Preview", height: "auto", title: 'Default', src: DefaultWhiteLogo }))),
                            React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', py: 1.5, spacing: 2 },
                                React.createElement(Typography, { width: '100%', className: "fileUploadHeader defaultLogoText", sx: { fontWeight: '500 !important' } },
                                    "Landing Page Logo",
                                    React.createElement(FormGroup, null,
                                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, name: 'IsDefaultLoginLogo1', checked: Boolean(dealerData === null || dealerData === void 0 ? void 0 : dealerData.IsDefaultLoginLogo1), disabled: true, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null) }), label: "Check this box to use a default logo" }))),
                                (dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginLogo1Url) ?
                                    React.createElement(Card, { sx: { maxWidth: 150, minHeight: 70, maxHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                                        React.createElement(CardMedia, { component: "img", alt: "No Preview", height: "auto", image: dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginLogo1Url })) :
                                    React.createElement(Card, { sx: { maxWidth: 150, minHeight: 70, maxHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                                        React.createElement(CardMedia, { component: "img", alt: "No Preview", height: "auto", title: 'Default', src: DefaultWhiteLogo }))),
                            React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', py: 1.5, spacing: 2 },
                                React.createElement(Typography, { width: '100%', className: 'fileUploadHeader defaultLogoText', sx: { fontWeight: '500 !important' } },
                                    "Login Screen Logo",
                                    React.createElement(FormGroup, null,
                                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, name: 'IsDefaultLoginLogo2', checked: Boolean(dealerData === null || dealerData === void 0 ? void 0 : dealerData.IsDefaultLoginLogo2), disabled: true, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null) }), label: "Check this box to use a default logo" }))),
                                (dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginLogo2Url) ?
                                    React.createElement(Card, { sx: { maxWidth: 150, minHeight: 70, maxHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                                        React.createElement(CardMedia, { component: "img", alt: "No Preview", height: "auto", image: dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginLogo2Url })) :
                                    React.createElement(Card, { sx: { maxWidth: 150, minHeight: 70, maxHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                                        React.createElement(CardMedia, { component: "img", alt: "No Preview", height: "auto", title: 'Default', src: DefaultWhiteLogo }))),
                            React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', py: 1.5, spacing: 2 },
                                React.createElement(Typography, { width: '100%', className: 'fileUploadHeader defaultLogoText', sx: { fontWeight: '500 !important' } },
                                    "Header Logo",
                                    React.createElement(FormGroup, null,
                                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, name: 'IsDefaultLandingLogo', checked: Boolean(dealerData === null || dealerData === void 0 ? void 0 : dealerData.IsDefaultLandingLogo), disabled: true, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null) }), label: "Check this box to use a default logo" }))),
                                (dealerData === null || dealerData === void 0 ? void 0 : dealerData.LandingLogoUrl) ?
                                    React.createElement(Card, { sx: { maxWidth: 150, minHeight: 70, maxHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                                        React.createElement(CardMedia, { component: "img", alt: "No Preview", height: "auto", image: dealerData === null || dealerData === void 0 ? void 0 : dealerData.LandingLogoUrl })) :
                                    React.createElement(Card, { sx: { maxWidth: 150, minHeight: 70, maxHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                                        React.createElement(CardMedia, { component: "img", alt: "No Preview", height: "auto", title: 'Default', src: DefaultWhiteLogo }))))),
                    React.createElement(Box, { className: "granted-apps", mb: 1 },
                        React.createElement(Stack, { className: "content-wrapper content-wrapper-edit" },
                            React.createElement(Typography, { className: 'header-txt', width: '100%' }, "Theme Configuration"),
                            React.createElement(Divider, { sx: { width: '100%' } }),
                            React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center', mb: '16px' },
                                React.createElement(Typography, { sx: { color: '#000', fontSize: '14px !important', fontWeight: '500 !important' } }, "Primary Theme:"),
                                React.createElement(Box, null,
                                    React.createElement("input", { style: { width: '140px', height: '50px' }, type: 'color', value: dealerData === null || dealerData === void 0 ? void 0 : dealerData.PrimaryThemeColor, disabled: true }))),
                            React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center', mb: '16px' },
                                React.createElement(Typography, { sx: { color: '#000', fontSize: '14px !important', fontWeight: '500 !important' } }, "Secondary Theme:"),
                                React.createElement(Box, null,
                                    React.createElement("input", { style: { width: '140px', height: '50px' }, type: 'color', value: dealerData === null || dealerData === void 0 ? void 0 : dealerData.SecondaryThemeColor, disabled: true }))))))))));
};
export default DealerManagementView;
