var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import constants from "../config/constants";
export var pwdValidation = function (password, initial_pwdErrors, component) {
    var validatePwd = __assign({}, initial_pwdErrors);
    var _validatePwd;
    validatePwd.isValidLength = password.trim().length >= 10;
    validatePwd.doesHvUCase = constants.regex.atleastOneUCharRegExp.test(password);
    validatePwd.doesHvLCase = constants.regex.atleastOneLCharRegExp.test(password);
    validatePwd.doesHvNum = constants.regex.atleastOneNumRegExp.test(password);
    validatePwd.doesHvSplChar = constants.regex.atleastOneSplCharRegExp.test(password);
    if (component !== undefined && component === 'passwordStrength') {
        var isValidLength = validatePwd.isValidLength, restObj = __rest(validatePwd, ["isValidLength"]);
        _validatePwd = __assign({}, restObj);
    }
    var isValid = Object.values(_validatePwd || validatePwd).every(function (criteria) { return criteria === true; }) || !password;
    return (component !== undefined && component === 'resetPassword') ? _validatePwd || validatePwd : isValid;
};
export var formatPhoneNumber = function (phoneNumber) {
    var cleaned = ('' + phoneNumber).replace(/\D/g, '');
    var match = RegExp(/^(\d{3})(\d{3})(\d{4})$/).exec(cleaned);
    if (match) {
        return "(".concat(match[1], ") ").concat(match[2], "-").concat(match[3]);
    }
    return phoneNumber;
};
export var deepEqual = function (obj1, obj2) {
    if (obj1 === obj2) {
        return true;
    }
    if (typeof obj1 !== typeof obj2) {
        return false;
    }
    if (obj1 === null || obj2 === null) {
        return false;
    }
    if (typeof obj1 === 'object') {
        if (Array.isArray(obj1)) {
            if (!Array.isArray(obj2) || obj1.length !== obj2.length) {
                return false;
            }
            for (var i = 0; i < obj1.length; i++) {
                if (!deepEqual(obj1[i], obj2[i])) {
                    return false;
                }
            }
            return true;
        }
        else {
            var keys1 = Object.keys(obj1);
            var keys2 = Object.keys(obj2);
            if (keys1.length !== keys2.length) {
                return false;
            }
            for (var _i = 0, keys1_1 = keys1; _i < keys1_1.length; _i++) {
                var key = keys1_1[_i];
                if (!deepEqual(obj1[key], obj2[key])) {
                    return false;
                }
            }
            return true;
        }
    }
    return false;
};
export var AppTypes = [
    "Credit Application", "Line of Credit Application", "SCH-TakeDown Application"
];
