var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import TextField from '../../components/FormComponents/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router';
import { NavigationConfig } from '../../utils/navigation_config';
import PasswordStrength from './PasswordStrength';
import { pwdValidation } from '../../utils/commonUtilities';
import CloseIcon from '../../assets/images/close-icon.svg';
import { useLocation } from 'react-router-dom';
import { loginSignup_asyncActions } from '../../asyncActions/loginSignup_asynActions';
import { useDispatch } from 'react-redux';
import messages from '../../utils/messages.json';
var initial_pwdValues = { pwd: '', rePwd: '' };
var initial_pwdErrors = { isValidLength: false, doesHvUCase: false, doesHvLCase: false, doesHvNum: false, doesHvSplChar: false };
var ResetPassword = function (props) {
    var navigate = useNavigate();
    var location = useLocation();
    var dispatch = useDispatch();
    var navConfig = NavigationConfig;
    var _a = React.useState(__assign({}, initial_pwdValues)), passwordValues = _a[0], setPasswordValues = _a[1];
    var _b = React.useState(__assign({}, initial_pwdValues)), passwordErrors = _b[0], setPasswordErrors = _b[1];
    var _c = React.useState({}), passwordValidation = _c[0], setPasswordValidation = _c[1];
    var _d = React.useState(''), apiError = _d[0], setApiError = _d[1];
    var handlePwdChange = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        setPasswordErrors(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[name] = '', _a)));
        });
        setPasswordValues(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[name] = value, _a)));
        });
        if (name === 'pwd') {
            var validatePwd = pwdValidation(value, initial_pwdErrors, 'resetPassword');
            setPasswordValidation(validatePwd);
        }
    };
    var handleOnKeyDown = function (e) {
        e.persist();
        if (e.key === 'Enter') {
            handleOnResetBtnClick();
        }
    };
    var arePwdDetailsValid = function () {
        var pwd = passwordValues.pwd, rePwd = passwordValues.rePwd;
        var validationValues = __assign({}, passwordValidation);
        var pwdError = '';
        if (pwd.trim()) {
            if (!Object.values(validationValues).every(function (criteria) { return criteria === true; })) {
                pwdError = messages['Password does not meet the requirements'];
            }
        }
        else {
            pwdError = messages['This field is required'];
        }
        var rePwdError = '';
        if (rePwd.trim()) {
            if (pwd !== rePwd) {
                rePwdError = messages['Password mismatch. Please use the same password'];
            }
        }
        else {
            rePwdError = messages['This field is required'];
        }
        var tempPwdErrors = {
            pwd: pwdError,
            rePwd: rePwdError,
        };
        setPasswordErrors(function (prevState) { return (__assign({}, tempPwdErrors)); });
        return Object.values(tempPwdErrors).every(function (item) { return item === ''; });
    };
    var handleOnResetBtnClick = function () {
        var _a, _b, _c;
        if (arePwdDetailsValid()) {
            var resetPasswordPayload = {
                Otp: (_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.otp,
                OldPassword: (_b = location === null || location === void 0 ? void 0 : location.state) === null || _b === void 0 ? void 0 : _b.oldPassword,
                Email: (_c = location === null || location === void 0 ? void 0 : location.state) === null || _c === void 0 ? void 0 : _c.email,
                Password: passwordValues.pwd
            };
            dispatch(loginSignup_asyncActions.resetPassword(resetPasswordPayload))
                .then(function (response) {
                var _a;
                if (((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.statusCode) === 200) {
                    navigate(navConfig.Account.Verify_Success);
                }
                else {
                    setApiError(function () { return (response.data.message); });
                }
            }).catch(function (error) {
                setApiError(function () { return (error.message); });
            });
        }
    };
    var handleOnBlur = function (e) {
        e.persist();
        if (e.target.name === 'pwd') {
            setPasswordErrors(function (prevState) { return (__assign(__assign({}, prevState), { pwd: Object.values(passwordValidation).every(function (criteria) { return criteria === true; }) ? '' : 'Password doesn’t meet criteria' })); });
        }
        else {
            setPasswordErrors(function (prevState) { return (__assign(__assign({}, prevState), { rePwd: (e.target.value === passwordValues.pwd) ? '' : 'Password mismatch' })); });
        }
    };
    var getPasswordErrorAndStrenthMeter = function () {
        if (passwordErrors.pwd) {
            return passwordErrors.pwd;
        }
        else {
            return React.createElement(PasswordStrength, { password: passwordValues.pwd, initial_pwdErrors: initial_pwdErrors });
        }
    };
    var handleOnPasswordPaste = function (event) {
        event.preventDefault();
    };
    return (React.createElement(Grid, { container: true, alignContent: 'flex-start' },
        React.createElement(IconButton, { onClick: function () { return navigate(navConfig.Account.Login); }, className: "close-icon" },
            React.createElement("img", { src: CloseIcon })),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, pb: '40px' },
            React.createElement(Typography, { fontSize: { xs: '22px', sm: '24px', md: '24px', lg: '30px' }, fontWeight: 'bold', align: 'left' }, "Reset Password")),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12, pb: { xs: '34px', sm: '34px', md: '34px' } },
            React.createElement(TextField, { name: 'pwd', label: 'New Password', placeholder: '********', value: passwordValues.pwd, inputType: "password", onChange: handlePwdChange, onBlur: handleOnBlur, error_message: getPasswordErrorAndStrenthMeter(), error: Boolean(passwordErrors.pwd), fullWidth: true })),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12, pb: { xs: '34px', sm: '34px', md: '34px' } },
            React.createElement(TextField, { name: 'rePwd', label: 'Confirm Password', placeholder: '********', value: passwordValues.rePwd, inputType: "password", onChange: handlePwdChange, onPaste: handleOnPasswordPaste, onKeyDown: handleOnKeyDown, onBlur: handleOnBlur, error: Boolean(passwordErrors.rePwd), error_message: passwordErrors.rePwd, fullWidth: true })),
        React.createElement(Typography, { variant: 'body1', className: 'api-eeror' }, apiError),
        React.createElement(Button, { fullWidth: true, variant: 'contained', className: "btn-txt", onClick: handleOnResetBtnClick }, "Reset Password"),
        React.createElement("div", { className: 'reset_password' },
            React.createElement(Typography, null,
                "Password must contain:",
                React.createElement("ul", null,
                    React.createElement("li", null,
                        "Minimum of 10 characters ",
                        passwordValues.pwd.length >= 10 && React.createElement(CheckCircleIcon, { fontSize: 'small' })),
                    React.createElement("li", null,
                        "At least One uppercase and lowercase  ",
                        passwordValidation.doesHvUCase && React.createElement(CheckCircleIcon, { fontSize: 'small' })),
                    React.createElement("li", null,
                        "At least One numeric ",
                        passwordValidation.doesHvNum && React.createElement(CheckCircleIcon, { fontSize: 'small' })),
                    React.createElement("li", null,
                        "At least One special character ",
                        React.createElement("br", null),
                        "  (Excludes \u201C#\u201D and \u201C@\u201D) ",
                        passwordValidation.doesHvSplChar && React.createElement(CheckCircleIcon, { fontSize: 'small' })))))));
};
export default ResetPassword;
