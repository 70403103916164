import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { App } from './app_reducer';
import { UserReducer } from './user_reducer';
export var Reducer = combineReducers({
    App: App,
    User: UserReducer
});
export var persistConfig = {
    key: 'root',
    timeout: 0,
    storage: storage,
    whitelist: ['App', 'User'],
    blacklist: []
};
