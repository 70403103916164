var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { InputLabel, FormControl, FormHelperText, InputAdornment, Tooltip, OutlinedInput, Box, Typography } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
var TextField = React.forwardRef(function (props, ref) {
    var _a;
    var _b = React.useState(false), pwdTypeToggle = _b[0], setPwdTypeToggle = _b[1];
    var _c = React.useState(props.value), val = _c[0], setVal = _c[1];
    return (React.createElement(FormControl, { fullWidth: props.fullWidth, error: props.error, variant: "standard", className: props.disabled ? 'disable-fields outlined-text-field' : 'outlined-text-field' },
        React.createElement(InputLabel, { required: props.required, shrink: true, htmlFor: props.id },
            props.label.toUpperCase(),
            props.isMandatoryField &&
                React.createElement(Box, { component: 'span', color: "red" }, "*"),
            props.tooltip &&
                React.createElement(Tooltip, { title: props.tooltip },
                    React.createElement(InfoOutlinedIcon, null))),
        React.createElement(OutlinedInput, __assign({ inputRef: ref, name: props.name, id: props.id, size: 'small', onChange: function (e) {
                var value = e.target.value;
                if (props.testRegExp) {
                    value = props.testRegExp.test(value) ? value : value.slice(0, -1);
                }
                else if (props.regExp) {
                    value = value === null || value === void 0 ? void 0 : value.replace(props.regExp, '');
                }
                if (props.shouldUpdateValueImmediately || props.shouldUpdateValueImmediately === undefined) {
                    setVal(value);
                }
                props.onChange(e);
            }, onPaste: props.onPaste, value: val || props.value, defaultValue: (_a = props.value) !== null && _a !== void 0 ? _a : '', multiline: props.multiline, maxRows: props.maxRows, minRows: props.minRows, disabled: props.disabled, onKeyDown: props.onKeyDown, onBlur: props.onBlur, placeholder: props.placeholder.toUpperCase(), inputProps: { maxLength: props.maxLength ? props.maxLength : 50 }, fullWidth: props.fullWidth, className: props.className || '' }, (props.startAdornment && {
            startAdornment: React.createElement(InputAdornment, { position: "start", className: 'startAdornment-input' },
                React.createElement(Typography, { variant: 'body1' }, props.startAdornment)),
        }), (props.endAdornment && {
            endAdornment: React.createElement(InputAdornment, { position: "start" }, props.endAdornment),
        }), ((props.inputType === "password") && {
            endAdornment: (React.createElement(InputAdornment, { position: "end", onClick: function () { setPwdTypeToggle(!pwdTypeToggle); } }, pwdTypeToggle ? React.createElement(VisibilityOffOutlinedIcon, null) : React.createElement(VisibilityOutlinedIcon, null))),
            type: pwdTypeToggle ? 'text' : 'password'
        }), (props.maskValue && {
            type: 'password'
        }))),
        React.createElement(FormHelperText, null, props.error_message)));
});
export default React.memo(TextField);
