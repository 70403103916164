var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import promiseMiddleware from './../middleware/promise-middleware';
import { persistConfig, Reducer } from './../reducers/index';
var reduxLogger = createLogger({
    collapsed: true,
    stateTransformer: function (state) {
        return state;
    }
});
var persistedReducer = persistReducer(persistConfig, Reducer);
function configuredStore() {
    var _store = configureStore({
        reducer: persistedReducer,
        middleware: _getMiddleware()
    });
    _enableHotLoader(_store);
    return _store;
}
function _getMiddleware() {
    var middleware = [
        promiseMiddleware,
        thunk
    ];
    if (__DEV__) {
        middleware = __spreadArray(__spreadArray([], middleware, true), [reduxLogger], false);
    }
    return middleware;
}
function _enableHotLoader(_store) {
    if (!__DEV__) {
        return;
    }
    var hot = module.hot;
    if (hot) {
        hot.accept('./../reducers', function () {
            var nextRootReducer = require('./../reducers');
            _store.replaceReducer(nextRootReducer);
        });
    }
}
export var store = configuredStore();
export var persistor = persistStore(store);
store.subscribe(function () {
    try {
        var storeSize = (JSON.stringify(store.getState()).length / 1000000);
        if (storeSize > 4) {
            alert('Low offline storage space. Please go online to free space.');
        }
    }
    catch (e) {
        alert('Very low offline storage space. Please go online to free space.');
    }
});
