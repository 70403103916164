import { Stack, Box, Typography, Select, MenuItem, FormHelperText, FormControl } from '@mui/material';
import * as React from 'react';
var SelectDD = function (_a) {
    var options = _a.options, handleChange = _a.handleChange, DropDownLabel = _a.DropDownLabel, selectedValue = _a.selectedValue, isMandatoryField = _a.isMandatoryField, labelKey = _a.labelKey, valueKey = _a.valueKey, isValueAnObject = _a.isValueAnObject, name = _a.name, placeHolder = _a.placeHolder, errorMessage = _a.errorMessage, disabled = _a.disabled, handleBlur = _a.handleBlur;
    return (React.createElement(Stack, { className: disabled ? 'disable-fields custom-select-container' : 'custom-select-container' },
        React.createElement(FormControl, { error: Boolean(errorMessage), className: 'select-dropdown' },
            React.createElement(Box, { component: 'span' },
                React.createElement(Typography, { fontSize: '14px', className: errorMessage ? 'error' : '' },
                    DropDownLabel.toUpperCase(),
                    isMandatoryField &&
                        React.createElement(Box, { component: 'span', color: "red" }, "*"))),
            React.createElement(Select, { className: 'mui-selector', name: name, value: selectedValue, onChange: handleChange, onBlur: handleBlur, displayEmpty: true, inputProps: { 'aria-label': 'Without label' }, id: "status", size: 'small', disabled: disabled, renderValue: function (selected) {
                    if (!selected) {
                        return React.createElement(Typography, { color: 'gray' }, placeHolder === null || placeHolder === void 0 ? void 0 : placeHolder.toUpperCase());
                    }
                    if (valueKey && labelKey) {
                        var selectedItem = void 0;
                        if (isValueAnObject) {
                            selectedItem = (options === null || options === void 0 ? void 0 : options.length) && (options === null || options === void 0 ? void 0 : options.filter(function (item) { return item[valueKey].labelString === selected; }));
                        }
                        else {
                            selectedItem = (options === null || options === void 0 ? void 0 : options.length) && (options === null || options === void 0 ? void 0 : options.filter(function (item) { return item[valueKey] === selected; }));
                        }
                        return (selectedItem && selectedItem[0]) ? selectedItem[0][labelKey] : '';
                    }
                    else {
                        return selected;
                    }
                } }, options && options.length > 0 ?
                options.map(function (item, index) {
                    return (typeof item === 'string' ?
                        React.createElement(MenuItem, { className: 'select-lebel-text', key: index, value: item }, item)
                        :
                            React.createElement(MenuItem, { key: index, className: 'select-lebel-text', value: valueKey && item[valueKey] ? item[valueKey] : item.value }, labelKey && item[labelKey] ? item[labelKey] : item.label));
                })
                : React.createElement(MenuItem, { disabled: true }, "No Options")),
            React.createElement(FormHelperText, null, errorMessage))));
};
export default React.memo(SelectDD);
