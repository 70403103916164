var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Button, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MUITimePicker from '../../components/FormComponents/TimePicker';
import MUIDatePicker from '../../components/FormComponents/DatePicker';
import moment from 'moment';
import constants from '../../config/constants';
import AutoCompleteDD from '../../components/FormComponents/AutoCompleteDD';
import { api_service } from '../../api/api_service';
import { api_urls } from '../../api/api_urls';
import ModuleTable from '../../components/UtilityComponents/moduleTable';
import './../../assets/scss/moduleTable.scss';
import { GenericMarketingTable, PortalMarketingTable } from '../../config/TableColumns';
import CustomTextarea from '../../components/FormComponents/TextAreaField';
import { utility } from '../../utils';
import ConfirmationAndInformationPoup, { POPUP_TYPE } from '../../components/UtilityComponents/ConfirmationAndInformationPoup';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
var initialGenNotificationInfo = {
    Id: '',
    Message: '',
    Portal: '',
    FromDate: '',
    FromTime: '',
    ToDate: '',
    ToTime: '',
    OffSetTIme: ''
};
var NotificationsTab = function (_a) {
    var selectedTab = _a.selectedTab;
    var _b = React.useState(__assign({}, initialGenNotificationInfo)), notificationInfo = _b[0], setNotificationInfo = _b[1];
    var _c = useState({}), errors = _c[0], setErrors = _c[1];
    var _d = useState(undefined), vendorList = _d[0], setVendorList = _d[1];
    var _e = useState({ Id: '', Name: '' }), selectedVendor = _e[0], setSelectedVendor = _e[1];
    var _f = useState([]), contentListData = _f[0], setContentListData = _f[1];
    var _g = useState(false), openConfirmationPopup = _g[0], setOpenConfirmationPopup = _g[1];
    var _h = useState(false), openSuccessPopup = _h[0], setOpenSuccessPopup = _h[1];
    var _j = useState(''), notificationId = _j[0], setNotificationId = _j[1];
    var _k = useState(false), open = _k[0], setOpen = _k[1];
    var _l = useState(false), isEdit = _l[0], setIsEdit = _l[1];
    var scrollTimeoutId;
    useEffect(function () {
        api_service.get({
            endPoint: api_urls.VENDOR_LIST,
        }).then(function (res) {
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                setVendorList(res.data.data);
            }
        }).catch(function (err) { });
    }, []);
    var getNotificationsData = function () {
        var today = moment();
        var payload = {};
        payload.NotificationType = selectedTab == 1 ? 'GENERAL' : 'PORTAL';
        api_service.get({
            payLoad: payload,
            endPoint: api_urls.MARKETING_CONTENT_LIST,
        }).then(function (res) {
            var _a;
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                var resp = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data;
                resp.forEach(function (item) {
                    var startDate = moment(item.StartDateTime);
                    var endDate = moment(item.EndDateTime);
                    var isExpired = today.isAfter(endDate, 'minute');
                    var isLive = today.isBetween(startDate, endDate, 'minute', '[]');
                    var isScheduled = today.isBefore(startDate, 'minute');
                    if (isExpired && item.Status !== 2) {
                        item.Status = 3;
                    }
                    else if (isLive && item.Status !== 2) {
                        item.Status = 1;
                    }
                    else if (isScheduled && item.Status !== 2) {
                        item.Status = 4;
                    }
                });
                resp === null || resp === void 0 ? void 0 : resp.map(function (respObj) {
                    respObj.FromDate = moment(respObj.StartDateTime).format('DD/MM/YYYY');
                    respObj.ToDate = moment(respObj.EndDateTime).format('DD/MM/YYYY');
                    respObj.FromTime = separateDateTime(new Date(respObj.StartDateTime), 'FromTime');
                    respObj.ToTime = separateDateTime(new Date(respObj.EndDateTime), 'ToTime');
                    respObj.CreatedTime = moment(respObj.CreatedTime).tz('America/New_York');
                });
                setContentListData(resp);
            }
        }).catch(function (err) { });
    };
    useEffect(function () {
        getNotificationsData();
        return function () {
            clearTimeout(scrollTimeoutId);
        };
    }, [selectedTab]);
    var handlePortalSelection = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        var vendor = vendorList === null || vendorList === void 0 ? void 0 : vendorList.find(function (item) { return item.Id === value; });
        setSelectedVendor({ Id: value, Name: vendor === null || vendor === void 0 ? void 0 : vendor.Name });
        var portalName = vendor === null || vendor === void 0 ? void 0 : vendor.Name;
        setNotificationInfo(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[name] = portalName, _a)));
        });
        setErrors(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[name] = '', _a)));
        });
    };
    var handleNotificationMsg = function (event) {
        var _a = event.target, name = _a.name, value = _a.value;
        setNotificationInfo(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[name] = value, _a)));
        });
        setErrors(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[name] = '', _a)));
        });
    };
    var validateTimeInput = function (timeString) {
        var timeRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9]:[AP]M$/i;
        return timeRegex.test(timeString);
    };
    var handleTimeChange = function (selectedTimeValue, type) {
        var formattedTime = moment(selectedTimeValue).format('hh:mm:A');
        if (!validateTimeInput(formattedTime)) {
            setErrors(function (prevState) {
                var _a;
                return (__assign(__assign({}, prevState), (_a = {}, _a[type] = 'Invalid time format', _a)));
            });
            return;
        }
        if (type === 'FromTime' || type === 'ToTime') {
            setNotificationInfo(function (prevState) {
                var _a;
                return (__assign(__assign({}, prevState), (_a = {}, _a[type] = selectedTimeValue, _a)));
            });
            setErrors(function (prevState) {
                var _a;
                return (__assign(__assign({}, prevState), (_a = {}, _a[type] = '', _a)));
            });
        }
    };
    var handleDateChange = function (dateVal, type) {
        var selectedDate = moment(dateVal).format(constants.US_DATE_FORMAT);
        var parsedDate = moment(selectedDate, constants.US_DATE_FORMAT, true);
        if (parsedDate.isValid() && parsedDate.isBetween(moment('01/01/1000', constants.US_DATE_FORMAT), moment('12/31/9999', constants.US_DATE_FORMAT))) {
            if (type === 'FromDate' || type === 'ToDate') {
                setNotificationInfo(function (prevState) {
                    var _a;
                    return (__assign(__assign({}, prevState), (_a = {}, _a[type] = selectedDate, _a)));
                });
                setErrors(function (prevState) {
                    var _a;
                    return (__assign(__assign({}, prevState), (_a = {}, _a[type] = '', _a)));
                });
            }
        }
        else if (dateVal == null) {
            setErrors('');
        }
        else {
            setErrors(function (prevErrors) {
                var _a;
                return (__assign(__assign({}, prevErrors), (_a = {}, _a[type] = 'Invalid Date', _a)));
            });
        }
    };
    var validateFormData = function (name, value) {
        if (name == 'Message' && value.trim() === '') {
            return 'This field is required';
        }
        if (selectedTab == 2) {
            if (name == 'Portal' && value.trim() === '') {
                return 'This field is required';
            }
        }
        if (name == 'FromDate' && value.trim() === '') {
            return 'This field is required';
        }
        if (name == 'FromTime' && !value) {
            return (errors === null || errors === void 0 ? void 0 : errors.FromTime) ? errors === null || errors === void 0 ? void 0 : errors.FromTime : 'This field is required';
        }
        if (name == 'ToDate' && value.trim() === '') {
            return 'This field is required';
        }
        if (name == 'ToTime' && !value) {
            return (errors === null || errors === void 0 ? void 0 : errors.ToTime) ? errors === null || errors === void 0 ? void 0 : errors.ToTime : 'This field is required';
        }
        return '';
    };
    var isValidData = function () {
        var updatedErrors = __assign({}, errors);
        for (var key in notificationInfo) {
            var error = validateFormData(key, notificationInfo[key]);
            updatedErrors[key] = error;
        }
        setErrors(updatedErrors);
        var hasErrors = Object.values(updatedErrors).some(function (val) { return val !== ''; });
        return !hasErrors;
    };
    var combineDateTime = function (date, time) {
        var combinedDateTime = moment(date).set({
            hour: moment(time).hours(),
            minute: moment(time).minutes(),
            second: moment(time).seconds(),
        });
        return combinedDateTime.toDate();
    };
    var separateDateTime = function (dateTime, type) {
        if (dateTime) {
            var time = moment(dateTime).toDate();
            var TimeFormat = moment(time).format('hh:mm A');
            return TimeFormat;
        }
    };
    var handleSubmitNotificationForm = function () {
        var fromDate = moment(notificationInfo.FromDate);
        var toDate = moment(notificationInfo.ToDate);
        var fromTime = moment(notificationInfo.FromTime, 'hh:mm:A');
        var toTime = moment(notificationInfo.ToTime, 'hh:mm:A');
        if ((toDate.isBefore(fromDate) || toDate.isSame(fromDate)) && (toTime.isBefore(fromTime) || toTime.isSame(fromTime))) {
            setErrors(function (prevErrors) { return (__assign(__assign({}, prevErrors), { ToTime: 'To Date and Time cannot be less than or equal to From Date and Time' })); });
            return;
        }
        if (moment(combineDateTime(new Date(notificationInfo.ToDate), new Date(notificationInfo.ToTime))).isSameOrBefore(moment())) {
            setErrors(function (prevErrors) { return (__assign(__assign({}, prevErrors), { ToTime: 'To Time cannot be less than or equal to current Time' })); });
            return;
        }
        if (isValidData()) {
            var payload = {
                Message: notificationInfo.Message,
                StartDateTime: combineDateTime(new Date(notificationInfo.FromDate), new Date(notificationInfo.FromTime)),
                EndDateTime: combineDateTime(new Date(notificationInfo.ToDate), new Date(notificationInfo.ToTime)),
                VendorId: (selectedVendor === null || selectedVendor === void 0 ? void 0 : selectedVendor.Id) ? selectedVendor === null || selectedVendor === void 0 ? void 0 : selectedVendor.Id : null,
                OffSetTIme: new Date().getTimezoneOffset()
            };
            if (notificationInfo.Id) {
                payload.Id = notificationInfo.Id;
            }
            api_service.post({
                endPoint: api_urls.MARKETING_CONTENT_SAVE,
                payLoad: payload,
                showLoader: true
            }).then(function (res) {
                var _a;
                if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                    setOpenSuccessPopup(true);
                    setNotificationInfo(__assign({}, initialGenNotificationInfo));
                    setSelectedVendor({ Id: '', Name: '' });
                }
                else {
                    utility.alert((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.message);
                }
            }).catch(function (err) {
                console.log(' handleSubmitNotificationForm~ err:', err);
            });
        }
    };
    var tableActionHandler = function (rowData, actionType) {
        if (actionType == "DELETE") {
            setNotificationId(rowData.Id);
            setOpen(true);
        }
        else if (actionType == "EDIT") {
            handleEditNotifications(rowData);
            var element_1 = document.getElementById('jump-here');
            scrollTimeoutId = setTimeout(function () {
                element_1 === null || element_1 === void 0 ? void 0 : element_1.scrollIntoView({ behavior: "smooth" });
            }, 250);
        }
    };
    var handleEditNotifications = function (rowData) {
        var editableInfo = __assign({}, rowData);
        editableInfo.Portal = rowData === null || rowData === void 0 ? void 0 : rowData.Portal;
        editableInfo.Message = rowData.Message;
        editableInfo.Id = rowData.Id;
        if (rowData.Status == 3) {
            editableInfo.FromDate = '';
            editableInfo.ToDate = '';
            editableInfo.FromTime = '';
            editableInfo.ToTime = '';
        }
        else {
            editableInfo.FromDate = rowData.StartDateTime;
            editableInfo.ToDate = rowData.EndDateTime;
            editableInfo.FromTime = moment(rowData.StartDateTime);
            editableInfo.ToTime = moment(rowData.EndDateTime);
        }
        if (rowData.Portal) {
            var vendor = vendorList === null || vendorList === void 0 ? void 0 : vendorList.find(function (item) { return item.Name === rowData.Portal; });
            setSelectedVendor({ Id: vendor === null || vendor === void 0 ? void 0 : vendor.Id, Name: vendor === null || vendor === void 0 ? void 0 : vendor.Name });
        }
        setNotificationInfo(editableInfo);
        setIsEdit(true);
        var updatedErrors = __assign({}, errors);
        for (var key in editableInfo) {
            var error = void 0;
            if (rowData.Status == 3 && (key == 'FromDate' || key == 'ToDate' || key == 'FromTime' || key == 'ToTime')) {
                error = null;
            }
            else {
                error = validateFormData(key, editableInfo[key]);
            }
            updatedErrors[key] = error;
        }
        setErrors(updatedErrors);
    };
    var handleDeleteNotifications = function () {
        api_service.delete({
            endPoint: "".concat(api_urls.DELETE_NOTIFICATION_DATA, "?NotificationId=").concat(notificationId),
        }).then(function (res) {
            if (res && res.data.statusCode === 200) {
                setNotificationId('');
                setNotificationInfo(__assign({}, initialGenNotificationInfo));
                getNotificationsData();
            }
        }).catch(function (err) {
            console.log("error in handleDeleteNotifications ");
        });
    };
    var currentDate = moment().format('YYYY-MM-DD');
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { className: "tab-content", id: "jump-here" },
            React.createElement(Typography, { component: 'p', className: 'title' }, selectedTab == 1 ? 'General Notification' : "Portal Notification"),
            selectedTab == 2 &&
                React.createElement(AutoCompleteDD, { options: vendorList !== null && vendorList !== void 0 ? vendorList : [], displayKey: 'Name', valueKey: 'Id', label: 'Portal Name', placeholder: 'select portal', handleChange: handlePortalSelection, selectedValue: selectedVendor, name: "Portal", errorMessage: errors.Portal, isMandatoryField: true, handleBlur: function () { } }),
            React.createElement(CustomTextarea, { fieldName: 'Message', onChange: handleNotificationMsg, placeholder: 'Enter Notification Message', rows: 7, value: notificationInfo === null || notificationInfo === void 0 ? void 0 : notificationInfo.Message, error: errors.Message }),
            React.createElement(Typography, { component: 'p', className: 'title' }, "Select Date Frequency"),
            React.createElement(Grid, { container: true, xs: 12, mb: 3 },
                React.createElement(Grid, { container: true, item: true, xs: 12, md: 6 },
                    React.createElement(Grid, { item: true, xs: 6, sx: {
                            paddingRight: '12px', '& .MuiInputBase-root': { borderRadius: '8px' }, '& .clear-date': { right: '0 !important' }, '& .MuiInputAdornment-root': {
                                '& .MuiButtonBase-root': { marginLeft: '-8px' }
                            }
                        } },
                        React.createElement(MUIDatePicker, { labelText: "From Date", isMandatory: true, selectedDate: notificationInfo.FromDate, dateChangeHandler: function (value) { return handleDateChange(value, 'FromDate'); }, maxDate: currentDate, errorMessage: errors.FromDate, clearDate: function () { return setNotificationInfo(function (prevState) { return (__assign(__assign({}, prevState), { FromDate: '' })); }); } })),
                    React.createElement(Grid, { item: true, xs: 6, sx: {
                            position: 'relative',
                            '& .MuiButtonBase-root ': {
                                right: '30px',
                            },
                            '& .MuiInputBase-root': {
                                borderRadius: '8px',
                                '& input': {
                                    textTransform: 'uppercase'
                                },
                            }
                        } },
                        React.createElement(AccessTimeIcon, { sx: { position: 'absolute', color: '#707070', top: 35, fontSize: '21px', right: 8 } }),
                        React.createElement(MUITimePicker, { isMandatory: true, labelText: 'From Time', timeChangeHandler: function (value) { return handleTimeChange(value, 'FromTime'); }, selectedTime: notificationInfo.FromTime, errorMessage: errors.FromTime, clearTime: function () { return setNotificationInfo(function (prevState) { return (__assign(__assign({}, prevState), { FromTime: '' })); }); } }))),
                React.createElement(Grid, { container: true, item: true, xs: 12, md: 6, sx: { paddingLeft: { xs: '0px', md: '16px' } } },
                    React.createElement(Grid, { item: true, xs: 6, sx: {
                            paddingRight: '12px', '& .MuiInputBase-root': { borderRadius: '8px' }, '& .clear-date': { right: '0 !important' },
                            '& .MuiInputAdornment-root': {
                                '& .MuiButtonBase-root': { marginLeft: '-8px' }
                            }
                        } },
                        React.createElement(MUIDatePicker, { labelText: "To Date", isMandatory: true, selectedDate: notificationInfo.ToDate, dateChangeHandler: function (value) { return handleDateChange(value, 'ToDate'); }, maxDate: notificationInfo.FromDate, errorMessage: errors.ToDate, clearDate: function () { return setNotificationInfo(function (prevState) { return (__assign(__assign({}, prevState), { ToDate: '' })); }); } })),
                    React.createElement(Grid, { item: true, xs: 6, sx: {
                            position: 'relative',
                            '& .MuiButtonBase-root ': {
                                right: '30px',
                            },
                            '& .MuiInputBase-root': {
                                borderRadius: '8px',
                                '& input': {
                                    textTransform: 'uppercase'
                                },
                            }
                        } },
                        React.createElement(AccessTimeIcon, { sx: { position: 'absolute', color: '#707070', right: 8, top: 35, fontSize: '21px', } }),
                        React.createElement(MUITimePicker, { isMandatory: true, labelText: 'To Time', timeChangeHandler: function (value) { return handleTimeChange(value, 'ToTime'); }, selectedTime: notificationInfo.ToTime, errorMessage: errors.ToTime, clearTime: function () { return setNotificationInfo(function (prevState) { return (__assign(__assign({}, prevState), { ToTime: '' })); }); } })))),
            React.createElement(Grid, { className: "btn-block" },
                React.createElement(Button, { variant: "outlined", className: 'save-btn', onClick: function () { return handleSubmitNotificationForm(); } }, isEdit ? 'Update' : 'Save'))),
        React.createElement(ConfirmationAndInformationPoup, { popupType: POPUP_TYPE.SUCCESS_ALERT, titleText: 'Success!', message: "".concat(selectedTab == 1 ? 'General Notification' : "Portal Notification", " ").concat(isEdit ? 'Updated' : 'Created', " Successfully."), acceptBtnText: 'Ok', acceptCallBack: useCallback(function () {
                setOpenSuccessPopup(false);
                getNotificationsData();
                setIsEdit(false);
            }, [openConfirmationPopup]), open: openSuccessPopup, key: "".concat(openSuccessPopup) }),
        React.createElement(Box, { sx: { display: '' }, className: "module-common-table-wrapper marketing-table", mt: 2 },
            React.createElement(ModuleTable, { dataToDisplay: contentListData, columns: selectedTab == 1 ? GenericMarketingTable : PortalMarketingTable, isLoading: false, actionHandler: tableActionHandler, isRowClickEnabled: true, moduleName: "Marketing Content", isActionColumn: true, hasDelete: true })),
        React.createElement(ConfirmationAndInformationPoup, { popupType: POPUP_TYPE.DELETE_CONFIRMATION_WARNING, titleText: 'Are you sure?', message: 'Are you sure you want to delete this Notification data?', acceptBtnText: 'Yes', denyBtnText: 'No', acceptCallBack: useCallback(function () {
                console.log('acceptCallBack');
                handleDeleteNotifications();
                setOpen(false);
            }, [open]), denyCallBack: useCallback(function () {
                setNotificationId('');
                setOpen(false);
            }, []), open: open, key: "".concat(open), cancelCallBack: useCallback(function () {
                setNotificationId('');
                setOpen(false);
            }, []) })));
};
export default NotificationsTab;
