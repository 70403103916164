import React from 'react';
var ResubmitApplicationIcon = function (_a) {
    var isDarkMode = _a.isDarkMode, isFromSideMenu = _a.isFromSideMenu;
    if (isFromSideMenu) {
        return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "18", height: "16", viewBox: "0 0 18 16", fill: "none" },
            React.createElement("path", { d: "M10.35 0.5C6.20836 0.5 2.85002 3.85833 2.85002 8C2.85002 8.05 2.85836 8.1 2.85836 8.15833L1.32502 6.625L0.150024 7.8L3.68336 11.3333L7.21669 7.8L6.04169 6.625L4.52502 8.14167C4.52502 8.09167 4.51669 8.05 4.51669 8C4.51669 4.78333 7.13336 2.16667 10.35 2.16667C13.5667 2.16667 16.1834 4.78333 16.1834 8C16.1834 11.2167 13.5667 13.8333 10.35 13.8333C8.76669 13.8333 7.33336 13.2 6.28336 12.175L5.10002 13.35C6.45002 14.675 8.30836 15.5 10.35 15.5C14.4917 15.5 17.85 12.1417 17.85 8C17.85 3.85833 14.4917 0.5 10.35 0.5Z", fill: isDarkMode ? "#FFFFFF" : "#868CA2" })));
    }
    else {
        return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "18", height: "16", viewBox: "0 0 18 16", fill: "none" },
            React.createElement("path", { d: "M10.35 0.5C6.20836 0.5 2.85002 3.85833 2.85002 8C2.85002 8.05 2.85836 8.1 2.85836 8.15833L1.32502 6.625L0.150024 7.8L3.68336 11.3333L7.21669 7.8L6.04169 6.625L4.52502 8.14167C4.52502 8.09167 4.51669 8.05 4.51669 8C4.51669 4.78333 7.13336 2.16667 10.35 2.16667C13.5667 2.16667 16.1834 4.78333 16.1834 8C16.1834 11.2167 13.5667 13.8333 10.35 13.8333C8.76669 13.8333 7.33336 13.2 6.28336 12.175L5.10002 13.35C6.45002 14.675 8.30836 15.5 10.35 15.5C14.4917 15.5 17.85 12.1417 17.85 8C17.85 3.85833 14.4917 0.5 10.35 0.5Z", fill: '#000000' })));
    }
};
export default ResubmitApplicationIcon;
