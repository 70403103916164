import React, { useCallback, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './../../assets/scss/adminview.scss';
import ConfirmationAndInformationPoup, { POPUP_TYPE } from '../../components/UtilityComponents/ConfirmationAndInformationPoup';
import { api_service } from '../../api/api_service';
import { api_urls } from '../../api/api_urls';
import { PORTAL_STATUS } from '../../components/ModuleHeader/ModuleHeader';
var UserModuleDrawer = function (_a) {
    var open = _a.open, handleClose = _a.handleClose, rowData = _a.rowData, getDealerListBasedOnVendor = _a.getDealerListBasedOnVendor;
    var _b = useState(false), openConfirmationPopup = _b[0], setOpenConfirmationPopup = _b[1];
    var _c = useState(false), openSuccessPopup = _c[0], setOpenSuccessPopup = _c[1];
    var _d = useState(), userProgramData = _d[0], setUserProgramData = _d[1];
    var handleClick = function () {
        if ((rowData === null || rowData === void 0 ? void 0 : rowData.userStatus) === PORTAL_STATUS[1].code || (rowData === null || rowData === void 0 ? void 0 : rowData.userStatus) === PORTAL_STATUS[2].code) {
            setOpenConfirmationPopup(true);
        }
        else {
            handleClose();
        }
    };
    var handleUserUpdate = function () {
        if ((rowData.userStatus === PORTAL_STATUS[1].code || rowData.userStatus === PORTAL_STATUS[2].code)) {
            var payLoad = {
                Id: Number(rowData.Id),
                VendorId: Number(rowData.VendorId),
                RoleType: rowData.RoleType,
                RoleApproval: rowData.userStatus === PORTAL_STATUS[1].code ? 1 : 2
            };
            api_service.post({
                endPoint: api_urls.UPDATE_USER,
                payLoad: payLoad,
                showLoader: true
            }).then(function (res) {
                if (res && res.data.statusCode === 200) {
                    setOpenSuccessPopup(true);
                }
            }).catch(function (err) {
                console.log('handleUserUpdate ~ err:', err);
            });
        }
        setOpenSuccessPopup(true);
    };
    var handleDrawerClose = function () {
        handleClose();
    };
    var getButtonText = function () {
        if ((rowData === null || rowData === void 0 ? void 0 : rowData.userStatus) === 0) {
            return 'Approve User';
        }
        else if ((rowData === null || rowData === void 0 ? void 0 : rowData.userStatus) === 1) {
            return 'Disapprove User';
        }
        else {
            return 'Close';
        }
    };
    var getUserProgramDetails = function () {
        if (rowData === null || rowData === void 0 ? void 0 : rowData.Id) {
            var payLoad = {
                AccountId: Number(rowData.Id),
            };
            api_service.get({
                endPoint: api_urls.PROGRAMS_LIST,
                payLoad: payLoad,
                showLoader: true
            }).then(function (res) {
                if (res && res.data.statusCode === 200) {
                    var response = res.data.data;
                    var names = response.map(function (obj) { return obj.Name; }).join(', ');
                    setUserProgramData(names);
                }
                else {
                    setUserProgramData('');
                }
            }).catch(function (err) {
                console.log('getUserProgramDetails ~ err:', err);
            });
        }
    };
    useEffect(function () {
        getUserProgramDetails();
    }, [rowData === null || rowData === void 0 ? void 0 : rowData.Id]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ConfirmationAndInformationPoup, { popupType: POPUP_TYPE.UNSAVED_DATA_WARNING, titleText: 'Are you sure?', message: "Are you sure you want to ".concat(getButtonText(), "?"), acceptBtnText: 'Yes', denyBtnText: 'No', acceptCallBack: useCallback(function () {
                handleUserUpdate();
                setOpenConfirmationPopup(false);
            }, [openConfirmationPopup]), denyCallBack: useCallback(function () {
                setOpenConfirmationPopup(false);
            }, []), open: openConfirmationPopup, key: "".concat(openConfirmationPopup), cancelCallBack: useCallback(function () {
                setOpenConfirmationPopup(false);
            }, []) }),
        React.createElement(ConfirmationAndInformationPoup, { popupType: POPUP_TYPE.SUCCESS_ALERT, titleText: 'Success!', message: "User status has been updated successfully.", acceptBtnText: 'Ok', acceptCallBack: useCallback(function () {
                setOpenSuccessPopup(false);
                getDealerListBasedOnVendor();
                handleClose();
            }, [openConfirmationPopup]), open: openSuccessPopup, key: "".concat(openSuccessPopup) }),
        React.createElement(Dialog, { open: open, className: 'MuiDialogue-upload location-popup' },
            React.createElement(DialogTitle, { sx: { m: 0, p: 2 }, id: "customized-dialog-title" }, "View User Details"),
            React.createElement(IconButton, { "aria-label": "close", onClick: function () { return handleDrawerClose(); }, className: 'popup-close', sx: {
                    position: 'absolute',
                    right: 8,
                    top: 8,
                } },
                React.createElement(CloseIcon, null)),
            React.createElement(DialogContent, null,
                React.createElement(Grid, { container: true, rowSpacing: 3, columnSpacing: { xs: 1, sm: 2, md: 0 } },
                    React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                        React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                            React.createElement(Typography, { className: 'view-page-labels' }, "First Name"),
                            React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.FirstName))),
                    React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                        React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                            React.createElement(Typography, { className: 'view-page-labels' }, "Last Name"),
                            React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.LastName))),
                    React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                        React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                            React.createElement(Typography, { className: 'view-page-labels' }, "Email"),
                            React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.Email))),
                    (rowData === null || rowData === void 0 ? void 0 : rowData.ActivationCode) &&
                        React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                            React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                                React.createElement(Typography, { className: 'view-page-labels' }, "Dealership Code"),
                                React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.ActivationCode))),
                    React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                        React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                            React.createElement(Typography, { className: 'view-page-labels' }, "Dealership Oid"),
                            React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.DealerOId))),
                    React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                        React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                            React.createElement(Typography, { className: 'view-page-labels' }, "Requested Role"),
                            React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.RoleType))),
                    React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                        React.createElement(Grid, { style: { padding: '5px' } },
                            React.createElement(Typography, { className: 'view-page-labels' }, "Program"),
                            React.createElement(Typography, { className: 'view-page-values' }, userProgramData ? userProgramData : ''))))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { variant: 'contained', onClick: function () { return handleClick(); }, size: 'small', className: 'btn-txt', style: { maxWidth: '125px' } }, getButtonText())))));
};
export default UserModuleDrawer;
