import * as React from 'react';
import { AppBar, Toolbar, Box, Typography, Button, Stack, Avatar, IconButton, Popover, Divider } from '@mui/material';
import './../../assets/scss/appBar.scss';
import { useDispatch, useSelector } from 'react-redux';
import { NavigationConfig } from '../../utils/navigation_config';
import { useNavigate } from 'react-router-dom';
import AuxCapDashboardLogo from './../../assets/images/AuxCapDashboardLogo-white.svg';
import UserAvatarImage from './../../assets/images/userAvatar.svg';
import { loginSignup_asyncActions } from '../../asyncActions/loginSignup_asynActions';
import ConfirmationAndInformationPoup, { POPUP_TYPE } from './ConfirmationAndInformationPoup';
import { useCallback } from 'react';
import { ArrowDropDown, AdminAvatarIcon, AdminMailIcon, AdminPhoneIcon, AdminLogoutIcon } from '../icons';
import { formatPhoneNumber } from '../../utils/commonUtilities';
export default function MuiAppBar() {
    var _a, _b, _c, _d, _e;
    var userBasicInfo = useSelector(function (store) { return store.User; });
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var _f = React.useState(false), open = _f[0], setOpen = _f[1];
    var _g = React.useState(null), anchorEl = _g[0], setAnchorEl = _g[1];
    var openPopOver = Boolean(anchorEl);
    var id = openPopOver ? 'simple-popover' : undefined;
    var logout = function () {
        dispatch(loginSignup_asyncActions.logout())
            .then(function (response) {
            var _a;
            if (((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.statusCode) === 200) {
                navigate(NavigationConfig.Account.Login);
                location.reload();
            }
        });
    };
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    return (React.createElement(Box, { sx: { position: 'fixed', right: 0, left: 0, zIndex: 9 } },
        React.createElement(AppBar, { position: "static" },
            React.createElement(Toolbar, null,
                React.createElement(Stack, { className: 'header' },
                    React.createElement(Box, { className: "header-logo" },
                        React.createElement("img", { src: AuxCapDashboardLogo, alt: "Brand Logo", width: '90%' })),
                    React.createElement(Box, { className: "logout-btn" },
                        React.createElement(Stack, { direction: 'row' },
                            React.createElement(Stack, { pr: '16px' },
                                React.createElement(Typography, { fontSize: '14px', lineHeight: '20px', fontWeight: 600, textAlign: 'right' }, (_a = userBasicInfo.userData.firstName) !== null && _a !== void 0 ? _a : ''),
                                React.createElement(Typography, { fontSize: '12px', lineHeight: '20px', fontWeight: 400, textAlign: 'right' }, userBasicInfo.userData.RoleType === 'ADMIN' ? "Admin" : userBasicInfo.userData.RoleType)),
                            React.createElement(Button, { "aria-describedby": id },
                                React.createElement(Avatar, { alt: "Remy Sharp", src: UserAvatarImage }),
                                React.createElement(IconButton, { "aria-label": "arrow", onClick: handleClick },
                                    React.createElement(ArrowDropDown, null))),
                            React.createElement(Popover, { className: "user-profile-popover", id: id, open: openPopOver, onClose: handleClose, anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }, transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                } },
                                React.createElement(Stack, { direction: 'column' },
                                    React.createElement(Stack, { direction: 'row', alignItems: 'center', justifyContent: 'start' },
                                        React.createElement(IconButton, { "aria-label": "arrow" },
                                            React.createElement(AdminAvatarIcon, null)),
                                        React.createElement(Typography, { color: '#FFFFFF', fontSize: '16px' }, "".concat((_b = userBasicInfo.userData.firstName) !== null && _b !== void 0 ? _b : '', " ").concat((_c = userBasicInfo.userData.lastName) !== null && _c !== void 0 ? _c : ''))),
                                    React.createElement(Divider, { sx: { borderColor: '#868CA2' } }),
                                    React.createElement(Stack, { direction: 'row', alignItems: 'center', justifyContent: 'start' },
                                        React.createElement(IconButton, { "aria-label": "arrow" },
                                            React.createElement(AdminMailIcon, null)),
                                        React.createElement(Typography, { color: '#FFFFFF', fontSize: '16px' }, (_d = userBasicInfo.userData.email) !== null && _d !== void 0 ? _d : '--')),
                                    React.createElement(Divider, { sx: { borderColor: '#868CA2' } }),
                                    React.createElement(Stack, { direction: 'row', alignItems: 'center', justifyContent: 'start' },
                                        React.createElement(IconButton, { "aria-label": "arrow" },
                                            React.createElement(AdminPhoneIcon, null)),
                                        React.createElement(Typography, { color: '#FFFFFF', fontSize: '16px' }, ((_e = userBasicInfo === null || userBasicInfo === void 0 ? void 0 : userBasicInfo.userData) === null || _e === void 0 ? void 0 : _e.PhoneNumber) ? "+1 ".concat(formatPhoneNumber(userBasicInfo.userData.PhoneNumber)) : '--')),
                                    React.createElement(Divider, { sx: { borderColor: '#868CA2' } }),
                                    React.createElement(Stack, { direction: 'row', alignItems: 'center', justifyContent: 'start', sx: { cursor: 'pointer' }, onClick: function () { return setOpen(true); } },
                                        React.createElement(IconButton, { "aria-label": "arrow" },
                                            React.createElement(AdminLogoutIcon, null)),
                                        React.createElement(Typography, { color: '#FFFFFF', fontSize: '16px' }, "Logout"))))),
                        React.createElement(ConfirmationAndInformationPoup, { popupType: POPUP_TYPE.LOGOUT_WARNING, titleText: 'Are you sure?', message: 'Are you sure you want to Logout?', acceptBtnText: 'Yes', denyBtnText: 'No', acceptCallBack: useCallback(function () {
                                logout();
                                setOpen(false);
                            }, [open]), denyCallBack: useCallback(function () {
                                setOpen(false);
                            }, []), open: open, key: "".concat(open), cancelCallBack: useCallback(function () {
                                setOpen(false);
                            }, []) })))))));
}
