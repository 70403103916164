var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Box, Button, Stack, SwipeableDrawer, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React, { useCallback, useEffect, useState } from "react";
import ModuleHeader from "../../components/ModuleHeader/ModuleHeader";
import ModuleTable from "../../components/UtilityComponents/moduleTable";
import { api_service } from "../../api/api_service";
import { api_urls } from "../../api/api_urls";
import moment from "moment";
import { ResubmitApplicationCols } from "../../config/TableColumns";
import EditApplicationDrawer from "./EditApplicationDrawer";
import ConfirmationAndInformationPoup, { POPUP_TYPE } from "../../components/UtilityComponents/ConfirmationAndInformationPoup";
import { utility } from "../../utils";
import SvelteJSONEditor from "../dealerManagement/JsonEditor";
import { deepEqual } from "../../utils/commonUtilities";
var ResubmitApplication = function () {
    var _a = useState('all'), selectedPortal = _a[0], setSelectedPortal = _a[1];
    var _b = useState(), searchText = _b[0], setSearchText = _b[1];
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var _d = useState(''), userAction = _d[0], setUserAction = _d[1];
    var _e = useState(undefined), vendorList = _e[0], setVendorList = _e[1];
    var _f = useState([]), adminList = _f[0], setAdminList = _f[1];
    var _g = useState(false), isFetching = _g[0], setIsFetching = _g[1];
    var _h = useState([]), freezedList = _h[0], setFreezedList = _h[1];
    var _j = useState(), rowData = _j[0], setRowData = _j[1];
    var _k = useState({ open: false, applicationId: null }), openDeleteConfirmation = _k[0], setOpenDeleteConfirmation = _k[1];
    var _l = useState({ ApplicationId: '' }), selectedApplicationForResubmit = _l[0], setSelectedApplicationForResubmit = _l[1];
    var _m = useState({ json: {} }), applicationJsonPayload = _m[0], setApplicationJsonPayload = _m[1];
    var _o = useState({ right: null }), drawerState = _o[0], setDrawerState = _o[1];
    var _p = useState(false), openSuccessPopup = _p[0], setOpenSuccessPopup = _p[1];
    var _q = useState(false), openUnsavedChangesConfirmation = _q[0], setOpenUnsavedChangesConfirmation = _q[1];
    var _r = useState({ json: {} }), freezedApplicationJsonPayload = _r[0], setFreezedApplicationJsonPayload = _r[1];
    var getFailedApplications = function () {
        setIsFetching(true);
        setAdminList([]);
        api_service.get({
            endPoint: api_urls.GET_FAILED_APPLICATIONS,
            payLoad: {
                VendorId: selectedPortal == 'all' ? null : selectedPortal
            }
        }).then(function (res) {
            setIsFetching(false);
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                var __failedApplications = res.data.data;
                __failedApplications === null || __failedApplications === void 0 ? void 0 : __failedApplications.map(function (eachApplication, index) {
                    eachApplication.CreatedTime = moment(eachApplication.CreatedTime).tz('America/New_York');
                });
                setAdminList(__failedApplications);
                setFreezedList(__failedApplications);
            }
        }).catch(function (err) { setIsFetching(false); });
    };
    var getFailedApplicationSyncData = function (applicationId) {
        setIsFetching(true);
        api_service.get({
            endPoint: "".concat(api_urls.GET_FAILED_APPLICATION_SYNC_DATA, "/").concat(applicationId),
        }).then(function (res) {
            var _a, _b, _c, _d;
            setIsFetching(false);
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                var __syncPayload = JSON.parse((_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.SyncPayload);
                setApplicationJsonPayload({ json: __syncPayload });
                setFreezedApplicationJsonPayload({ json: __syncPayload });
            }
            else {
                utility.alert((_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.message);
            }
        }).catch(function (err) { setIsFetching(false); });
    };
    var tableActionHandler = function (rowData, actionType) {
        console.log('rowData:', rowData);
        console.log('actionType:', actionType);
        if (actionType === 'Resubmit') {
            setSelectedApplicationForResubmit(rowData);
            getFailedApplicationSyncData(rowData.ApplicationId);
        }
        else if (actionType === 'Delete') {
            setOpenDeleteConfirmation({ open: true, applicationId: rowData === null || rowData === void 0 ? void 0 : rowData.ApplicationId });
        }
    };
    var handleViewDetails = function (__rowData) {
        setRowData(__rowData);
        setUserAction('View');
        setOpen(true);
    };
    var handleClose = function () {
        setRowData(null);
        setOpen(false);
    };
    var handleDeleteFailedApplication = function (applicationId) {
        api_service.delete({
            endPoint: "".concat(api_urls.DELETE_FAILED_APPLICATION, "/").concat(applicationId),
        }).then(function (res) {
            var _a;
            if (res && res.data.statusCode === 200) {
                getFailedApplications();
            }
            else {
                utility.alert((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.message);
            }
        }).catch(function (err) {
            console.log("error in delete failed application:", err);
        });
    };
    var handleResubmitApplication = function () {
        var __applicationJsonPayload = null;
        if (applicationJsonPayload === null || applicationJsonPayload === void 0 ? void 0 : applicationJsonPayload.text) {
            __applicationJsonPayload = JSON.parse(applicationJsonPayload === null || applicationJsonPayload === void 0 ? void 0 : applicationJsonPayload.text);
        }
        else if (applicationJsonPayload === null || applicationJsonPayload === void 0 ? void 0 : applicationJsonPayload.json) {
            __applicationJsonPayload = applicationJsonPayload === null || applicationJsonPayload === void 0 ? void 0 : applicationJsonPayload.json;
        }
        var __payload = {
            ApplicationId: selectedApplicationForResubmit === null || selectedApplicationForResubmit === void 0 ? void 0 : selectedApplicationForResubmit.ApplicationId,
            Source: selectedApplicationForResubmit === null || selectedApplicationForResubmit === void 0 ? void 0 : selectedApplicationForResubmit.Source,
            Payload: __applicationJsonPayload
        };
        api_service.post({
            endPoint: api_urls.RESUBMIT_FAILED_APPLICATION,
            payLoad: __payload,
            showLoader: true
        }).then(function (res) {
            var _a;
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                setApplicationJsonPayload({ json: {} });
                toggleDrawer('right', false, "close");
                setSelectedApplicationForResubmit({ ApplicationId: '' });
                setOpenSuccessPopup(true);
            }
            else {
                utility.alert((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.message);
            }
        }).catch(function (err) {
            console.log('handleSaveDealerConfiguration ~ err:', err);
        });
    };
    var toggleDrawer = function (anchor, open, appName) { return function () {
        var _a;
        setDrawerState(__assign(__assign({}, drawerState), (_a = {}, _a[anchor] = open ? appName : null, _a)));
    }; };
    var handleCancelChanges = function () {
        if (deepEqual((applicationJsonPayload === null || applicationJsonPayload === void 0 ? void 0 : applicationJsonPayload.text) ? JSON.parse(applicationJsonPayload.text) : applicationJsonPayload.json, freezedApplicationJsonPayload === null || freezedApplicationJsonPayload === void 0 ? void 0 : freezedApplicationJsonPayload.json)) {
            setDrawerState({ 'right': null });
        }
        else {
            setOpenUnsavedChangesConfirmation(true);
        }
    };
    var list = function (anchor, appName) { return (React.createElement(Box, { role: "presentation", className: "cstDrawer", p: 2.5 },
        React.createElement(Stack, { direction: 'row', alignItems: 'center', justifyContent: 'space-between', mb: 2 },
            React.createElement(Typography, { variant: 'h5', fontWeight: 700 }, appName),
            React.createElement(CloseIcon, { onClick: handleCancelChanges })),
        React.createElement(Stack, { alignItems: 'center', justifyContent: 'flex-start', sx: {
                height: 'calc(100dvh - 158px)',
                overflow: 'auto',
                borderTop: '1px solid #80808036',
                borderBottom: '1px solid #80808036',
                marginBottom: '16px',
                padding: '8px 0px',
            } },
            React.createElement(SvelteJSONEditor, { onChange: function (content) {
                    setApplicationJsonPayload(content);
                }, content: applicationJsonPayload })),
        React.createElement(Stack, { direction: 'row', alignItems: 'center', justifyContent: 'flex-end', spacing: 2 },
            React.createElement(Button, { onClick: handleCancelChanges, variant: "outlined", sx: {
                    color: '#000',
                    borderRadius: '30px', borderColor: '#000',
                } }, "Cancel"),
            React.createElement(Box, { sx: {
                    '& .MuiButtonBase-root': {
                        backgroundColor: '#000',
                        padding: '6px 8px',
                        borderRadius: '30px'
                    }
                } },
                React.createElement(Button, { type: "submit", variant: "contained", color: "primary", fullWidth: true, className: "commonBtn ", onClick: handleResubmitApplication, disabled: JSON.stringify(applicationJsonPayload) && Object.keys(applicationJsonPayload).length ? false : true }, "Submit Application"))))); };
    useEffect(function () {
        var _a;
        if ((applicationJsonPayload === null || applicationJsonPayload === void 0 ? void 0 : applicationJsonPayload.json) && ((_a = Object.keys(applicationJsonPayload === null || applicationJsonPayload === void 0 ? void 0 : applicationJsonPayload.json)) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            setDrawerState({ right: selectedApplicationForResubmit.ApplicationId });
        }
    }, [applicationJsonPayload === null || applicationJsonPayload === void 0 ? void 0 : applicationJsonPayload.json]);
    useEffect(function () {
        var searchTerm = searchText === null || searchText === void 0 ? void 0 : searchText.toLowerCase();
        if (searchTerm === '') {
            setAdminList(freezedList);
        }
        else {
            var filteredResults = freezedList.filter(function (item) { var _a; return (_a = item === null || item === void 0 ? void 0 : item.CustomerName) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(searchTerm); });
            setAdminList(filteredResults);
        }
    }, [searchText]);
    useEffect(function () {
        if (selectedPortal) {
            getFailedApplications();
        }
    }, [selectedPortal]);
    return (React.createElement(Box, { className: 'common-module-wrapper user-management-wrapper' },
        React.createElement(ModuleHeader, { moduleName: 'Resubmit Application', placeHolderText: 'Search by Customer Name', hasPortalDD: true, hasStatusDD: false, hasSearch: true, hasAddItem: false, selectedPortal: selectedPortal, getSelectedPortal: setSelectedPortal, getSearchedText: setSearchText, isDrawerOpen: function () { }, getVendorList: setVendorList, resetDealerFilters: freezedList === null || freezedList === void 0 ? void 0 : freezedList.length, feildWidth: 3.5 }),
        React.createElement(Box, { className: "module-common-table-wrapper" },
            React.createElement(ModuleTable, { dataToDisplay: adminList, columns: ResubmitApplicationCols, isLoading: isFetching, actionHandler: tableActionHandler, onClickHandler: handleViewDetails, isRowClickEnabled: true, moduleName: "Resubmit Application", isActionColumn: true, isPortalSelected: selectedPortal })),
        React.createElement(Box, null,
            React.createElement(EditApplicationDrawer, { open: open, handleClose: handleClose, rowData: rowData, getAdminList: getFailedApplications })),
        React.createElement(SwipeableDrawer, { anchor: "right", open: drawerState['right'] === selectedApplicationForResubmit.ApplicationId, onClose: toggleDrawer('right', false, "close"), onOpen: toggleDrawer('right', true, "open"), sx: {
                '& .MuiPaper-root': {
                    background: '#fff',
                    minWidth: '800px',
                    maxWidth: '800px',
                }
            } }, list('right', selectedApplicationForResubmit.CustomerName)),
        React.createElement(ConfirmationAndInformationPoup, { popupType: POPUP_TYPE.DELETE_CONFIRMATION_WARNING, titleText: 'Are you sure?', open: openDeleteConfirmation.open, key: "".concat(openDeleteConfirmation.open), message: 'Are you sure you want to Delete the Application?', acceptBtnText: 'Yes', denyBtnText: 'No', acceptCallBack: function () {
                handleDeleteFailedApplication(openDeleteConfirmation.applicationId);
                setOpenDeleteConfirmation({ open: false, applicationId: null });
            }, denyCallBack: function () {
                setOpenDeleteConfirmation({ open: false, applicationId: null });
            }, cancelCallBack: function () {
                setOpenDeleteConfirmation({ open: false, applicationId: null });
            } }),
        React.createElement(ConfirmationAndInformationPoup, { popupType: POPUP_TYPE.UNSAVED_DATA_WARNING, titleText: 'Are you sure?', message: "Are you sure you want to Cancel the changes?", acceptBtnText: 'Yes', denyBtnText: 'No', acceptCallBack: useCallback(function () {
                setOpenUnsavedChangesConfirmation(false);
                setDrawerState({ right: null });
            }, [openUnsavedChangesConfirmation]), denyCallBack: useCallback(function () {
                setOpenUnsavedChangesConfirmation(false);
            }, []), open: openUnsavedChangesConfirmation, key: "".concat(openUnsavedChangesConfirmation), cancelCallBack: useCallback(function () {
                setOpenUnsavedChangesConfirmation(false);
            }, []) }),
        React.createElement(ConfirmationAndInformationPoup, { popupType: POPUP_TYPE.SUCCESS_ALERT, titleText: 'Success!', message: "Application has been submitted successfully.", acceptBtnText: 'Ok', acceptCallBack: useCallback(function () {
                setOpenSuccessPopup(false);
                getFailedApplications();
            }, [openSuccessPopup]), open: openSuccessPopup, key: "".concat(openSuccessPopup) })));
};
export default ResubmitApplication;
