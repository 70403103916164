import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
var OtpTimer = function (_a) {
    var waitingTimeSec = _a.waitingTimeSec, waitingTimeMin = _a.waitingTimeMin, isOTPExpiered = _a.isOTPExpiered;
    var _b = useState(waitingTimeMin), counterMin = _b[0], setCounterMin = _b[1];
    var _c = useState(waitingTimeSec), counterSec = _c[0], setCounterSec = _c[1];
    useEffect(function () {
        var timerInterval = setTimeout(function () {
            if (counterSec > 0) {
                setCounterSec(function (counterSec) { return counterSec - 1; });
            }
            else if (counterMin !== undefined && counterMin > 0) {
                setCounterSec(59);
                setCounterMin(function (counterMin) { return (counterMin !== undefined ? counterMin - 1 : 0); });
            }
            else if (counterSec === 0 && counterMin === 0) {
                isOTPExpiered && isOTPExpiered(true);
                clearInterval(timerInterval);
            }
        }, 1000);
        return function () {
            clearInterval(timerInterval);
        };
    }, [counterSec, counterMin]);
    return (React.createElement(Typography, null,
        "OTP expires in: ",
        counterMin > 9 ? counterMin : "0".concat(counterMin),
        ":",
        counterSec > 9 ? counterSec : "0".concat(counterSec)));
};
export default OtpTimer;
