// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiBackdrop-root .MuiCircularProgress-determinate {
  border: 5px solid #fff;
  border-radius: 50%;
  position: relative;
}
.MuiBackdrop-root .MuiCircularProgress-indeterminate {
  position: absolute;
}

.fab-icon.MuiFab-root {
  position: fixed;
  right: 30px;
  bottom: 100px;
  background: #fff;
  border: 1px solid #000;
  box-shadow: none;
  filter: drop-shadow(0px 5.5999999046px 5.5999999046px rgba(0, 0, 0, 0.25));
}
.fab-icon.MuiFab-root:hover {
  background-color: #fff;
}

.loader-text.MuiTypography-body1 {
  position: absolute;
  top: 55%;
  z-index: 1201;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/loader.scss"],"names":[],"mappings":"AACE;EACE,sBAAA;EACA,kBAAA;EACA,kBAAA;AAAJ;AAEE;EACE,kBAAA;AAAJ;;AAKE;EACE,eAAA;EACA,WAAA;EACA,aAAA;EACA,gBAAA;EACA,sBAAA;EACA,gBAAA;EACA,0EAAA;AAFJ;AAGI;EACE,sBAAA;AADN;;AAOE;EACE,kBAAA;EACA,QAAA;EAEA,aAAA;EACA,WAAA;AALJ","sourcesContent":[".MuiBackdrop-root {\n  .MuiCircularProgress-determinate {\n    border: 5px solid #fff;\n    border-radius: 50%;\n    position: relative;\n  }\n  .MuiCircularProgress-indeterminate {\n    position: absolute;\n  }\n}\n\n.fab-icon {\n  &.MuiFab-root {\n    position: fixed;\n    right: 30px;\n    bottom: 100px;\n    background: #fff;\n    border: 1px solid #000;\n    box-shadow: none;\n    filter: drop-shadow(0px 5.599999904632568px 5.599999904632568px rgba(0, 0, 0, 0.25));\n    &:hover {\n      background-color: #fff;\n    }\n  }\n}\n\n.loader-text {\n  &.MuiTypography-body1 {\n    position: absolute;\n    top: 55%;\n    // left: 37%;\n    z-index: 1201;\n    color: #fff;\n    }\n  \n }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
