var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import SelectDD from '../../components/FormComponents/SelectDD';
import TextField from '../../components/FormComponents/TextField';
import CloseIcon from '@mui/icons-material/Close';
import './../../assets/scss/adminview.scss';
var initialProgramInfo = {
    Name: '',
    CompanyId: '',
    Country: '',
    PrivateLabel: '',
};
var COUNTRY_LIST = [
    { country: 'USA', code: 'USA' },
    { country: 'Canada', code: 'CAN' },
];
var DealerMangementDrawer = function (_a) {
    var open = _a.open, handleClose = _a.handleClose, programData = _a.programData, setDealerData = _a.setDealerData, userAction = _a.userAction, selectedProgramIndex = _a.selectedProgramIndex;
    var _b = React.useState(__assign({}, initialProgramInfo)), programInfo = _b[0], setProgramInfo = _b[1];
    var _c = React.useState(__assign({}, initialProgramInfo)), errors = _c[0], setErrors = _c[1];
    var _d = React.useState(''), actionType = _d[0], setActionType = _d[1];
    var handleProgramInfoChange = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        setErrors(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[name] = '', _a)));
        });
        setProgramInfo(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleOnInputBlur = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        var updatedErrors = __assign({}, errors);
        updatedErrors[name] = validateField(name, value);
        setErrors(updatedErrors);
    };
    var validateField = function (name, value) {
        if (!value.trim()) {
            return 'This field is required.';
        }
        return '';
    };
    var isValidData = function () {
        var updatedErrors = __assign({}, errors);
        for (var key in programInfo) {
            var error = validateField(key, programInfo[key]);
            updatedErrors[key] = error;
        }
        setErrors(updatedErrors);
        var hasErrors = Object.values(updatedErrors).some(function (val) { return val !== ''; });
        return !hasErrors;
    };
    var handleClick = function () {
        if (actionType === 'View') {
            setActionType('Edit');
        }
        else {
            if (isValidData()) {
                var __programs_1 = __spreadArray([], programData, true);
                __programs_1[selectedProgramIndex] = __assign({}, programInfo);
                setDealerData(function (prevData) { return (__assign(__assign({}, prevData), { Programs: __programs_1 })); });
                handleClose();
            }
            else {
                return;
            }
        }
    };
    var handleDrawerClose = function () {
        setProgramInfo(programData[selectedProgramIndex]);
        setErrors(__assign({}, initialProgramInfo));
        setActionType(userAction);
        handleClose();
    };
    var getButtonText = function () {
        if (actionType === 'View') {
            return 'Edit';
        }
        else {
            return 'Submit';
        }
    };
    useEffect(function () {
        setActionType(userAction);
        setProgramInfo(programData[selectedProgramIndex]);
    }, [userAction, selectedProgramIndex]);
    return (React.createElement(Dialog, { open: open, className: 'MuiDialogue-upload location-popup' },
        React.createElement(DialogTitle, { sx: { m: 0, p: 2 }, id: "customized-dialog-title" }, actionType === 'View' ? 'View Program Input' : "".concat(actionType, " Program Input")),
        React.createElement(IconButton, { "aria-label": "close", onClick: function () { return handleDrawerClose(); }, className: 'popup-close', sx: {
                position: 'absolute',
                right: 8,
                top: 8,
            } },
            React.createElement(CloseIcon, null)),
        React.createElement(DialogContent, null, actionType === 'View' ?
            React.createElement(Grid, { container: true, rowSpacing: 3, columnSpacing: { xs: 1, sm: 2, md: 0 } },
                React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                    React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                        React.createElement(Typography, { className: 'view-page-labels' }, " Name"),
                        React.createElement(Typography, { className: 'view-page-values' }, programInfo === null || programInfo === void 0 ? void 0 : programInfo.Name))),
                React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                    React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                        React.createElement(Typography, { className: 'view-page-labels' }, "Company ID"),
                        React.createElement(Typography, { className: 'view-page-values' }, programInfo === null || programInfo === void 0 ? void 0 : programInfo.FinanceCompanyRecordId))),
                React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                    React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                        React.createElement(Typography, { className: 'view-page-labels' }, "Country"),
                        React.createElement(Typography, { className: 'view-page-values' }, (programInfo === null || programInfo === void 0 ? void 0 : programInfo.Country) === 'CAN' ? 'Canada' : 'USA'))),
                React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                    React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                        React.createElement(Typography, { className: 'view-page-labels' }, "Private Label"),
                        React.createElement(Typography, { className: 'view-page-values' }, programInfo === null || programInfo === void 0 ? void 0 : programInfo.PrivateLabel))))
            :
                React.createElement(Grid, { container: true, rowSpacing: 3, columnSpacing: { xs: 1, sm: 2, md: 3 } },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(TextField, { label: ' Name', name: 'Name', isMandatoryField: true, placeholder: 'Name', fullWidth: true, value: programInfo === null || programInfo === void 0 ? void 0 : programInfo.Name, onChange: handleProgramInfoChange, onBlur: handleOnInputBlur, error: Boolean(errors.Name), error_message: errors.Name, type: "text" })),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(TextField, { label: 'Company ID', name: 'FinanceCompanyRecordId', isMandatoryField: true, placeholder: 'Company ID', fullWidth: true, value: programInfo === null || programInfo === void 0 ? void 0 : programInfo.FinanceCompanyRecordId, onChange: handleProgramInfoChange, onBlur: handleOnInputBlur, error: Boolean(errors.FinanceCompanyRecordId), error_message: errors.FinanceCompanyRecordId, type: "text" })),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(SelectDD, { isMandatoryField: true, DropDownLabel: 'Country', placeHolder: 'Country', options: COUNTRY_LIST, labelKey: 'country', valueKey: 'code', handleChange: handleProgramInfoChange, selectedValue: programInfo.Country, name: "Country", errorMessage: errors.Country, handleBlur: handleOnInputBlur })),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(TextField, { label: 'Private Label', name: 'PrivateLabel', isMandatoryField: true, placeholder: 'Private Label', fullWidth: true, value: programInfo === null || programInfo === void 0 ? void 0 : programInfo.PrivateLabel, onChange: handleProgramInfoChange, onBlur: handleOnInputBlur, error: Boolean(errors.PrivateLabel), error_message: errors.PrivateLabel, type: "text" })))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { variant: 'contained', onClick: function () { return handleClick(); }, size: 'small', className: 'btn-txt' }, getButtonText()))));
};
export default DealerMangementDrawer;
