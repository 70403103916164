import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { main_routes } from "./routes/main_routes";
import './assets/scss/form_styles.scss';
import { ThemeProvider } from "@mui/material";
import defaultTheme from "./Themes/default_theme";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Loader from "./components/loader";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'moment-timezone';
var App = function (props) {
    return (React.createElement(ThemeProvider, { theme: defaultTheme },
        React.createElement(ToastContainer, null),
        React.createElement(Loader, null),
        React.createElement(LocalizationProvider, { dateAdapter: AdapterMoment },
            React.createElement(RouterProvider, { router: createBrowserRouter(main_routes) }))));
};
export default App;
