var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from '@mui/material';
import TextField from '../../components/FormComponents/TextField';
import CloseIcon from '@mui/icons-material/Close';
import AutoCompleteDD from '../../components/FormComponents/AutoCompleteDD';
import './../../assets/scss/adminview.scss';
import ConfirmationAndInformationPoup, { POPUP_TYPE } from '../../components/UtilityComponents/ConfirmationAndInformationPoup';
import { api_service } from '../../api/api_service';
import { api_urls } from '../../api/api_urls';
import AutoCompleteMultiSelectDD from '../../components/FormComponents/AutoCompleteMulitSelectDD';
import constants from '../../config/constants';
import { utility } from '../../utils';
var initialAdminInfo = {
    FirstName: '',
    LastName: '',
    Email: '',
    PhoneNumber: '',
    RoleType: '',
    Dealers: ''
};
var AdminModuleDrawer = function (_a) {
    var open = _a.open, handleClose = _a.handleClose, rowData = _a.rowData, userAction = _a.userAction, getAdminList = _a.getAdminList;
    var _b = React.useState(__assign({}, initialAdminInfo)), adminInfo = _b[0], setAdminInfo = _b[1];
    var _c = React.useState(__assign({}, initialAdminInfo)), errors = _c[0], setErrors = _c[1];
    var _d = React.useState(''), actionType = _d[0], setActionType = _d[1];
    var _e = useState(false), openConfirmationPopup = _e[0], setOpenConfirmationPopup = _e[1];
    var _f = useState(false), openSuccessPopup = _f[0], setOpenSuccessPopup = _f[1];
    var _g = useState([]), dealerList = _g[0], setDealerList = _g[1];
    var _h = useState([]), rolesList = _h[0], setRolesList = _h[1];
    var handleNewLocationInfoChange = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        if (name === 'PhoneNumber') {
            value = value === null || value === void 0 ? void 0 : value.replace(constants.regex.PhoneNumber_DownPayment, '');
        }
        if (name === 'RoleType') {
            setAdminInfo(function (prevState) { return (__assign(__assign({}, prevState), { RoleType: value, Dealers: '' })); });
            setErrors(function (prevState) {
                var _a;
                return (__assign(__assign({}, prevState), (_a = {}, _a[name] = '', _a)));
            });
        }
        else {
            setErrors(function (prevState) {
                var _a;
                return (__assign(__assign({}, prevState), (_a = {}, _a[name] = '', _a)));
            });
            setAdminInfo(function (prevState) {
                var _a;
                return (__assign(__assign({}, prevState), (_a = {}, _a[name] = value, _a)));
            });
        }
    };
    var handleOnInputBlur = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        var updatedErrors = __assign({}, errors);
        updatedErrors[name] = validateField(name, name === 'Dealers' ? adminInfo.Dealers : value);
        setErrors(updatedErrors);
    };
    var validateField = function (name, value) {
        var _a;
        if (name === 'Dealers' && adminInfo.RoleType !== 'ADMIN') {
            if (!((_a = value[0]) === null || _a === void 0 ? void 0 : _a.Id)) {
                return 'This field is required.';
            }
        }
        if (typeof (value) === 'string' && !value.trim() && name !== 'Dealers') {
            return 'This field is required.';
        }
        if ((name === 'Email') && !constants.regex.emailRegExp.test(value)) {
            return 'Please enter valid email address';
        }
        if ((name === 'PhoneNumber') && value && value.length < 10) {
            return 'Please provide a valid phone number';
        }
        return '';
    };
    var isValidData = function () {
        var updatedErrors = __assign({}, errors);
        console.log('isValidData ~ adminInfo:', adminInfo);
        for (var key in adminInfo) {
            var error = validateField(key, adminInfo[key]);
            updatedErrors[key] = error;
        }
        setErrors(updatedErrors);
        var hasErrors = Object.values(updatedErrors).some(function (val) { return val !== ''; });
        return !hasErrors;
    };
    var handleClick = function () {
        if (actionType === 'View') {
            setActionType('Edit');
        }
        else if (actionType === 'Edit' || actionType === 'Create') {
            if (isValidData()) {
                setOpenConfirmationPopup(true);
            }
        }
        else {
            return;
        }
    };
    var handleDrawerClose = function () {
        setActionType(userAction);
        setAdminInfo(__assign({}, initialAdminInfo));
        setErrors(__assign({}, initialAdminInfo));
        handleClose();
        setErrors(__assign({}, initialAdminInfo));
    };
    var handleUpdateAdmin = function () {
        var __dealerIds = getPayloadDealerIds();
        var __payload = {
            Id: Number(adminInfo.Id),
            FirstName: adminInfo.FirstName,
            LastName: adminInfo.LastName,
            Email: adminInfo.Email,
            PhoneNumber: adminInfo.PhoneNumber,
            RoleType: adminInfo.RoleType,
            DealerIds: __dealerIds
        };
        api_service.put({
            endPoint: api_urls.UPDATE_ADMIN,
            payLoad: __payload,
            showLoader: true
        }).then(function (res) {
            var _a;
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                setOpenSuccessPopup(true);
            }
            else {
                utility.alert((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.message);
            }
        }).catch(function (err) {
            console.log('getDealersList ~ err:', err);
        });
    };
    var getPayloadDealerIds = function () {
        var __adminDealers = __spreadArray([], adminInfo.Dealers, true);
        var __dealerIds = __adminDealers.map(function (item) {
            return Number(item.Id);
        });
        return __dealerIds;
    };
    var handleCreateAdmin = function () {
        var __dealerIds = getPayloadDealerIds();
        var __payload = {
            FirstName: adminInfo.FirstName,
            LastName: adminInfo.LastName,
            Email: adminInfo.Email,
            PhoneNumber: adminInfo.PhoneNumber,
            RoleType: adminInfo.RoleType,
            DealerIds: __dealerIds
        };
        api_service.post({
            endPoint: api_urls.CREATE_ADMIN,
            payLoad: __payload,
            showLoader: true
        }).then(function (res) {
            var _a;
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                setOpenSuccessPopup(true);
            }
            else {
                utility.alert((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.message);
            }
        }).catch(function (err) {
            console.log('getDealersList ~ err:', err);
        });
    };
    var getButtonText = function () {
        if (userAction === 'Create') {
            return 'Submit';
        }
        else {
            return actionType === 'View' ? 'Edit Admin' : 'Update';
        }
    };
    var getDealersList = function () {
        api_service.get({
            endPoint: api_urls.VENDOR_LIST,
        }).then(function (res) {
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                var __dealerList = res.data.data.map(function (item) {
                    return { Id: item.Id, Name: item.Name };
                });
                setDealerList(__dealerList);
            }
        }).catch(function (err) {
            console.log('getDealersList ~ err:', err);
        });
    };
    var getRolesList = function () {
        api_service.get({
            endPoint: api_urls.ROLES_LIST,
            payLoad: { PortalType: 'ADMIN' }
        }).then(function (res) {
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                var __rolesList = res.data.data.map(function (item) {
                    return { Id: item.RoleType, Name: item.RoleDescription };
                });
                setRolesList(__rolesList);
            }
        }).catch(function (err) {
            console.log('getDealersList ~ err:', err);
        });
    };
    var getSelectedStateObject = useCallback(function (dropDownCode) {
        if (dropDownCode) {
            var stateObj = rolesList.find(function (roleObject) { return roleObject.Id === dropDownCode; });
            return stateObj;
        }
        return dropDownCode;
    }, [adminInfo.RoleType, rolesList]);
    var handleSelectionChange = function (event, values) {
        setErrors(function (prevState) { return (__assign(__assign({}, prevState), { Dealers: '' })); });
        setAdminInfo(function (prevState) { return (__assign(__assign({}, prevState), { Dealers: values })); });
    };
    useEffect(function () {
        setActionType(userAction);
    }, [userAction]);
    useEffect(function () {
        if (actionType === 'Create') {
            setAdminInfo(__assign({}, initialAdminInfo));
        }
        else {
            var __dealerValues = (rowData === null || rowData === void 0 ? void 0 : rowData.AssignedDealers) && JSON.parse(rowData === null || rowData === void 0 ? void 0 : rowData.AssignedDealers).map(function (item) {
                return { Id: item.VendorId, Name: item.VendorName };
            });
            setAdminInfo(__assign(__assign({}, rowData), { Dealers: __dealerValues }));
        }
    }, [actionType]);
    useEffect(function () {
        getDealersList();
        getRolesList();
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(ConfirmationAndInformationPoup, { popupType: POPUP_TYPE.UNSAVED_DATA_WARNING, titleText: 'Are you sure?', message: "Are you sure you want to ".concat(userAction !== 'Create' ? 'update' : 'create', " the Admin?"), acceptBtnText: 'Yes', denyBtnText: 'No', acceptCallBack: useCallback(function () {
                if (userAction !== 'Create') {
                    handleUpdateAdmin();
                }
                else {
                    handleCreateAdmin();
                }
                setOpenConfirmationPopup(false);
            }, [openConfirmationPopup]), denyCallBack: useCallback(function () {
                setOpenConfirmationPopup(false);
            }, []), open: openConfirmationPopup, key: "".concat(openConfirmationPopup), cancelCallBack: useCallback(function () {
                setOpenConfirmationPopup(false);
            }, []) }),
        React.createElement(ConfirmationAndInformationPoup, { popupType: POPUP_TYPE.SUCCESS_ALERT, titleText: 'Success!', message: "Admin has been ".concat(userAction !== 'Create' ? 'updated' : 'created', " successfully."), acceptBtnText: 'Ok', acceptCallBack: useCallback(function () {
                setOpenSuccessPopup(false);
                handleDrawerClose();
                getAdminList();
            }, [openConfirmationPopup]), open: openSuccessPopup, key: "".concat(openSuccessPopup) }),
        React.createElement(Dialog, { open: open, className: 'MuiDialogue-upload location-popup' },
            React.createElement(DialogTitle, { sx: { m: 0, p: 2 }, id: "customized-dialog-title" }, actionType === 'View' ? 'View Admin Details' : "".concat(actionType, " Admin")),
            React.createElement(IconButton, { "aria-label": "close", onClick: function () { return handleDrawerClose(); }, className: 'popup-close', sx: {
                    position: 'absolute',
                    right: 8,
                    top: 8,
                } },
                React.createElement(CloseIcon, null)),
            React.createElement(DialogContent, null, actionType === 'View' ?
                React.createElement(Grid, { container: true, rowSpacing: 3, columnSpacing: { xs: 1, sm: 2, md: 0 } },
                    React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                        React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                            React.createElement(Typography, { className: 'view-page-labels' }, "First Name"),
                            React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.FirstName))),
                    React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                        React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                            React.createElement(Typography, { className: 'view-page-labels' }, "Last Name"),
                            React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.LastName))),
                    React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                        React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                            React.createElement(Typography, { className: 'view-page-labels' }, "Email"),
                            React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.Email))),
                    React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                        React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                            React.createElement(Typography, { className: 'view-page-labels' }, "Phone Number"),
                            React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.PhoneNumber))),
                    React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: (rowData === null || rowData === void 0 ? void 0 : rowData.RoleType) !== 'ADMIN' ? '1px #E6E6E6 solid' : '' } },
                        React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                            React.createElement(Typography, { className: 'view-page-labels' }, "Assigned Role"),
                            React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.RoleType))),
                    (rowData === null || rowData === void 0 ? void 0 : rowData.RoleType) !== 'ADMIN' &&
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Grid, { style: { padding: '5px' } },
                                React.createElement(Typography, { className: 'view-page-labels' }, "Assigned Dealers"),
                                React.createElement(Stack, { direction: 'row', spacing: 1.2 }, (rowData === null || rowData === void 0 ? void 0 : rowData.AssignedDealers) && JSON.parse(rowData.AssignedDealers).some(function (item) { return item.VendorName !== null; }) > 0 && JSON.parse(rowData.AssignedDealers).map(function (item, index) { return (React.createElement(Chip, { key: index, label: item === null || item === void 0 ? void 0 : item.VendorName, size: "small", sx: { marginTop: '8px !important', marginBottom: '8px !important' } })); })))))
                :
                    React.createElement(Grid, { container: true, rowSpacing: 3, columnSpacing: { xs: 1, sm: 2, md: 3 } },
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(TextField, { label: 'First Name', name: 'FirstName', isMandatoryField: true, placeholder: 'First Name', fullWidth: true, value: adminInfo === null || adminInfo === void 0 ? void 0 : adminInfo.FirstName, onChange: handleNewLocationInfoChange, onBlur: handleOnInputBlur, error: Boolean(errors.FirstName), error_message: errors.FirstName, type: "text" })),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(TextField, { label: 'Last Name', name: 'LastName', isMandatoryField: true, placeholder: 'Last Name', fullWidth: true, value: adminInfo === null || adminInfo === void 0 ? void 0 : adminInfo.LastName, onChange: handleNewLocationInfoChange, onBlur: handleOnInputBlur, error: Boolean(errors.LastName), error_message: errors.LastName, type: "text" })),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(TextField, { label: 'Email', name: 'Email', isMandatoryField: true, placeholder: 'Email', fullWidth: true, value: adminInfo === null || adminInfo === void 0 ? void 0 : adminInfo.Email, onChange: handleNewLocationInfoChange, disabled: actionType === 'Edit', onBlur: handleOnInputBlur, error: Boolean(errors.Email), error_message: errors.Email, type: "text" })),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(TextField, { label: 'Phone Number', name: 'PhoneNumber', isMandatoryField: true, placeholder: 'Phone Number', fullWidth: true, value: adminInfo === null || adminInfo === void 0 ? void 0 : adminInfo.PhoneNumber, onChange: handleNewLocationInfoChange, onBlur: handleOnInputBlur, error: Boolean(errors.PhoneNumber), error_message: errors.PhoneNumber, type: "text", maxLength: 10, startAdornment: '+1', regExp: constants.regex.PhoneNumber_DownPayment })),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(AutoCompleteDD, { options: rolesList, displayKey: 'Name', valueKey: 'Id', label: 'Assign Role', placeholder: 'Role', handleChange: handleNewLocationInfoChange, selectedValue: getSelectedStateObject(adminInfo.RoleType), name: "RoleType", errorMessage: errors.RoleType, isMandatoryField: true, handleBlur: handleOnInputBlur })),
                        adminInfo.RoleType !== 'ADMIN' &&
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement(AutoCompleteMultiSelectDD, { options: dealerList, displayKey: 'Name', valueKey: 'Id', label: 'Assign Dealers', placeholder: 'Dealers', handleChange: handleSelectionChange, selectedValue: adminInfo === null || adminInfo === void 0 ? void 0 : adminInfo.Dealers, name: "Dealers", errorMessage: errors.Dealers, isMandatoryField: true, handleBlur: handleOnInputBlur })))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { variant: 'contained', onClick: function () { return handleClick(); }, size: 'small', className: 'btn-txt' }, getButtonText())))));
};
export default AdminModuleDrawer;
