import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import React from 'react';
import './../../assets/scss/form_styles.scss';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Stack, FormControl, FormHelperText, Typography, Box, IconButton } from '@mui/material';
import moment from 'moment';
import { CloseIconSmall } from '../icons';
var MUIDatePicker = function (_a) {
    var labelText = _a.labelText, selectedDate = _a.selectedDate, dateChangeHandler = _a.dateChangeHandler, errorMessage = _a.errorMessage, isMandatory = _a.isMandatory, disabled = _a.disabled, minDate = _a.minDate, maxDate = _a.maxDate, clearDate = _a.clearDate;
    var handleClearDate = function () {
        clearDate && clearDate();
    };
    return (React.createElement(Stack, { className: "date-container ".concat(disabled ? 'disable-fields' : '') },
        React.createElement(FormControl, { error: Boolean(errorMessage) },
            React.createElement(DemoContainer, { components: ['DatePicker'] },
                React.createElement(DemoItem, { label: errorMessage ? React.createElement(Typography, { fontSize: '14px', className: errorMessage ? 'error' : '' },
                        React.createElement(Box, { component: 'span', color: "#d32f2f" }, labelText.toUpperCase() + "".concat(isMandatory ? '*' : '')))
                        :
                            React.createElement(Typography, { fontSize: '14px', className: errorMessage ? 'error' : '' },
                                React.createElement(React.Fragment, null,
                                    labelText.toUpperCase(),
                                    isMandatory &&
                                        React.createElement(Box, { component: 'span', color: "#d32f2f" }, "*"))) },
                    React.createElement(DesktopDatePicker, { defaultValue: selectedDate === null || selectedDate === '' ? null : moment(selectedDate), value: moment(selectedDate ? selectedDate : null), onChange: dateChangeHandler, className: "date-picker-styles", disabled: disabled, minDate: maxDate ? moment(maxDate) : null, maxDate: minDate ? moment(minDate) : null })),
                clearDate && selectedDate && !errorMessage &&
                    React.createElement(IconButton, { size: "small", onClick: handleClearDate, className: 'clear-date', title: "Clear Date" },
                        React.createElement(CloseIconSmall, null))),
            React.createElement(FormHelperText, { sx: { marginLeft: '0px' } }, errorMessage))));
};
export default MUIDatePicker;
