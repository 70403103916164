var AppActions = (function () {
    function AppActions() {
        var _this = this;
        this.Constants = {
            ShowLoader: 'app/loader/show',
            HideLoader: 'app/loader/hide',
            ResetStore: 'app/store/reset',
            UPLOAD_PERCENTAGE: 'UPLOAD_PERCENTAGE',
            CLEAR_PERCENTAGE_RECORDS: 'CLEAR_PERCENTAGE_RECORDS',
            SNACKBAR_ALERT_MSG: 'SNACKBAR_ALERT_MSG'
        };
        this.showLoader = function () { return ({
            type: _this.Constants.ShowLoader
        }); };
        this.hideLoader = function () { return ({
            type: _this.Constants.HideLoader
        }); };
        this.resetStore = function () { return ({
            type: _this.Constants.ResetStore
        }); };
        this.updateUploadPercentage = function (data) {
            return {
                payload: data,
                type: _this.Constants.UPLOAD_PERCENTAGE
            };
        };
        this.clearUploadPercentage = function () {
            return {
                type: _this.Constants.CLEAR_PERCENTAGE_RECORDS
            };
        };
        this.snackbarMessage = function (data) {
            return {
                payload: data,
                type: _this.Constants.SNACKBAR_ALERT_MSG
            };
        };
    }
    return AppActions;
}());
export var app_actions = new AppActions();
