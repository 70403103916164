import { createTheme } from "@mui/material";
var primaryColorScheme = {
    main: '#14151C',
    light: '#F3F4F6',
    dark: '#C2C7D0',
    contrastText: '#FFFFFF',
};
var defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#14151C',
        }
    },
    typography: {
        fontFamily: 'Roboto',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'capitalize',
                }
            }
        }
    }
});
export default defaultTheme;
