// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-col .MuiTooltip-popper {
  inset: -26px auto auto -75px !important;
}
.action-col .MuiTooltip-popper .MuiTooltip-tooltip {
  background-color: #FFFFFF;
}
.action-col .MuiTooltip-popper .MuiTooltip-tooltip .MuiStack-root .MuiStack-root {
  border-bottom: 1px solid #DFE2E7;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/moduleTable.scss"],"names":[],"mappings":"AAII;EACI,uCAAA;AAHR;AAKQ;EACI,yBAAA;AAHZ;AAMgB;EACI,gCAAA;AAJpB","sourcesContent":["@import './mixins.scss';\n@import './variable.scss';\n\n.action-col {\n    .MuiTooltip-popper {\n        inset: -26px auto auto -75px !important;\n\n        .MuiTooltip-tooltip {\n            background-color: #FFFFFF;\n\n            .MuiStack-root {\n                .MuiStack-root {\n                    border-bottom: 1px solid #DFE2E7;\n                }\n            }\n\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
