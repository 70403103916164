import * as React from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavigationConfig } from '../../utils/navigation_config';
import CloseIcon from '../../assets/images/close-icon.svg';
var EmailVerifySuccess = function (props) {
    var _a, _b;
    var navConfig = NavigationConfig;
    var navigate = useNavigate();
    var location = useLocation();
    return (React.createElement(Box, null,
        React.createElement(IconButton, { onClick: function () { return navigate(navConfig.Account.Login); }, className: "close-icon" },
            React.createElement("img", { src: CloseIcon })),
        React.createElement(Typography, { fontSize: '30px', align: 'left', fontWeight: 'bold', pb: '16px' }, ((_a = location.state) === null || _a === void 0 ? void 0 : _a.useCase) ? 'Email Verified Successfully!' : 'Password Reset Successful!'),
        React.createElement(Box, { pb: '32px' }, ((_b = location.state) === null || _b === void 0 ? void 0 : _b.useCase) ? React.createElement(React.Fragment, null,
            React.createElement(Typography, { variant: "body2", gutterBottom: true, align: 'left', fontSize: { xs: '14px', sm: '14px', md: '16px', lg: '16px' }, className: "need-help" }, "Your email has been verified"),
            React.createElement(Typography, { variant: "body2", gutterBottom: true, align: 'left', fontSize: { xs: '14px', sm: '14px', md: '16px', lg: '16px' }, className: "need-help" }, "Please proceed to Sign In to continue."))
            :
                React.createElement(React.Fragment, null,
                    React.createElement(Typography, { variant: "body2", gutterBottom: true, align: 'left', fontSize: { xs: '14px', sm: '14px', md: '16px', lg: '16px' }, className: "need-help" }, "Your password has been reset."),
                    React.createElement(Typography, { variant: "body2", gutterBottom: true, align: 'left', fontSize: { xs: '14px', sm: '14px', md: '16px', lg: '16px' }, className: "need-help" }, "Please Sign In again using the new password."))),
        React.createElement(Box, { className: 'd-flex mt-2', pb: '40px' },
            React.createElement(Button, { fullWidth: true, variant: 'contained', className: 'ml-2', onClick: function () { return navigate(navConfig.Account.Login); }, sx: { textTransform: 'capitalize' } }, "Sign In"))));
};
export default EmailVerifySuccess;
