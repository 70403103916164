var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Checkbox, Divider, IconButton, Stack, Typography, Button, Card, CardMedia, InputLabel, Grid, SwipeableDrawer, FormGroup, FormControlLabel } from '@mui/material';
import './../../assets/scss/dealerModule.scss';
import { BackArrowIcon, EditPencilIcon } from '../../components/icons';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import SelectDD from '../../components/FormComponents/SelectDD';
import { styled } from '@mui/material/styles';
import DealerMangementDrawer from './DealerMangementDrawer';
import { api_service } from '../../api/api_service';
import { api_urls } from '../../api/api_urls';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { utility } from '../../utils';
import ConfirmationAndInformationPoup, { POPUP_TYPE } from '../../components/UtilityComponents/ConfirmationAndInformationPoup';
import _ from '../../utils/lodash';
import { AppTypes, deepEqual } from '../../utils/commonUtilities';
import { NavigationConfig } from '../../utils/navigation_config';
import SvelteJSONEditor from "./JsonEditor";
import AttachmentIcon from '@mui/icons-material/Attachment';
import LandingPageBackgroundLogo from '../../assets/images/defaultBG.png';
import LandingPageLogo from '../../assets/images/Auxilior-Logo-BW-Vertical-White.png';
import LoginScreenLogo from '../../assets/images/Auxilior-Logo-BW-Horizontal-Black.png';
import HeaderLogo from '../../assets/images/Auxilior-Logo-BW-Horizontal-White.png';
import DefaultWhiteLogo from '../../assets/images/whiteBackground.svg';
import DefaultHeaderLogo from '../../assets/images/Transparent_Logo.png';
var VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
var initialDealerValues = {
    VendorId: null,
    IsCreditAppEnabled: 0,
    IsLOCAppEnabled: 0,
    IsTakeDownAppEnabled: 0,
    IsQuoteToolEnabled: 0,
    IsReqDocEnabled: 0,
    IsSendAppEnabled: 0,
    IsDefaultLandingLogo: 0,
    IsDefaultLoginLogo1: 0,
    IsDefaultLoginLogo2: 0,
    IsDefaultLoginBackground: 0,
    FontStyle: '',
    LandingLogo: '',
    LandingLogoUrl: '',
    LoginLogo1: '',
    LoginLogo1Url: '',
    LoginLogo2: '',
    LoginLogo2Url: '',
    LoginBackground: '',
    LoginBackgroundUrl: '',
    PrimaryThemeColor: '',
    SecondaryThemeColor: '',
    DealerName: '',
    DealerUrl: '',
    Programs: [
        {
            Id: null,
            Name: '',
            FinanceCompanyRecordId: null,
            Country: '',
            PrivateLabel: ''
        }
    ],
    RateCard: '',
    FAQ: ''
};
export var initialBlobFiles = {
    LandingLogo: '',
    LoginLogo1: '',
    LoginLogo2: '',
    LoginBackground: '',
    RateCard: '',
    FAQ: '',
};
var DealerManagementEdit = function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var navigate = useNavigate();
    var location = useLocation();
    var _h = useState(), rowData = _h[0], setRowData = _h[1];
    var _j = useState(false), open = _j[0], setOpen = _j[1];
    var _k = useState(''), userActionType = _k[0], setUserActionType = _k[1];
    var _l = useState(false), isFetching = _l[0], setIsFetching = _l[1];
    var _m = useState(0), selectedProgramIndex = _m[0], setSelectedProgramIndex = _m[1];
    var _o = useState(__assign({}, initialDealerValues)), dealerData = _o[0], setDealerData = _o[1];
    console.log('DealerManagementEdit ~ initialDealerValues:', initialDealerValues);
    console.log('DealerManagementEdit ~ dealerData:', dealerData);
    var _p = useState(__assign({}, initialDealerValues)), freezedDealerData = _p[0], setFreezedDealerData = _p[1];
    var _q = React.useState(__assign({}, initialBlobFiles)), blobFiles = _q[0], setBlobFiles = _q[1];
    var _r = useState(false), openConfirmationPopup = _r[0], setOpenConfirmationPopup = _r[1];
    var _s = useState(false), openUnsavedChangesPopup = _s[0], setOpenUnsavedChangesPopup = _s[1];
    var _t = useState(false), openSuccessPopup = _t[0], setOpenSuccessPopup = _t[1];
    var navConfig = NavigationConfig;
    var _u = useState({}), drawerState = _u[0], setDrawerState = _u[1];
    var _v = useState({}), defaultImageBlobs = _v[0], setDefaultImageBlobs = _v[1];
    var defaultImages = [
        { name: 'LandingLogo', path: HeaderLogo, fileName: 'Auxilior-Logo-BW-Horizontal-White.png' },
        { name: 'LoginLogo1', path: LandingPageLogo, fileName: 'Auxilior-Logo-BW-Horizontal-White.png' },
        { name: 'LoginLogo2', path: LoginScreenLogo, fileName: 'Auxilior-Logo-BW-Vertical-Black.png' },
        { name: 'LoginBackground', path: LandingPageBackgroundLogo, fileName: 'defaultBG.png' },
    ];
    var _w = useState({
        json: {}
    }), norJsonContent = _w[0], setNorJsonContent = _w[1];
    var _x = useState({
        json: {}
    }), locJsonContent = _x[0], setLocJsonContent = _x[1];
    var _y = useState({
        json: {}
    }), schJsonContent = _y[0], setSchJsonContent = _y[1];
    var vendorId = location.state;
    var goBackHandler = function () {
        if (deepEqual(dealerData, freezedDealerData)) {
            navigate(-1);
        }
        else {
            setOpenUnsavedChangesPopup(true);
        }
    };
    var handleEditOrViewClick = function (userAction, programIndex) {
        setUserActionType(userAction);
        setSelectedProgramIndex(programIndex);
        setOpen(true);
    };
    var handleClose = function () {
        setRowData(null);
        setOpen(false);
    };
    var getDealerDetails = function () {
        setIsFetching(true);
        api_service.get({
            endPoint: api_urls.DEALER_DETAILS_BY_ID,
            payLoad: {
                VendorId: Number(vendorId)
            }
        }).then(function (res) {
            setIsFetching(false);
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                var dealerData_1 = res.data.data;
                setDealerData(dealerData_1);
                setFreezedDealerData(dealerData_1);
            }
        }).catch(function (err) { setIsFetching(false); });
    };
    var handleChange = function (event) {
        var _a;
        var _b = event.target, name = _b.name, checked = _b.checked;
        setDealerData(__assign(__assign({}, dealerData), (_a = {}, _a[name] = checked ? 1 : 0, _a)));
    };
    var getDefaultOptionNames = function (name) {
        switch (name) {
            case 'IsDefaultLandingLogo':
                return {
                    fileData: defaultImageBlobs === null || defaultImageBlobs === void 0 ? void 0 : defaultImageBlobs.LandingLogo,
                    fileName: 'LandingLogo'
                };
            case 'IsDefaultLoginLogo1':
                return {
                    fileData: defaultImageBlobs === null || defaultImageBlobs === void 0 ? void 0 : defaultImageBlobs.LoginLogo1,
                    fileName: 'LoginLogo1'
                };
            case 'IsDefaultLoginLogo2':
                return {
                    fileData: defaultImageBlobs === null || defaultImageBlobs === void 0 ? void 0 : defaultImageBlobs.LoginLogo2,
                    fileName: 'LoginLogo2'
                };
            case 'IsDefaultLoginBackground':
                return {
                    fileData: defaultImageBlobs === null || defaultImageBlobs === void 0 ? void 0 : defaultImageBlobs.LoginBackground,
                    fileName: 'LoginBackground'
                };
            default:
                return {
                    fileData: null,
                    fileName: ''
                };
        }
    };
    var handleLogoCheckBoxes = function (event) {
        var _a = event.target, name = _a.name, checked = _a.checked;
        var _b = getDefaultOptionNames(name), fileData = _b.fileData, fileName = _b.fileName;
        if (!validateFileSizeAndName(fileData)) {
            return;
        }
        if (fileData && checked) {
            var uploadedFileUrl_1;
            uploadedFileUrl_1 = URL.createObjectURL(fileData);
            setDealerData(function (prevData) {
                var _a;
                return (__assign(__assign({}, prevData), (_a = {}, _a[fileName] = fileData, _a["".concat(fileName, "Url")] = uploadedFileUrl_1, _a[name] = 1, _a)));
            });
            setBlobFiles(function (prevData) {
                var _a;
                return (__assign(__assign({}, prevData), (_a = {}, _a[fileName] = fileData, _a)));
            });
        }
        else {
            if (!checked) {
                setDealerData(function (prevData) {
                    var _a;
                    return (__assign(__assign({}, prevData), (_a = {}, _a[fileName] = '', _a["".concat(fileName, "Url")] = '', _a[name] = 0, _a)));
                });
                setBlobFiles(function (prevData) {
                    var _a;
                    return (__assign(__assign({}, prevData), (_a = {}, _a[fileName] = '', _a)));
                });
            }
        }
    };
    var getLogoCorrespondingCheckbox = function (name) {
        switch (name) {
            case 'LandingLogo':
                return 'IsDefaultLandingLogo';
            case 'LoginLogo1':
                return 'IsDefaultLoginLogo1';
            case 'LoginLogo2':
                return 'IsDefaultLoginLogo2';
            case 'LoginBackground':
                return 'IsDefaultLoginBackground';
            default:
                return '';
        }
    };
    var fileSelectHandler = function (event) {
        var fileEventData = event.target.files[0];
        event.target.value = '';
        if (!validateFileSizeAndName(fileEventData)) {
            return;
        }
        if (event.target.name === 'RateCard' || event.target.name === 'FAQ') {
            if ((fileEventData === null || fileEventData === void 0 ? void 0 : fileEventData.type) !== 'application/pdf') {
                utility.alert('Please upload a valid file');
                return;
            }
        }
        if (['LoginBackground', 'LoginLogo2', 'LoginLogo1', 'LandingLogo'].includes(event.target.name)) {
            if (!['image/jpg', 'image/jpeg', 'image/png', 'image/svg'].includes(fileEventData === null || fileEventData === void 0 ? void 0 : fileEventData.type)) {
                utility.alert('Please upload a valid file');
                return;
            }
        }
        if (fileEventData) {
            var uploadedFileUrl = void 0;
            uploadedFileUrl = URL.createObjectURL(fileEventData);
            var __dealerData = __assign({}, dealerData);
            if (event.target.name !== 'RateCard' && event.target.name !== 'FAQ') {
                var checkboxName = getLogoCorrespondingCheckbox(event.target.name);
                __dealerData[checkboxName] = 0;
            }
            __dealerData[event.target.name] = fileEventData,
                __dealerData["".concat(event.target.name, "Url")] = uploadedFileUrl;
            setDealerData(__dealerData);
            setBlobFiles(function (prevData) {
                var _a;
                return (__assign(__assign({}, prevData), (_a = {}, _a[event.target.name] = fileEventData, _a)));
            });
        }
    };
    var validateFileSizeAndName = function (fileEventData) {
        if (!fileEventData) {
            return false;
        }
        return true;
    };
    var jsonToFormData = function (json, formData, parentKey) {
        if (formData === void 0) { formData = null; }
        if (parentKey === void 0) { parentKey = ''; }
        if (!formData) {
            formData = new FormData();
        }
        var _loop_1 = function (key) {
            if (json.hasOwnProperty(key)) {
                var nestedKey_1 = parentKey ? "".concat(parentKey, "[").concat(key, "]") : key;
                if (typeof json[key] === 'object' && !Array.isArray(json[key]) && !(json[key] instanceof File)) {
                    jsonToFormData(json[key], formData, nestedKey_1);
                }
                else if (Array.isArray(json[key])) {
                    json[key].forEach(function (item, index) {
                        var arrayKey = "".concat(nestedKey_1, "[").concat(index, "]");
                        if (typeof item === 'object' && !(item instanceof File)) {
                            jsonToFormData(item, formData, arrayKey);
                        }
                        else {
                            formData.append(arrayKey, item);
                        }
                    });
                }
                else {
                    formData.append(nestedKey_1, json[key]);
                }
            }
        };
        for (var key in json) {
            _loop_1(key);
        }
        return formData;
    };
    var handleSaveConfig = function () {
        var __blobFiles = __assign({}, blobFiles);
        var fileUrls = {};
        for (var blobFilekeyName in __blobFiles) {
            if (!dealerData[blobFilekeyName] && freezedDealerData["".concat(blobFilekeyName, "Url")]) {
                fileUrls["".concat(blobFilekeyName, "Url")] = freezedDealerData["".concat(blobFilekeyName, "Url")];
            }
        }
        if (Object.keys(fileUrls).length > 0) {
            handleFileDelete();
        }
        else {
            handleSaveDealerConfiguration();
        }
    };
    var handleFileDelete = function () {
        var __blobFiles = __assign({}, blobFiles);
        var fileUrls = {};
        var __payload = {};
        for (var blobFilekeyName in __blobFiles) {
            if (dealerData[blobFilekeyName] === '') {
                fileUrls["".concat(blobFilekeyName, "Url")] = freezedDealerData["".concat(blobFilekeyName, "Url")];
            }
        }
        __payload.VendorId = Number(vendorId);
        __payload.FileUrls = [fileUrls];
        api_service.put({
            endPoint: api_urls.DELETE_DEALER_FILES,
            payLoad: __payload,
            showLoader: true
        }).then(function (res) {
            var _a;
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                handleSaveDealerConfiguration();
            }
            else {
                utility.alert((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.message);
            }
        }).catch(function (err) {
            console.log('handleSaveDealerConfiguration ~ err:', err);
        });
    };
    var handleSaveDealerConfiguration = function () {
        var __payload = _.cloneDeep(dealerData);
        for (var keyname in __payload) {
            if (__payload[keyname] === '' || __payload[keyname] === null) {
                delete __payload[keyname];
            }
        }
        var formData = jsonToFormData(__payload);
        api_service.put({
            endPoint: api_urls.UPDATE_DEALER_CONFIG,
            headerType: api_service.ContentHeaders.FormData,
            payLoad: formData,
            showLoader: true
        }).then(function (res) {
            var _a;
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                setOpenSuccessPopup(true);
            }
            else {
                utility.alert((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.message);
            }
        }).catch(function (err) {
            console.log('handleSaveDealerConfiguration ~ err:', err);
        });
    };
    var handleFileRemove = function (fileName) {
        setBlobFiles(function (prevData) {
            var _a;
            return (__assign(__assign({}, prevData), (_a = {}, _a[fileName] = '', _a)));
        });
        setDealerData(function (prevData) {
            var _a;
            return (__assign(__assign({}, prevData), (_a = {}, _a[fileName] = '', _a["".concat(fileName, "Url")] = '', _a)));
        });
    };
    var handleSaveJsonData = function () {
        var NORJsonData = null;
        if (norJsonContent === null || norJsonContent === void 0 ? void 0 : norJsonContent.text) {
            NORJsonData = JSON.parse(norJsonContent === null || norJsonContent === void 0 ? void 0 : norJsonContent.text);
        }
        else if (norJsonContent === null || norJsonContent === void 0 ? void 0 : norJsonContent.json) {
            NORJsonData = norJsonContent === null || norJsonContent === void 0 ? void 0 : norJsonContent.json;
        }
        var LOCJsonData = null;
        if (locJsonContent === null || locJsonContent === void 0 ? void 0 : locJsonContent.text) {
            LOCJsonData = JSON.parse(locJsonContent === null || locJsonContent === void 0 ? void 0 : locJsonContent.text);
        }
        else if (locJsonContent === null || locJsonContent === void 0 ? void 0 : locJsonContent.json) {
            LOCJsonData = locJsonContent === null || locJsonContent === void 0 ? void 0 : locJsonContent.json;
        }
        var SCHJsonData = null;
        if (schJsonContent === null || schJsonContent === void 0 ? void 0 : schJsonContent.text) {
            SCHJsonData = JSON.parse(schJsonContent === null || schJsonContent === void 0 ? void 0 : schJsonContent.text);
        }
        else if (schJsonContent === null || schJsonContent === void 0 ? void 0 : schJsonContent.json) {
            SCHJsonData = schJsonContent === null || schJsonContent === void 0 ? void 0 : schJsonContent.json;
        }
        var prparePayload = {
            VendorId: Number(vendorId),
            NORJson: NORJsonData,
            LOCJson: LOCJsonData,
            SCHJson: SCHJsonData
        };
        api_service.put({
            endPoint: api_urls.SAVE_OR_UPDATE_FIELD_CONFIG,
            payLoad: prparePayload,
            showLoader: true
        }).then(function (res) {
            var _a;
            var _b;
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                setDrawerState(__assign(__assign({}, drawerState), (_a = {}, _a['right'] = false, _a)));
            }
            else {
                utility.alert((_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.message);
            }
        }).catch(function (err) {
            console.log('handleSaveDealerConfiguration ~ err:', err);
        });
    };
    var getFieldConfigData = function () {
        api_service.get({
            endPoint: "".concat(api_urls.GET_FIELD_CONFIG_DATA, "?VendorId=").concat(vendorId),
            showLoader: true
        }).then(function (res) {
            var _a;
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                var response = res.data.data;
                if (response) {
                    var NORJson = response.NORJson, LOCJson = response.LOCJson, SCHJson = response.SCHJson;
                    setNorJsonContent({ json: NORJson });
                    setLocJsonContent({ json: LOCJson });
                    setSchJsonContent({ json: SCHJson });
                }
            }
            else {
                utility.alert((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.message);
            }
        }).catch(function (err) {
            console.log('handleSaveDealerConfiguration ~ err:', err);
        });
    };
    useEffect(function () {
        getFieldConfigData();
    }, []);
    var parseJSON = function (jsonString) {
        if (jsonString === null || jsonString === void 0 ? void 0 : jsonString.json) {
            try {
                return (jsonString === null || jsonString === void 0 ? void 0 : jsonString.json) ? JSON.stringify(jsonString === null || jsonString === void 0 ? void 0 : jsonString.json) : null;
            }
            catch (error) {
                console.error("Error stringify JSON:", error);
                return false;
            }
        }
        else if (jsonString === null || jsonString === void 0 ? void 0 : jsonString.text) {
            try {
                return (jsonString === null || jsonString === void 0 ? void 0 : jsonString.text) ? JSON.parse(jsonString === null || jsonString === void 0 ? void 0 : jsonString.text) : null;
            }
            catch (error) {
                console.error("Error parsing JSON:", error);
                return false;
            }
        }
    };
    var isJsonValid = function () {
        var nor = parseJSON(norJsonContent && norJsonContent);
        var loc = parseJSON(locJsonContent && locJsonContent);
        var sch = parseJSON(schJsonContent && schJsonContent);
        return nor && Object.keys(nor).length > 0 &&
            loc && Object.keys(loc).length > 0 &&
            sch && Object.keys(sch).length > 0;
    };
    var toggleDrawer = function (anchor, open, appName) { return function () {
        var _a;
        setDrawerState(__assign(__assign({}, drawerState), (_a = {}, _a[anchor] = open ? appName : null, _a)));
    }; };
    var list = function (anchor, appName) { return (React.createElement(Box, { role: "presentation", className: "cstDrawer", p: 2.5 },
        React.createElement(Stack, { direction: 'row', alignItems: 'center', justifyContent: 'space-between', mb: 2 },
            React.createElement(Typography, { variant: 'h5', fontWeight: 700 }, "JSON Editor"),
            React.createElement(CloseIcon, { onClick: toggleDrawer(anchor, false, "close") })),
        React.createElement(Stack, { alignItems: 'center', justifyContent: 'flex-start', sx: {
                height: 'calc(100dvh - 158px)',
                overflow: 'auto',
                borderTop: '1px solid #80808036',
                borderBottom: '1px solid #80808036',
                marginBottom: '16px',
                padding: '8px 0px',
            } },
            React.createElement(SvelteJSONEditor, { onChange: function (content) {
                    switch (appName) {
                        case "Credit Application":
                            setNorJsonContent(content);
                            break;
                        case "Line of Credit Application":
                            setLocJsonContent(content);
                            break;
                        case "SCH-TakeDown Application":
                            setSchJsonContent(content);
                            break;
                        default:
                            break;
                    }
                }, content: appName === "Credit Application" ? norJsonContent : appName === "Line of Credit Application" ? locJsonContent : schJsonContent }), props === null || props === void 0 ? void 0 :
            props.children),
        React.createElement(Stack, { direction: 'row', alignItems: 'center', justifyContent: 'flex-end', spacing: 2 },
            React.createElement(Button, { onClick: toggleDrawer(anchor, false, "close"), variant: "outlined", sx: {
                    color: '#000',
                    borderRadius: '30px', borderColor: '#000',
                } }, "Cancel"),
            React.createElement(Box, { sx: {
                    '& .MuiButtonBase-root': {
                        backgroundColor: '#000',
                        padding: '6px 8px',
                        borderRadius: '30px'
                    }
                } },
                React.createElement(Button, { type: "submit", variant: "contained", color: "primary", fullWidth: true, className: "commonBtn ", onClick: handleSaveJsonData, disabled: isJsonValid() ? false : true }, "Save"))))); };
    useEffect(function () {
        getDealerDetails();
    }, [vendorId]);
    useEffect(function () {
        var fetchImages = function () { return __awaiter(void 0, void 0, void 0, function () {
            var blobs, _i, defaultImages_1, image, response, blob, file, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        blobs = {};
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 7, , 8]);
                        _i = 0, defaultImages_1 = defaultImages;
                        _a.label = 2;
                    case 2:
                        if (!(_i < defaultImages_1.length)) return [3, 6];
                        image = defaultImages_1[_i];
                        return [4, fetch(image.path)];
                    case 3:
                        response = _a.sent();
                        if (!response.ok) {
                            throw new Error("HTTP error! Status: ".concat(response.status));
                        }
                        return [4, response.blob()];
                    case 4:
                        blob = _a.sent();
                        file = new File([blob], image.fileName, { type: blob.type });
                        blobs[image.name] = file;
                        _a.label = 5;
                    case 5:
                        _i++;
                        return [3, 2];
                    case 6:
                        setDefaultImageBlobs(blobs);
                        return [3, 8];
                    case 7:
                        error_1 = _a.sent();
                        return [2, error_1];
                    case 8: return [2];
                }
            });
        }); };
        fetchImages();
    }, []);
    return (React.createElement(Box, { className: "dealer-view-container" },
        React.createElement(ConfirmationAndInformationPoup, { popupType: POPUP_TYPE.UNSAVED_DATA_WARNING, titleText: 'Are you sure?', message: "Are you sure you want to save the configuration?", acceptBtnText: 'Yes', denyBtnText: 'No', acceptCallBack: useCallback(function () {
                handleSaveConfig();
                setOpenConfirmationPopup(false);
            }, [openConfirmationPopup]), denyCallBack: useCallback(function () {
                setOpenConfirmationPopup(false);
            }, []), open: openConfirmationPopup, key: "".concat(openConfirmationPopup), cancelCallBack: useCallback(function () {
                setOpenConfirmationPopup(false);
            }, []) }),
        React.createElement(ConfirmationAndInformationPoup, { popupType: POPUP_TYPE.SUCCESS_ALERT, titleText: 'Success!', message: "Configuration has been set successfully.", acceptBtnText: 'Ok', acceptCallBack: useCallback(function () {
                setOpenSuccessPopup(false);
                getDealerDetails();
                navigate(navConfig.DealerManagement);
            }, [openConfirmationPopup]), open: openSuccessPopup, key: "".concat(openSuccessPopup) }),
        React.createElement(ConfirmationAndInformationPoup, { popupType: POPUP_TYPE.UNSAVED_DATA_WARNING, titleText: 'Warning, Unsaved Changes', message: 'You are about to leave this section without saving. All changes will be lost. Do you really want to leave without saving?', acceptBtnText: 'Save Changes', denyBtnText: 'Leave without Saving', acceptCallBack: function () {
                handleSaveConfig();
                setOpenUnsavedChangesPopup(false);
            }, denyCallBack: useCallback(function () {
                setOpenUnsavedChangesPopup(false);
                navigate(-1);
            }, []), open: openUnsavedChangesPopup, key: "".concat(openUnsavedChangesPopup), cancelCallBack: useCallback(function () { return setOpenUnsavedChangesPopup(false); }, []) }),
        React.createElement(Stack, { direction: 'row', className: "dealer-view-header" },
            React.createElement(IconButton, { size: 'small', onClick: goBackHandler },
                React.createElement(BackArrowIcon, null)),
            React.createElement(Typography, null, "Portal Configurations")),
        React.createElement(Box, { className: "dealer-view-body" },
            React.createElement(Stack, { direction: 'row', className: "dealer-info-box" },
                React.createElement(Stack, null,
                    React.createElement(Typography, { className: "label" }, "Portal Name"),
                    React.createElement(Typography, { className: "content" }, dealerData.DealerName)),
                React.createElement(Stack, null,
                    React.createElement(Typography, { className: "label" }, "Portal URL"),
                    React.createElement(Typography, { className: "content" }, dealerData.DealerUrl))),
            React.createElement(Box, { className: "dealer-view-body-inside" },
                React.createElement(Box, { className: "granted-apps" },
                    React.createElement(Typography, { className: "header-txt", pb: '8px !important' }, "Features Configurations"), (_a = dealerData === null || dealerData === void 0 ? void 0 : dealerData.Programs) === null || _a === void 0 ? void 0 :
                    _a.map(function (program, index) { return (React.createElement(Stack, { key: index, direction: 'row', className: "content-wrapper content-wrapper-edit", mb: 1 },
                        React.createElement(Typography, null, "".concat(index + 1, ". Program Input (Name, Aspire finance company ID)")),
                        React.createElement(Typography, { sx: { '& .MuiButtonBase-root': { padding: '0px', marginRight: '16px' } } },
                            React.createElement(IconButton, { sx: { '& .MuiSvgIcon-root': { width: '21px' } }, size: 'small', onClick: function () { return handleEditOrViewClick('View', index); } },
                                React.createElement(RemoveRedEyeOutlinedIcon, null)),
                            React.createElement(IconButton, { sx: { '& svg': { width: '16px' } }, size: 'small', onClick: function () { return handleEditOrViewClick('Edit', index); } },
                                React.createElement(EditPencilIcon, null))))); })),
                React.createElement(Box, { className: "granted-apps", mb: 1 },
                    React.createElement(Stack, { className: "content-wrapper content-wrapper-edit" },
                        React.createElement(Typography, { className: 'header-txt', width: '100%' }, "Features Configurations"),
                        React.createElement(Divider, { sx: { width: '100%' } }),
                        React.createElement(Stack, { width: '100%' },
                            React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', py: 1.5 },
                                React.createElement(Typography, { className: 'header-txt', whiteSpace: 'nowrap' }, "Applications Granted"),
                                React.createElement(Box, { width: '80%' },
                                    React.createElement(Box, { display: 'flex', alignItems: 'center' },
                                        React.createElement(Typography, { className: 'editText grantFeildsSm' },
                                            React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, disabled: false, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null), name: 'IsCreditAppEnabled', value: dealerData.IsCreditAppEnabled, checked: dealerData.IsCreditAppEnabled === 1, onChange: handleChange }),
                                            "Credit Application"),
                                        React.createElement(Typography, { className: 'editText grantFeildsSm' },
                                            React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, disabled: false, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null), name: 'IsLOCAppEnabled', value: dealerData.IsLOCAppEnabled, checked: dealerData.IsLOCAppEnabled === 1, onChange: handleChange }),
                                            "Line of Credit Application"),
                                        React.createElement(Typography, { className: 'editText grantFeildsMd', sx: { display: { xs: 'none', md: 'block' } } },
                                            React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, disabled: false, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null), name: 'IsTakeDownAppEnabled', value: dealerData.IsTakeDownAppEnabled, checked: dealerData.IsTakeDownAppEnabled === 1, onChange: handleChange }),
                                            "Schedule/Takedown"),
                                        React.createElement(Typography, { className: 'editText grantFeildsSm grantFeildsSmText' },
                                            React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, disabled: false, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null), name: 'IsTakeDownAppEnabled', value: dealerData.IsTakeDownAppEnabled, checked: dealerData.IsTakeDownAppEnabled === 1, onChange: handleChange }),
                                            "Schedule / Takedown")))),
                            React.createElement(Divider, { sx: { width: '100%' } }),
                            React.createElement(Box, { className: "granted-apps", mb: 1, display: 'flex' },
                                React.createElement(Stack, { direction: 'row', justifyContent: 'flex-start !important', className: "content-wrapper content-wrapper-edit content-quote", sx: { border: 'none !important' } },
                                    React.createElement(Typography, null, "Quote tool"),
                                    React.createElement(Typography, null,
                                        React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, disabled: false, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null), name: 'IsQuoteToolEnabled', value: dealerData.IsQuoteToolEnabled, checked: dealerData.IsQuoteToolEnabled === 1, onChange: handleChange }))),
                                React.createElement(Stack, { direction: 'row', justifyContent: 'flex-start !important', className: "content-wrapper content-wrapper-edit content-quote", sx: { border: 'none !important' } },
                                    React.createElement(Typography, null, "Send Application Link"),
                                    React.createElement(Typography, null,
                                        React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, disabled: false, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null), name: 'IsSendAppEnabled', value: dealerData.IsSendAppEnabled, checked: dealerData.IsSendAppEnabled === 1, onChange: handleChange }))),
                                React.createElement(Stack, { direction: 'row', justifyContent: 'flex-start !important', className: "content-wrapper content-wrapper-edit content-quote", sx: { border: 'none !important' } },
                                    React.createElement(Typography, null, "Req Docs"),
                                    React.createElement(Typography, null,
                                        React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, disabled: false, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null), name: 'IsReqDocEnabled', value: dealerData.IsReqDocEnabled, checked: dealerData.IsReqDocEnabled === 1, onChange: handleChange }))))))),
                React.createElement(Box, { className: "granted-apps", mb: 1 },
                    React.createElement(Grid, { container: true, className: "content-wrapper content-wrapper-edit" },
                        React.createElement(Typography, { className: "header-txt" }, "Field Configuration"),
                        React.createElement(Divider, { sx: { width: '100%', marginBottom: '12px' } }),
                        React.createElement(Grid, { container: true, spacing: 2, width: '100% !important' }, AppTypes.map(function (appName, index) { return (React.createElement(Grid, { item: true, xs: 12, sm: 4, lg: 3 },
                            React.createElement(React.Fragment, { key: index },
                                React.createElement(Button, { variant: 'outlined', fullWidth: true, className: 'jsonBtn', onClick: toggleDrawer('right', true, appName) },
                                    React.createElement(AttachmentIcon, { sx: { paddingRight: '10px' } }),
                                    " ",
                                    appName),
                                React.createElement(SwipeableDrawer, { anchor: 'right', open: drawerState['right'] === appName, onClose: toggleDrawer('right', false, 'close'), onOpen: toggleDrawer('right', true, 'open'), sx: {
                                        '& .MuiPaper-root': {
                                            background: '#fff',
                                            minWidth: '800px',
                                            maxWidth: '800px',
                                        }
                                    } }, list('right', appName))))); })))),
                React.createElement(Box, { className: "granted-apps", mb: 1 },
                    React.createElement(Stack, { direction: 'column', alignItems: 'baseline !important', className: "content-wrapper content-wrapper-rate-card content-wrapper-edit" },
                        React.createElement(Typography, null, "Rate Card"),
                        React.createElement(Divider, { sx: { width: '100%' } }),
                        React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', py: 1.5, spacing: 2 },
                            React.createElement(Box, null,
                                React.createElement(Typography, { width: '100%', className: "fileUploadHeader-subheader" }, "Upload rate card for the Portal. Supported file format is PDF only."),
                                React.createElement(Typography, { width: '100%', className: "fileUploadHeader" }, "Rate Card PDF File"),
                                React.createElement(Box, { className: 'choose-file-input' },
                                    React.createElement(Button, { component: "label", variant: "contained", id: 'upload-img-left', className: "upload-btn" },
                                        "Choose file",
                                        React.createElement(VisuallyHiddenInput, { type: "file", name: 'RateCard', onChange: function (e) { return fileSelectHandler(e); }, accept: '.pdf' })),
                                    React.createElement(Box, { id: "custom-text" },
                                        React.createElement(Button, { component: "label", variant: "outlined", id: 'upload-img-right', sx: { padding: '0px' } },
                                            (typeof (dealerData === null || dealerData === void 0 ? void 0 : dealerData.RateCard) === 'string' ? dealerData === null || dealerData === void 0 ? void 0 : dealerData.RateCard : (_b = dealerData === null || dealerData === void 0 ? void 0 : dealerData.RateCard) === null || _b === void 0 ? void 0 : _b.name) || 'No file chosen, yet.',
                                            dealerData.RateCard &&
                                                React.createElement(CloseIcon, { sx: { marginLeft: '8px', cursor: 'pointer', fontSize: '20px' }, onClick: function () { return handleFileRemove('RateCard'); } })))))))),
                React.createElement(Box, { className: "granted-apps", mb: 1 },
                    React.createElement(Stack, { direction: 'column', alignItems: 'baseline !important', className: "content-wrapper content-wrapper-rate-card content-wrapper-edit" },
                        React.createElement(Typography, null, "FAQ"),
                        React.createElement(Divider, { sx: { width: '100%' } }),
                        React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', py: 1.5, spacing: 2 },
                            React.createElement(Stack, { direction: 'row', spacing: 2 },
                                React.createElement(Box, null,
                                    React.createElement(Typography, { width: '100%', className: "fileUploadHeader-subheader" }, "Upload FAQ for the Portal. Supported file format is PDF only."),
                                    React.createElement(Typography, { width: '100%', className: "fileUploadHeader" }, "FAQ PDF File"),
                                    React.createElement(Box, { className: 'choose-file-input' },
                                        React.createElement(Button, { component: "label", variant: "contained", id: 'upload-img-left', className: "upload-btn" },
                                            "Choose file",
                                            React.createElement(VisuallyHiddenInput, { type: "file", name: 'FAQ', onChange: function (e) { return fileSelectHandler(e); }, accept: '.pdf' })),
                                        React.createElement(Box, { id: "custom-text" },
                                            React.createElement(Button, { component: "label", variant: "outlined", id: 'upload-img-right', sx: { padding: '0px' } },
                                                (typeof (dealerData === null || dealerData === void 0 ? void 0 : dealerData.FAQ) === 'string' ? dealerData === null || dealerData === void 0 ? void 0 : dealerData.FAQ : (_c = dealerData === null || dealerData === void 0 ? void 0 : dealerData.FAQ) === null || _c === void 0 ? void 0 : _c.name) || 'No file chosen, yet.',
                                                dealerData.FAQ &&
                                                    React.createElement(CloseIcon, { sx: { marginLeft: '8px', cursor: 'pointer', fontSize: '20px' }, onClick: function () { return handleFileRemove('FAQ'); } }))))))))),
                React.createElement(Box, { className: "granted-apps", mb: 1 },
                    React.createElement(Stack, { className: "content-wrapper content-wrapper-edit" },
                        React.createElement(Typography, { className: 'header-txt', width: '100%' }, "Font Selection"),
                        React.createElement(Divider, { sx: { width: '100%' } }),
                        React.createElement(Stack, { sx: {
                                '& .MuiFormControl-root': {
                                    '& .MuiInputBase-root': {
                                        background: '#fff',
                                        borderRadius: '8px',
                                    }
                                },
                            }, className: 'font-dropdown', direction: 'row', justifyContent: 'flex-start', spacing: 2, alignItems: 'center', width: '100%', py: 1.5 },
                            React.createElement(SelectDD, { options: ['ROBOTO'], DropDownLabel: "", placeHolder: 'Select Font Type', selectedValue: dealerData.FontStyle, name: "FontStyle" }),
                            React.createElement(Typography, { className: 'editText' },
                                "Selected Font Type: ",
                                React.createElement("b", null, dealerData.FontStyle))))),
                React.createElement(Box, { className: "granted-apps", mb: 1 },
                    React.createElement(Stack, { className: "content-wrapper content-wrapper-edit" },
                        React.createElement(Typography, { className: 'header-txt', width: '100%' }, "Logo Upload"),
                        React.createElement(Divider, { sx: { width: '100%' } }),
                        React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', width: '100%', py: 1.5, spacing: 2 },
                            React.createElement(Stack, { direction: 'row', spacing: 2 },
                                React.createElement(Box, null,
                                    React.createElement(Typography, { width: '100%', className: "fileUploadHeader defaultLogoText" },
                                        "Landing Page Background",
                                        React.createElement(FormGroup, null,
                                            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, name: 'IsDefaultLoginBackground', checked: dealerData === null || dealerData === void 0 ? void 0 : dealerData.IsDefaultLoginBackground, onChange: function (e) { return handleLogoCheckBoxes(e); }, disabled: false, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null) }), label: "Check this box to use a default background" }))),
                                    React.createElement(Box, { className: 'choose-file-input' },
                                        React.createElement(Button, { component: "label", variant: "contained", id: 'upload-img-left', className: "upload-btn" },
                                            "Choose file",
                                            React.createElement(VisuallyHiddenInput, { type: "file", name: 'LoginBackground', onChange: function (e) { return fileSelectHandler(e); }, accept: '.jpg, .jpeg, .png, .svg' })),
                                        React.createElement(Box, { id: "custom-text" },
                                            React.createElement(Button, { component: "label", variant: "outlined", id: 'upload-img-right', sx: { padding: '0px' } },
                                                (typeof (dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginBackground) === 'string' ? dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginBackground : (_d = dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginBackground) === null || _d === void 0 ? void 0 : _d.name) || 'No file chosen, yet.',
                                                dealerData.LoginBackground &&
                                                    React.createElement(CloseIcon, { sx: { marginLeft: '8px', cursor: 'pointer', fontSize: '20px' }, onClick: function () { return handleFileRemove('LoginBackground'); } })))),
                                    React.createElement(Typography, { fontSize: '12px !important', fontWeight: '400 !important', color: '#808080', lineHeight: '1 !important', padding: '4px 0px' }, "Upload an image within 3:2 aspect ratio for best fit."))),
                            (dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginBackgroundUrl) ?
                                React.createElement(Card, { sx: { maxWidth: 150, minHeight: 70, maxHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                                    React.createElement(CardMedia, { component: "img", alt: "No Preview", height: "auto", image: dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginBackgroundUrl })) :
                                React.createElement(Card, { sx: { maxWidth: 150, minHeight: 70, maxHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                                    React.createElement(CardMedia, { component: "img", alt: "No Preview", height: "auto", title: 'Default', src: DefaultWhiteLogo }))),
                        React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', width: '100%', py: 1.5, spacing: 2 },
                            React.createElement(Stack, { direction: 'row', spacing: 2 },
                                React.createElement(Box, null,
                                    React.createElement(Typography, { width: '100%', className: "fileUploadHeader defaultLogoText" },
                                        "Landing Page Logo",
                                        React.createElement(FormGroup, null,
                                            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, name: 'IsDefaultLoginLogo1', checked: dealerData === null || dealerData === void 0 ? void 0 : dealerData.IsDefaultLoginLogo1, onChange: function (e) { return handleLogoCheckBoxes(e); }, disabled: false, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null) }), label: "Check this box to use a default logo" }))),
                                    React.createElement(Box, { className: 'choose-file-input' },
                                        React.createElement(Button, { component: "label", variant: "contained", id: 'upload-img-left', sx: { padding: '0px 10px', whiteSpace: 'nowrap' }, className: "upload-btn" },
                                            "Choose file",
                                            React.createElement(VisuallyHiddenInput, { type: "file", name: 'LoginLogo1', onChange: function (e) { return fileSelectHandler(e); }, accept: '.jpg, .jpeg, .png, .svg' })),
                                        React.createElement(Box, { id: "custom-text" },
                                            React.createElement(Button, { component: "label", variant: "outlined", id: 'upload-img-right', sx: { padding: '0px' } },
                                                (typeof (dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginLogo1) === 'string' ? dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginLogo1 : (_e = dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginLogo1) === null || _e === void 0 ? void 0 : _e.name) || 'No file chosen, yet.',
                                                dealerData.LoginLogo1 &&
                                                    React.createElement(CloseIcon, { sx: { marginLeft: '8px', cursor: 'pointer', fontSize: '20px' }, onClick: function () { return handleFileRemove('LoginLogo1'); } })))),
                                    React.createElement(Typography, { fontSize: '12px !important', fontWeight: '400 !important', color: '#808080', lineHeight: '1 !important', padding: '4px 0px' }, "Upload an image within 320W x 320H pixels for best fit."))),
                            (dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginLogo1Url) ?
                                React.createElement(Card, { sx: { maxWidth: 150, minHeight: 70, maxHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                                    React.createElement(CardMedia, { component: "img", alt: "No Preview", height: "auto", image: dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginLogo1Url })) :
                                React.createElement(Card, { sx: { maxWidth: 150, minHeight: 70, maxHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                                    React.createElement(CardMedia, { component: "img", alt: "No Preview", height: "auto", title: 'Default', src: DefaultHeaderLogo }))),
                        React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', width: '100%', py: 1.5, spacing: 2 },
                            React.createElement(Stack, { direction: 'row', spacing: 2 },
                                React.createElement(Box, null,
                                    React.createElement(Typography, { width: '100%', className: 'fileUploadHeader defaultLogoText' },
                                        "Login Screen Logo",
                                        React.createElement(FormGroup, null,
                                            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, name: 'IsDefaultLoginLogo2', checked: dealerData === null || dealerData === void 0 ? void 0 : dealerData.IsDefaultLoginLogo2, onChange: function (e) { return handleLogoCheckBoxes(e); }, disabled: false, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null) }), label: "Check this box to use a default logo" }))),
                                    React.createElement(Box, { className: 'choose-file-input' },
                                        React.createElement(Button, { component: "label", variant: "contained", id: 'upload-img-left', sx: { padding: '0px 10px', whiteSpace: 'nowrap' }, className: "upload-btn" },
                                            "Choose file",
                                            React.createElement(VisuallyHiddenInput, { type: "file", name: 'LoginLogo2', onChange: function (e) { return fileSelectHandler(e); }, accept: '.jpg, .jpeg, .png, .svg' })),
                                        React.createElement(Box, { id: "custom-text" },
                                            React.createElement(Button, { component: "label", variant: "outlined", id: 'upload-img-right', sx: { padding: '0px' } },
                                                (typeof (dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginLogo2) === 'string' ? dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginLogo2 : (_f = dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginLogo2) === null || _f === void 0 ? void 0 : _f.name) || 'No file chosen, yet.',
                                                dealerData.LoginLogo2 &&
                                                    React.createElement(CloseIcon, { sx: { marginLeft: '8px', cursor: 'pointer', fontSize: '20px' }, onClick: function () { return handleFileRemove('LoginLogo2'); } })))),
                                    React.createElement(Typography, { fontSize: '12px !important', fontWeight: '400 !important', color: '#808080', lineHeight: '1 !important', padding: '4px 0px' }, "Upload an image within 160W x 80H pixels for best fit."))),
                            (dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginLogo2Url) ?
                                React.createElement(Card, { sx: { maxWidth: 150, minHeight: 70, maxHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                                    React.createElement(CardMedia, { component: "img", alt: "No Preview", height: "auto", image: dealerData === null || dealerData === void 0 ? void 0 : dealerData.LoginLogo2Url })) :
                                React.createElement(Card, { sx: { maxWidth: 150, minHeight: 70, maxHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                                    React.createElement(CardMedia, { component: "img", alt: "No Preview", height: "auto", title: 'Default', src: DefaultWhiteLogo }))),
                        React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', width: '100%', py: 1.5, spacing: 2 },
                            React.createElement(Stack, { direction: 'row', spacing: 2 },
                                React.createElement(Box, null,
                                    React.createElement(Typography, { width: '100%', className: 'fileUploadHeader defaultLogoText' },
                                        "Header Logo",
                                        React.createElement(FormGroup, null,
                                            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { sx: { "&.Mui-checked": { color: "#000", } }, name: 'IsDefaultLandingLogo', checked: dealerData === null || dealerData === void 0 ? void 0 : dealerData.IsDefaultLandingLogo, onChange: function (e) { return handleLogoCheckBoxes(e); }, disabled: false, icon: React.createElement(CheckBoxOutlineBlankOutlinedIcon, null), checkedIcon: React.createElement(CheckBoxOutlinedIcon, null) }), label: "Check this box to use a default logo" }))),
                                    React.createElement(Typography, { fontSize: '12px !important', fontWeight: '400 !important', color: '#808080', lineHeight: '1 !important', padding: '4px 0px' }, "This logo will also appear on your email templates and quotations."),
                                    React.createElement(Box, { className: 'choose-file-input' },
                                        React.createElement(Button, { component: "label", variant: "contained", id: 'upload-img-left', className: "upload-btn" },
                                            "Choose file",
                                            React.createElement(VisuallyHiddenInput, { type: "file", name: 'LandingLogo', onChange: function (e) { return fileSelectHandler(e); }, accept: '.jpg, .jpeg, .png, .svg' })),
                                        React.createElement(Box, { id: "custom-text" },
                                            React.createElement(Button, { component: "label", variant: "outlined", id: 'upload-img-right', sx: { padding: '0px' } },
                                                (typeof (dealerData === null || dealerData === void 0 ? void 0 : dealerData.LandingLogo) === 'string' ? dealerData === null || dealerData === void 0 ? void 0 : dealerData.LandingLogo : (_g = dealerData === null || dealerData === void 0 ? void 0 : dealerData.LandingLogo) === null || _g === void 0 ? void 0 : _g.name) || 'No file chosen, yet.',
                                                dealerData.LandingLogo &&
                                                    React.createElement(CloseIcon, { sx: { marginLeft: '8px', cursor: 'pointer', fontSize: '20px' }, onClick: function () { return handleFileRemove('LandingLogo'); } })))),
                                    React.createElement(Typography, { fontSize: '12px !important', fontWeight: '400 !important', color: '#808080', lineHeight: '1 !important', padding: '4px 0px' }, "Upload an image within 160W x 80H pixels for best fit."))),
                            (dealerData === null || dealerData === void 0 ? void 0 : dealerData.LandingLogoUrl) ?
                                React.createElement(Card, { sx: { maxWidth: 150, minHeight: 70, maxHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                                    React.createElement(CardMedia, { component: "img", alt: "No Preview", height: "auto", image: dealerData === null || dealerData === void 0 ? void 0 : dealerData.LandingLogoUrl })) :
                                React.createElement(Card, { sx: { maxWidth: 150, minHeight: 70, maxHeight: 70, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                                    React.createElement(CardMedia, { component: "img", alt: "No Preview", height: "auto", title: 'Default', src: DefaultHeaderLogo }))))),
                React.createElement(Box, { className: "granted-apps", mb: 1 },
                    React.createElement(Stack, { className: 'content-wrapper content-wrapper-edit' },
                        React.createElement(Typography, { className: 'header-txt', width: '100%' }, "Theme Configuration"),
                        React.createElement(Divider, { sx: { width: '100%' } }),
                        React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center', mb: '16px' },
                            React.createElement(Stack, null,
                                React.createElement(InputLabel, { sx: { color: '#000', fontSize: '14px', fontWeight: 400 } }, "Primary Theme:"),
                                React.createElement(Typography, { fontSize: '12px !important', fontWeight: '400 !important', color: '#808080', lineHeight: '1 !important', padding: '4px 0px' }, "Select a color or input a hex code to set the primary color for the header and call-to-action buttons.")),
                            React.createElement(Box, null,
                                React.createElement("input", { style: { width: '140px', height: '50px' }, type: 'color', value: dealerData === null || dealerData === void 0 ? void 0 : dealerData.PrimaryThemeColor, onChange: function (e) { return setDealerData(__assign(__assign({}, dealerData), { PrimaryThemeColor: e.target.value })); } }))),
                        React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center' },
                            React.createElement(Stack, null,
                                React.createElement(InputLabel, { sx: { color: '#000', fontSize: '14px', fontWeight: 400 } }, "Secondary Theme:"),
                                React.createElement(Typography, { fontSize: '12px !important', fontWeight: '400 !important', color: '#808080', lineHeight: '1 !important', padding: '4px 0px' }, "Select a color or input a hex code to set the secondary color for the secondary buttons.")),
                            React.createElement("input", { style: { width: '140px', height: '50px' }, type: 'color', value: dealerData === null || dealerData === void 0 ? void 0 : dealerData.SecondaryThemeColor, onChange: function (e) { return setDealerData(__assign(__assign({}, dealerData), { SecondaryThemeColor: e.target.value })); } })))),
                React.createElement(DealerMangementDrawer, { open: open, handleClose: handleClose, programData: dealerData.Programs, setDealerData: setDealerData, userAction: userActionType, selectedProgramIndex: selectedProgramIndex }))),
        React.createElement(Box, { display: "flex", justifyContent: "flex-end", sx: { background: "#F3F4F6" }, p: 2 },
            React.createElement(Button, { className: "btn-txt", sx: { background: '#14151C', color: '#fff', '&:hover': { background: '#14151c' } }, onClick: function () { return setOpenConfirmationPopup(true); } }, "Save Configuration"))));
};
export default DealerManagementEdit;
