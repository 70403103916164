var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { app_actions } from "../actions/app_actions";
import { user_actions } from "../actions/user_actions";
import { api_service } from "./../api/api_service";
import { api_urls } from "./../api/api_urls";
var Login_Signup_AsyncActions = (function () {
    function Login_Signup_AsyncActions() {
        this.login = function (payload) {
            return function (dispatch) {
                return __awaiter(this, void 0, void 0, function () {
                    var networkReq, error_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4, api_service.post({ endPoint: api_urls.LOGIN, payLoad: payload, showLoader: true })];
                            case 1:
                                networkReq = _a.sent();
                                return [2, networkReq];
                            case 2:
                                error_1 = _a.sent();
                                return [2, error_1];
                            case 3: return [2];
                        }
                    });
                });
            };
        };
        this.logout = function () {
            return function (dispatch) {
                return __awaiter(this, void 0, void 0, function () {
                    var networkReq, error_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4, api_service.get({ endPoint: api_urls.LOGOUT, showLoader: true })];
                            case 1:
                                networkReq = _a.sent();
                                dispatch(app_actions.resetStore());
                                return [2, networkReq];
                            case 2:
                                error_2 = _a.sent();
                                return [2, error_2];
                            case 3: return [2];
                        }
                    });
                });
            };
        };
        this.forgotPassword = function (payload) {
            return function (dispatch) {
                return __awaiter(this, void 0, void 0, function () {
                    var networkReq, error_3;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4, api_service.post({ endPoint: api_urls.FORGOT_PASSWORD, payLoad: payload, showLoader: true })];
                            case 1:
                                networkReq = _a.sent();
                                return [2, networkReq];
                            case 2:
                                error_3 = _a.sent();
                                return [2, error_3];
                            case 3: return [2];
                        }
                    });
                });
            };
        };
        this.forgotPasswordOTPverification = function (payload) {
            return function (dispatch) {
                return __awaiter(this, void 0, void 0, function () {
                    var networkReq, error_4;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4, api_service.post({ endPoint: api_urls.VERIFY_OTP, payLoad: payload, showLoader: true })];
                            case 1:
                                networkReq = _a.sent();
                                return [2, networkReq];
                            case 2:
                                error_4 = _a.sent();
                                return [2, error_4];
                            case 3: return [2];
                        }
                    });
                });
            };
        };
        this.resetPassword = function (payload) {
            return function (dispatch) {
                return __awaiter(this, void 0, void 0, function () {
                    var networkReq, error_5;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4, api_service.post({ endPoint: api_urls.RESET_PASSWORD, payLoad: payload, showLoader: true })];
                            case 1:
                                networkReq = _a.sent();
                                return [2, networkReq];
                            case 2:
                                error_5 = _a.sent();
                                return [2, error_5];
                            case 3: return [2];
                        }
                    });
                });
            };
        };
        this.refreshSession = function (refreshToken) {
            return function (dispatch) {
                var _a;
                return __awaiter(this, void 0, void 0, function () {
                    var networkReq, response, accessToken, error_6;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 3, , 4]);
                                if (!refreshToken) return [3, 2];
                                return [4, api_service.post({
                                        endPoint: api_urls.REFRESH_TOKEN,
                                        payLoad: { RefreshToken: refreshToken }
                                    })];
                            case 1:
                                networkReq = _b.sent();
                                if (((_a = networkReq.data) === null || _a === void 0 ? void 0 : _a.statusCode) === 403) {
                                    return [2];
                                }
                                response = networkReq.data;
                                accessToken = response.data.Token;
                                dispatch(user_actions.sessionRefresh(accessToken));
                                return [2, { accessToken: accessToken }];
                            case 2: return [3, 4];
                            case 3:
                                error_6 = _b.sent();
                                return [2, error_6];
                            case 4: return [2];
                        }
                    });
                });
            };
        };
    }
    return Login_Signup_AsyncActions;
}());
export var loginSignup_asyncActions = new Login_Signup_AsyncActions();
