import * as React from 'react';
import { pwdValidation } from '../../utils/commonUtilities';
var PasswordStrength = function (props) {
    var password = props.password, initial_pwdErrors = props.initial_pwdErrors;
    var _a = React.useState(''), passwordStrength = _a[0], setPasswordStrength = _a[1];
    React.useEffect(function () {
        checkPasswordStrength(password);
    }, [password]);
    var checkPasswordStrength = function (password) {
        if (pwdValidation(password, initial_pwdErrors, 'passwordStrength')) {
            setPasswordStrength((password.length < 10) ? 'Average' : 'Strong');
        }
        else {
            setPasswordStrength('Weak');
        }
    };
    return (React.createElement(React.Fragment, null, password ? React.createElement("span", null,
        "Password Strength: ",
        React.createElement("span", { className: "strength-text ".concat(passwordStrength.toLowerCase()) }, passwordStrength)) : ''));
};
export default PasswordStrength;
