import * as React from 'react';
import { useSelector } from 'react-redux';
import '../assets/scss/loader.scss';
import { Box, Backdrop, CircularProgress, Typography } from '@mui/material';
var Loader = function () {
    var loaderCounter = useSelector(function (state) { return state.App.ActiveHTTPRequests; });
    var showLoaderMessage = useSelector(function (state) { return state.User.loaderMessage; });
    var _a = React.useState(false), showLoader = _a[0], setShowLoader = _a[1];
    React.useEffect(function () {
        var timer = setTimeout(function () {
            setShowLoader(loaderCounter > 0);
        }, 50);
        return function () { return clearTimeout(timer); };
    }, [loaderCounter]);
    return (React.createElement(Box, { key: "".concat(showLoader) },
        React.createElement(Backdrop, { sx: { color: '#fff', zIndex: function (theme) { return theme.zIndex.drawer + 1; } }, open: showLoader },
            React.createElement(CircularProgress, { color: "inherit", variant: "determinate", size: 40, thickness: 4, sx: {
                    color: function (theme) {
                        return theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800];
                    },
                } }),
            React.createElement(CircularProgress, { variant: "indeterminate", size: 50, thickness: 4 }),
            React.createElement(Typography, { className: 'loader-text', textAlign: 'center' }, showLoaderMessage || 'Loading... Do not refresh'))));
};
export default React.memo(Loader);
