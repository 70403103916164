import React, { useEffect, useState } from 'react';
import ModuleHeader from '../../components/ModuleHeader/ModuleHeader';
import { Box } from '@mui/material';
import ModuleTable from '../../components/UtilityComponents/moduleTable';
import { DealerManagementCols } from '../../config/TableColumns';
import moment from 'moment';
import { api_service } from '../../api/api_service';
import { api_urls } from '../../api/api_urls';
import { useNavigate } from 'react-router-dom';
import { NavigationConfig } from '../../utils/navigation_config';
var DealerManagement = function () {
    var _a = useState([]), dealerList = _a[0], setDealerList = _a[1];
    var _b = useState(false), isFetching = _b[0], setIsFetching = _b[1];
    var navigate = useNavigate();
    var navConfig = NavigationConfig;
    var getDealerList = function () {
        setIsFetching(true);
        setDealerList([]);
        api_service.get({
            endPoint: api_urls.VENDOR_LIST,
        }).then(function (res) {
            setIsFetching(false);
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                var dealerList_1 = res.data.data;
                dealerList_1.map(function (eachDealer, index) {
                    eachDealer.CreatedTime = moment(eachDealer.CreatedTime).tz('America/New_York');
                });
                setDealerList(dealerList_1);
            }
        }).catch(function (err) { setIsFetching(false); });
    };
    var handleRowClickHandler = function (rowData) {
        navigate("".concat(navConfig.DealerManagement, "/view/").concat(rowData.Id), { state: rowData.Id });
    };
    var handleCreatePortal = function () {
        navigate("".concat(navConfig.DealerManagement, "/create"));
    };
    var handleActionClickHandler = function (rowData, actionType) {
        console.log('rowData:', rowData);
        console.log('actionType:', actionType);
    };
    useEffect(function () {
        getDealerList();
    }, []);
    return (React.createElement(Box, { className: "common-module-wrapper user-management-wrapper" },
        React.createElement(ModuleHeader, { moduleName: 'Portal Management', placeHolderText: 'Search by TicketID', hasPortalDD: false, hasStatusDD: false, hasSearch: false, hasAddItem: false, hasPortalCreate: true, getSelectedPortal: function () { }, selectedStatus: function () { }, getSearchedText: function () { }, isDrawerOpen: function () { }, handleCreatePortal: handleCreatePortal }),
        React.createElement(Box, { className: "module-common-table-wrapper" },
            React.createElement(ModuleTable, { dataToDisplay: dealerList, columns: DealerManagementCols, isLoading: isFetching, actionHandler: handleActionClickHandler, isRowClickEnabled: true, isActionColumn: false, onClickHandler: handleRowClickHandler, moduleName: "Portal Management" }))));
};
export default DealerManagement;
