import Box from '@mui/material/Box';
import React from 'react';
import "./../../assets/scss/MarketingContent.scss";
import TabsComponent from './TabsComponent';
import NotificationsTab from './NotificationsTab';
import ModuleHeader from '../../components/ModuleHeader/ModuleHeader';
var MarketingContent = function () {
    var _a = React.useState('1'), selectedTab = _a[0], setSelectedTab = _a[1];
    var tabs = [
        { label: "General Notification", value: '1', content: React.createElement("div", null,
                React.createElement(NotificationsTab, { selectedTab: selectedTab })) },
        { label: "Portal Notification", value: '2', content: React.createElement("div", null,
                React.createElement(NotificationsTab, { selectedTab: selectedTab })) },
    ];
    var handleTabChange = function (event, newValue) {
        setSelectedTab(newValue);
    };
    return (React.createElement(Box, { className: "common-module-wrapper marketing-main" },
        React.createElement(ModuleHeader, { moduleName: 'Marketing Content Configuration', placeHolderText: 'Search by TicketID', hasPortalDD: false, hasStatusDD: false, hasSearch: false, hasAddItem: false, getSelectedPortal: function () { }, selectedStatus: function () { }, getSearchedText: function () { }, isDrawerOpen: function () { } }),
        React.createElement(Box, { className: "tabs-main" },
            React.createElement(Box, { className: "tabs" },
                React.createElement(TabsComponent, { tabs: tabs, selectedTab: selectedTab, onTabChange: handleTabChange })))));
};
export default MarketingContent;
