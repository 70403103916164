import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import '../../assets/scss/adminview.scss';
import moment from "moment";
var EditApplicationDrawer = function (_a) {
    var open = _a.open, handleClose = _a.handleClose, rowData = _a.rowData, getAdminList = _a.getAdminList;
    var _b = React.useState(''), actionType = _b[0], setActionType = _b[1];
    var handleDrawerClose = function () {
        handleClose();
    };
    var getApplicationStatus = function (syncStatus) {
        switch (syncStatus) {
            case 1:
                return 'Processing';
            case 2:
                return 'Success';
            default:
                return 'Failed';
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Dialog, { open: open, className: 'MuiDialogue-upload location-popup' },
            React.createElement(DialogTitle, { sx: { m: 0, p: 2 }, id: "customized-dialog-title" }, "View Application Details"),
            React.createElement(IconButton, { "aria-label": "close", onClick: function () { return handleDrawerClose(); }, className: 'popup-close', sx: {
                    position: 'absolute',
                    right: 8,
                    top: 8,
                } },
                React.createElement(CloseIcon, null)),
            React.createElement(DialogContent, null,
                React.createElement(Grid, { container: true, rowSpacing: 3, columnSpacing: { xs: 1, sm: 2, md: 0 } },
                    React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                        React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                            React.createElement(Typography, { className: 'view-page-labels' }, "Customer Name"),
                            React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.CustomerName))),
                    React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                        React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                            React.createElement(Typography, { className: 'view-page-labels' }, "Portal Name"),
                            React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.PortalName))),
                    React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                        React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                            React.createElement(Typography, { className: 'view-page-labels' }, "SalesRep Name"),
                            React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.SalesRepName))),
                    React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                        React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                            React.createElement(Typography, { className: 'view-page-labels' }, "Date and Time (EST)"),
                            React.createElement(Typography, { className: 'view-page-values' }, (rowData === null || rowData === void 0 ? void 0 : rowData.CreatedTime) ? moment(rowData.CreatedTime).format('MM/DD/YYYY | hh:mm A') : ''))),
                    React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: (rowData === null || rowData === void 0 ? void 0 : rowData.RoleType) !== 'ADMIN' ? '1px #E6E6E6 solid' : '' } },
                        React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                            React.createElement(Typography, { className: 'view-page-labels' }, "Error Message"),
                            React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.SyncErrorLog))),
                    React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: (rowData === null || rowData === void 0 ? void 0 : rowData.RoleType) !== 'ADMIN' ? '1px #E6E6E6 solid' : '' } },
                        React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                            React.createElement(Typography, { className: 'view-page-labels' }, "Status"),
                            React.createElement(Typography, { className: 'view-page-values' }, getApplicationStatus(rowData === null || rowData === void 0 ? void 0 : rowData.SyncStatus)))))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { variant: 'contained', onClick: function () { return handleClose(); }, size: 'small', className: 'btn-txt' }, "Close")))));
};
export default EditApplicationDrawer;
