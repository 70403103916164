import React from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
var CustomTextarea = function (_a) {
    var value = _a.value, onChange = _a.onChange, error = _a.error, placeholder = _a.placeholder, rows = _a.rows, fieldName = _a.fieldName;
    return (React.createElement(FormControl, { error: Boolean(error), fullWidth: true, sx: { '& textarea': { borderColor: error ? '#d32f2f !important' : '' } } },
        React.createElement(TextareaAutosize, { name: fieldName, value: value, onChange: onChange, placeholder: placeholder, maxRows: rows, className: 'textarea' }),
        React.createElement(FormHelperText, { sx: { position: 'absolute', bottom: '-5px', marginLeft: '0px' } }, error)));
};
export default CustomTextarea;
