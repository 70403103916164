import * as React from 'react';
import { TextField } from '@mui/material';
import _ from './../../utils/lodash';
var SearchField = function (_a) {
    var onSearch = _a.onSearch, placeholder = _a.placeholder, value = _a.value, otherValues = _a.otherValues;
    var _b = React.useState(''), searchTextValue = _b[0], setSearchTextValue = _b[1];
    var handleOnChange = function (e) {
        var value = e.target.value.trim();
        if (value || e.target.value === '') {
            setSearchTextValue(e.target.value);
            debounceOnValueChange(e.target.value, otherValues);
        }
    };
    var debounceOnValueChange = React.useCallback(_.debounce(onSearch, 500), []);
    React.useEffect(function () {
        if (value === '') {
            setSearchTextValue('');
        }
    }, [value]);
    return (React.createElement(TextField, { className: 'search_feild', placeholder: placeholder.toUpperCase(), value: searchTextValue, disabled: Boolean(otherValues === null || otherValues === void 0 ? void 0 : otherValues.disabled), onChange: handleOnChange, inputProps: { maxLength: 50 }, fullWidth: true, variant: "standard" }));
};
export default React.memo(SearchField);
