import * as React from 'react';
import NeedHelp from '../pages/loginSignup/NeedHelp';
import LoginScreen from '../pages/loginSignup/Login';
import ForgotPassword from '../pages/loginSignup/ForgotPassword';
import ResetPassword from '../pages/loginSignup//ResetPassword';
import UserOTP from '../pages/loginSignup//userOTP';
import { NavigationConfig } from '../utils/navigation_config';
import EmailVerifySuccess from '../pages/loginSignup/EmailVerifySuccess';
var navConfig = NavigationConfig;
export var login_routes = [
    {
        path: '/*',
        element: React.createElement(LoginScreen, null)
    },
    {
        path: navConfig.Account.Login,
        element: React.createElement(LoginScreen, null)
    },
    {
        path: navConfig.Account.Forgot_Password,
        element: React.createElement(ForgotPassword, null)
    },
    {
        path: navConfig.Account.Reset_Password,
        element: React.createElement(ResetPassword, null)
    },
    {
        path: navConfig.Account.SignUP_OTP,
        element: React.createElement(UserOTP, null)
    },
    {
        path: navConfig.Account.Forgot_Pwd_OTP,
        element: React.createElement(UserOTP, null)
    },
    {
        path: navConfig.Account.Verify_Success,
        element: React.createElement(EmailVerifySuccess, null)
    },
    {
        path: navConfig.Account.NeedHelp,
        element: React.createElement(NeedHelp, null)
    }
];
