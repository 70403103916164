var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { app_actions } from '../actions/app_actions';
import { user_actions } from '../actions/user_actions';
var initialState = {
    userData: {
        email: null,
        firstName: null,
        lastName: null,
        PhoneNumber: null,
        isRoleApproved: 0,
        RoleType: '',
        Country: '',
        ProgramName: '',
    },
    isLoggedIn: false,
    session: {
        accessToken: '',
        refreshToken: '',
    },
    loaderMessage: '',
    helpMail: ''
};
export function UserReducer(state, _a) {
    if (state === void 0) { state = initialState; }
    var type = _a.type, payload = _a.payload;
    switch (type) {
        case 'persist/REHYDRATE': {
            if (payload && payload.User) {
                return Object.assign({}, state, payload.User);
            }
            else {
                return Object.assign({}, state);
            }
        }
        case user_actions.Constants.LoginSuccess:
            return Object.assign({}, state, {
                isLoggedIn: true,
                session: {
                    accessToken: payload.session.Token,
                    refreshToken: payload.session.RefreshToken,
                },
                userData: {
                    email: payload.session.Email,
                    firstName: payload.session.FirstName,
                    lastName: payload.session.LastName,
                    PhoneNumber: payload.session.PhoneNumber,
                    isRoleApproved: payload.session.RoleApproval,
                    RoleType: payload.session.RoleType,
                    Country: payload.session.Country,
                    ProgramName: payload.session.ProgramName,
                }
            });
        case user_actions.Constants.SessionRefresh:
            return Object.assign({}, state, {
                session: __assign(__assign({}, state.session), { accessToken: payload })
            });
        case user_actions.Constants.setSignupFormInfo:
            return Object.assign({}, state, {
                signupFormInfo: payload,
            });
        case user_actions.Constants.loaderMessage:
            return Object.assign({}, state, {
                loaderMessage: payload,
            });
        case user_actions.Constants.helpMail:
            return Object.assign({}, state, {
                helpMail: payload,
            });
        case app_actions.Constants.ResetStore: {
            return Object.assign({}, state, initialState);
        }
        default:
            return state;
    }
}
