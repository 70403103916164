var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import TextField from '../../components/FormComponents/TextField';
import { useNavigate } from 'react-router-dom';
import { NavigationConfig } from '../../utils/navigation_config';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import AuxCapLogo from './../../assets/images/AuxCapDashboardLogo-dark.svg';
import constants from '../../config/constants';
import { loginSignup_asyncActions } from '../../asyncActions/loginSignup_asynActions';
import { user_actions } from '../../actions/user_actions';
import messages from '../../utils/messages.json';
var INITIAL_FORM_VALUES = { email: "", password: "" };
var LoginScreen = function (props) {
    var _a = useState(__assign({}, INITIAL_FORM_VALUES)), userData = _a[0], setUserdata = _a[1];
    var _b = useState(__assign({}, INITIAL_FORM_VALUES)), formErrors = _b[0], setFormErrors = _b[1];
    var _c = useState(''), apiError = _c[0], setApiError = _c[1];
    var dispatch = useDispatch();
    var navConfig = NavigationConfig;
    var navigate = useNavigate();
    var handleOnInputChange = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        isValidFormData(name, value);
    };
    var handleOnInputBlur = function (e) { isValidFormData(e.target.name, e.target.value); };
    var handleLogin = function () {
        if (isValidFormData()) {
            var loginPayload = {
                'Email': userData === null || userData === void 0 ? void 0 : userData.email,
                'Password': userData === null || userData === void 0 ? void 0 : userData.password
            };
            dispatch(loginSignup_asyncActions.login(loginPayload))
                .then(function (response) {
                var _a, _b, _c;
                if (((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.statusCode) === 200) {
                    if ((_c = (_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.IsTempLogin) {
                        navigate(navConfig.Account.Reset_Password, { state: { email: userData === null || userData === void 0 ? void 0 : userData.email, oldPassword: userData === null || userData === void 0 ? void 0 : userData.password } });
                    }
                    else {
                        dispatch(user_actions.loginSuccess({ session: response.data.data }));
                    }
                }
                else {
                    setApiError(function () { return (response.data ? response.data.message : response.message); });
                }
            }).catch(function (error) {
                setApiError(function () { return (error.message); });
            });
        }
    };
    var isValidFormData = function (fieldName, value) {
        var _a;
        var _b, _c;
        if (fieldName === void 0) { fieldName = 'All'; }
        var _userData = __assign(__assign({}, userData), (_a = {}, _a[fieldName] = value, _a));
        var errorInfo = __assign({}, formErrors);
        switch (fieldName) {
            case 'All':
            case 'email':
                if ((_b = _userData.email) === null || _b === void 0 ? void 0 : _b.trim()) {
                    errorInfo.email = constants.regex.emailRegExp.test(_userData.email) ? '' : 'Please enter valid email address.';
                }
                else {
                    errorInfo.email = 'This field is required.';
                }
                if (fieldName !== 'All')
                    break;
            case 'All':
            case 'password':
                errorInfo.password = ((_c = _userData.password) === null || _c === void 0 ? void 0 : _c.trim()) ? '' : messages['This field is required'];
        }
        if (fieldName !== 'All') {
            setUserdata(function (prevData) {
                var _a;
                return (__assign(__assign({}, prevData), (_a = {}, _a[fieldName] = value, _a)));
            });
        }
        setFormErrors(function () { return (__assign({}, errorInfo)); });
        return Object.values(errorInfo).every(function (errorMsg) { return errorMsg === ''; });
    };
    var handleOnKeyDown = function (e) {
        e.persist();
        if (e.key === 'Enter') {
            handleLogin();
        }
    };
    return (React.createElement(Grid, { container: true, alignContent: 'flex-start' },
        React.createElement(Grid, { item: true, pb: { xs: '16px', sm: '16px', md: '24px' }, xs: 12, sm: 12 },
            React.createElement("img", { src: AuxCapLogo, alt: "logo", width: '215px', height: '68.39px' })),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, pb: '40px' },
            React.createElement(Typography, { fontSize: { xs: '22px', sm: '24px', md: '24px', lg: '30px' }, align: 'left', className: 'heading' }, "Sign In to your account")),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12, pb: { xs: '34px', sm: '34px', md: '34px' } },
            React.createElement(Box, null,
                React.createElement(TextField, { label: 'Email', name: 'email', placeholder: 'example@email.com', value: userData.email, error: Boolean(formErrors.email), error_message: formErrors.email, onChange: handleOnInputChange, onBlur: handleOnInputBlur, fullWidth: true, maxLength: 60 }))),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12, pb: { xs: '34px', sm: '34px', md: '34px' } },
            React.createElement(Box, null,
                React.createElement(TextField, { name: 'password', label: 'Password', placeholder: '********', value: userData.password, error: Boolean(formErrors.password), error_message: formErrors.password, onKeyDown: handleOnKeyDown, onChange: handleOnInputChange, onBlur: handleOnInputBlur, inputType: "password", fullWidth: true }))),
        React.createElement(Typography, { variant: 'body1', className: 'api-eeror' }, apiError),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12, pb: '40px' },
            React.createElement(Typography, { textAlign: 'end' },
                React.createElement(Box, { component: 'span', sx: { cursor: 'pointer', textDecoration: 'underline' }, onClick: function () { return navigate(navConfig.Account.Forgot_Password); } }, "Forgot Password?"))),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12, pb: '34px' },
            React.createElement(Box, { className: 'd-flex mt-2' },
                React.createElement(Button, { fullWidth: true, variant: 'contained', className: 'btn-txt', onClick: handleLogin }, "Sign In"))),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12 },
            React.createElement(Typography, { textAlign: 'center', onClick: function () { return navigate(navConfig.Account.NeedHelp); }, sx: { textDecoration: 'underline', cursor: 'pointer' } }, "Need Help?"))));
};
export default LoginScreen;
