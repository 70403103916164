var UserActions = (function () {
    function UserActions() {
        var _this = this;
        this.Constants = {
            LoginSuccess: 'app/login/success',
            SaveUserData: 'app/save/user-data',
            SessionRefresh: 'app/user/session/refresh',
            setSignupFormInfo: 'app/signup/user-forminfo-data',
            loaderMessage: 'app/loader/loader-message',
            helpMail: 'app/dashboard/helpemail',
        };
        this.loginSuccess = function (data) {
            return {
                type: _this.Constants.LoginSuccess,
                payload: data
            };
        };
        this.sessionRefresh = function (data) {
            return {
                type: _this.Constants.SessionRefresh,
                payload: data
            };
        };
        this.saveUserData = function (data) {
            return {
                type: _this.Constants.SaveUserData,
                payload: data
            };
        };
        this.setSignupFormInfo = function (data) {
            return {
                type: _this.Constants.setSignupFormInfo,
                payload: data
            };
        };
        this.setLoaderMessage = function (data) {
            return {
                type: _this.Constants.loaderMessage,
                payload: data
            };
        };
        this.helpEmail = function (data) {
            return {
                type: _this.Constants.helpMail,
                payload: data
            };
        };
    }
    return UserActions;
}());
export var user_actions = new UserActions();
