// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-side--main-menu-container .MuiBox-root .MuiDrawer-root .MuiPaper-root {
  position: relative;
  height: calc(100vh - 56px);
}
.app-side--main-menu-container .MuiBox-root .MuiDrawer-root .MuiPaper-root .MuiList-root {
  padding: 12px;
}
.app-side--main-menu-container .MuiBox-root .MuiDrawer-root .MuiPaper-root .MuiList-root .MuiListItem-root {
  border-radius: 4px;
}
.app-side--main-menu-container .MuiBox-root .MuiDrawer-root .MuiPaper-root .MuiList-root .MuiListItem-root .MuiListItemText-root .MuiTypography-root {
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/authentication.scss"],"names":[],"mappings":"AAGY;EACI,kBAAA;EACA,0BAAA;AAFhB;AAGgB;EACI,aAAA;AADpB;AAEoB;EACI,kBAAA;AAAxB;AAE4B;EACI,eAAA;AAAhC","sourcesContent":[".app-side--main-menu-container{\n    .MuiBox-root {\n        .MuiDrawer-root {\n            .MuiPaper-root {\n                position:relative;\n                height: calc(100vh - 56px);\n                .MuiList-root {\n                    padding:12px;\n                    .MuiListItem-root {\n                        border-radius: 4px;\n                        .MuiListItemText-root {\n                            .MuiTypography-root {\n                                font-size: 16px;\n                            }\n                        }\n                    }\n                }\n            }\n        }\n    }   \n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
