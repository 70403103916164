import * as React from 'react';
import { Box, Button, Grid, Typography, IconButton } from '@mui/material';
import TextField from '../../components/FormComponents/TextField';
import { useNavigate } from 'react-router-dom';
import { NavigationConfig } from '../../utils/navigation_config';
import constants from '../../config/constants';
import CloseIcon from '../../assets/images/close-icon.svg';
import { loginSignup_asyncActions } from '../../asyncActions/loginSignup_asynActions';
import { useDispatch } from 'react-redux';
import { ForgotPasswordIcon } from './../../components/icons';
var ForgotPassword = function (props) {
    var dispatch = useDispatch();
    var navConfig = NavigationConfig;
    var navigate = useNavigate();
    var _a = React.useState(''), email = _a[0], setEmail = _a[1];
    var _b = React.useState(''), error = _b[0], setError = _b[1];
    var handleOnValChange = function (e) {
        setEmail(function () { return (e.target.value); });
        setError(function () { return (''); });
    };
    var isEmailValid = function (value) {
        var trimmedValue = value !== null && value !== void 0 ? value : email.trim();
        var errorMsg = trimmedValue ? (constants.regex.emailRegExp.test(trimmedValue) ? '' : 'Please enter valid email address.') : 'This field is required.';
        setError(function () { return (errorMsg); });
        return errorMsg === '';
    };
    var handleResetPassword = function () {
        if (isEmailValid()) {
            var forgotPasswordPayload = {
                'Email': email
            };
            dispatch(loginSignup_asyncActions.forgotPassword(forgotPasswordPayload))
                .then(function (response) {
                var _a;
                if (((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.statusCode) === 200) {
                    navigate(navConfig.Account.Forgot_Pwd_OTP, { state: { userMail: email } });
                }
                else {
                    setError(function () { return (response.data.message); });
                }
            }).catch(function (error) {
                setError(function () { return (error.message); });
            });
        }
    };
    return (React.createElement(Grid, { container: true },
        React.createElement(IconButton, { onClick: function () { return navigate(navConfig.Account.Login); }, className: "close-icon" },
            React.createElement("img", { src: CloseIcon })),
        React.createElement(Grid, { item: true, xs: 12, sm: 12 },
            React.createElement(Box, { pb: '14px' },
                React.createElement(ForgotPasswordIcon, null))),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, pb: '16px' },
            React.createElement(Typography, { variant: 'h4', fontSize: { xs: '30px', sm: '30px', md: '24px' } }, "Forgot password?")),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, pb: { xs: '14px', sm: '14px', md: '32px' } },
            React.createElement(Typography, { variant: 'caption', className: "need-help", fontSize: { xs: '14px', sm: '14px', md: '16px', lg: '16px' } }, "Please enter your registered email address")),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12, pb: '40px' },
            React.createElement(Box, null,
                React.createElement(TextField, { value: email, name: 'email', error: Boolean(error), error_message: error, onBlur: function (e) { return isEmailValid(e.target.value); }, onChange: handleOnValChange, placeholder: 'example@email.com', label: 'Email', fullWidth: true, maxLength: 60 }))),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12, pb: '40px' },
            React.createElement(Box, { className: 'd-flex mt-2' },
                React.createElement(Button, { fullWidth: true, variant: 'contained', className: 'btn-txt', sx: { textTransform: 'capitalize' }, onClick: handleResetPassword }, "Send OTP"))),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12 },
            React.createElement(Typography, { textAlign: 'center', fontSize: { xs: '16px', sm: '16px', md: '16px' } }, "Know your password? ",
                React.createElement(Box, { component: 'span', sx: { fontWeight: 'bold', cursor: 'pointer' }, onClick: function () { return navigate(navConfig.Account.Login); } }, "Sign In")))));
};
export default ForgotPassword;
