import { Box, Button, Grid, Stack, Typography, IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { NavigationConfig } from '../../utils/navigation_config';
import OTPComponent from '../../components/UtilityComponents/otp_component';
import OtpTimer from '../../components/UtilityComponents/widget/OtpTimer';
import CloseIcon from '../../assets/images/close-icon.svg';
import { loginSignup_asyncActions } from '../../asyncActions/loginSignup_asynActions';
import { useDispatch } from 'react-redux';
var TimerSecondTime = 0;
var TimerMinuteTime = 15;
var UserOTP = function () {
    var _a, _b, _c, _d, _e, _f;
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var location = useLocation();
    var navConfig = NavigationConfig;
    var _g = useState(''), otpValue = _g[0], setOtpValue = _g[1];
    var _h = useState(''), error = _h[0], setError = _h[1];
    var _j = useState(false), isOTPExpiered = _j[0], setIsOTPExpiered = _j[1];
    var _k = useState("".concat(new Date().getTime())), refreshTimerComponent = _k[0], setRefreshTimerComponent = _k[1];
    React.useEffect(function () {
    }, []);
    var handleOnOTPChange = function (otp) {
        setError(function () { return (''); });
        setOtpValue(function (prevValue) { return (otp); });
    };
    var handleOnVerifyOTPClick = function () {
        var _a;
        if (otpValue.length === 6) {
            var verifyOtpPayload = {
                "Email": (_a = location.state) === null || _a === void 0 ? void 0 : _a.userMail,
                "Otp": otpValue
            };
            dispatch(loginSignup_asyncActions.forgotPasswordOTPverification(verifyOtpPayload))
                .then(function (response) {
                var _a, _b;
                if (((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.statusCode) === 200) {
                    navigate(navConfig.Account.Reset_Password, { state: { email: (_b = location.state) === null || _b === void 0 ? void 0 : _b.userMail, otp: otpValue } });
                }
                else {
                    setError(function () { return (response.data.message); });
                }
            }).catch(function (error) {
                setError(function () { return (error.message); });
            });
        }
        else {
            setError(function () { return ('Please enter a valid OTP.'); });
        }
    };
    var resetOTPTimer = function () {
        setIsOTPExpiered(false);
        setRefreshTimerComponent("".concat(new Date().getTime()));
    };
    var handleResendOTP = function () {
        var _a;
        setOtpValue('');
        setError('');
        var resendOTP = {
            'Email': (_a = location.state) === null || _a === void 0 ? void 0 : _a.userMail
        };
        dispatch(loginSignup_asyncActions.forgotPassword(resendOTP))
            .then(function (response) {
            resetOTPTimer();
            if (response.data.statusCode === 400) {
                setError(function () { return (response.data.message); });
            }
        });
    };
    return (React.createElement(Grid, { container: true },
        ((_a = location.state) === null || _a === void 0 ? void 0 : _a.prevPath) !== 'signup' &&
            React.createElement(IconButton, { onClick: function () { return navigate(navConfig.Account.Login); }, className: "close-icon" },
                React.createElement("img", { src: CloseIcon })),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12, pb: '24px' },
            React.createElement(Typography, { fontSize: { xs: '24px', sm: '30px', md: '30px' }, align: 'left', fontWeight: 'bold', pb: '16px' }, ((_b = location.state) === null || _b === void 0 ? void 0 : _b.prevPath) ? 'Verify Email' : 'Enter the OTP'),
            React.createElement(Typography, { fontSize: { xs: '14px', sm: '14px', md: '15px', lg: '16px' }, align: 'left', pb: '24px', className: 'need-help' }, ((_c = location.state) === null || _c === void 0 ? void 0 : _c.prevPath) === 'signup' ?
                React.createElement(Typography, null,
                    "Enter the OTP",
                    React.createElement(Box, { component: 'span', sx: { fontWeight: 'bold' } }, " (One Time Password) "),
                    "which we sent to your below email.")
                :
                    React.createElement(Typography, null,
                        "Please enter the OTP",
                        React.createElement(Box, { component: 'span', sx: { fontWeight: 'bold' } }, " (One Time Password) "),
                        "which we sent to your below email to reset your password.")),
            React.createElement(Stack, { flexDirection: 'row', justifyContent: 'space-between' },
                React.createElement(Typography, { className: 'otp-mail', fontSize: { xs: '14px', sm: '14px', md: '15px', lg: '16px' } }, ((_d = location.state) === null || _d === void 0 ? void 0 : _d.userMail) ? (_e = location.state) === null || _e === void 0 ? void 0 : _e.userMail : 'examplemail@gmail.com'),
                ((_f = location.state) === null || _f === void 0 ? void 0 : _f.prevPath) !== 'signup' &&
                    React.createElement(Typography, { fontSize: { xs: '14px', sm: '14px', md: '15px', lg: '16px' }, className: 'otp-mail' }))),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12, pb: '40px' },
            React.createElement(OTPComponent, { numOfOTPboxes: 6, onChange: handleOnOTPChange, error: Boolean(error), error_message: error, key: "".concat(refreshTimerComponent) })),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12, pb: '40px', key: refreshTimerComponent },
            React.createElement(OtpTimer, { waitingTimeSec: TimerSecondTime, waitingTimeMin: TimerMinuteTime, isOTPExpiered: function (value) { return setIsOTPExpiered(value); } })),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12 },
            React.createElement(Box, { className: 'd-flex mt-2', pb: '35px' },
                React.createElement(Button, { fullWidth: true, variant: 'contained', className: 'ml-2', onClick: handleOnVerifyOTPClick, sx: { textTransform: 'capitalize' } }, "Verify"))),
        React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12, pb: '40px' },
            React.createElement(Typography, { textAlign: 'center', fontSize: { xs: '16px', sm: '16px', md: '16px' } }, "Did not receive OTP? ",
                React.createElement(Tooltip, { title: !isOTPExpiered ? "This will be enabled after the OTP expires" : '', placement: 'top' },
                    React.createElement(Box, { component: 'span', sx: { fontWeight: 'bold', cursor: isOTPExpiered ? 'pointer' : 'not-allowed', opacity: isOTPExpiered ? '1' : '0.5' }, onClick: function () { return isOTPExpiered && handleResendOTP(); } }, "Resend"))))));
};
export default UserOTP;
